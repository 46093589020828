// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.wrapper.has-background {
  background: white;
}
.wrapper .country-code {
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  text-align: center;
  font-family: AppFontBold;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper .country-code::after {
  content: "";
  position: absolute;
  right: -4px;
  bottom: -4px;
  height: 45px;
  width: 1px;
  background: #e5e5e5;
}
.wrapper .country-code .flag-container {
  height: 17px;
  width: 22px;
  border-radius: 3px;
  overflow: hidden;
  margin-right: 10px;
}
.wrapper .country-code .flag-container .flag {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.wrapper .country-code .arrow {
  height: 4px;
  width: 7px;
  margin-left: 13px;
}
.wrapper ion-input {
  padding: 0px 15px !important;
  --placeholder-color: rgb(181, 176, 176);
}`, "",{"version":3,"sources":["webpack://./src/app/components/input-phone-with-county-code/input-phone-with-county-code.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,2BAAA;AACJ;AAAI;EACI,iBAAA;AAER;AACI;EACI,uBAAA;EAAA,kBAAA;EACA,aAAA;EACA,kBAAA;EACA,wBAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACR;AACQ;EACI,WAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,UAAA;EACA,mBAAA;AACZ;AAEQ;EACI,YAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;AAAZ;AACY;EACI,WAAA;EACA,YAAA;EACA,iBAAA;AAChB;AAGQ;EACI,WAAA;EACA,UAAA;EACA,iBAAA;AADZ;AAII;EACI,4BAAA;EACA,uCAAA;AAFR","sourcesContent":[".wrapper{\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    &.has-background {\n        background: white;\n\n    }\n    .country-code {\n        width: fit-content;\n        padding: 10px;\n        text-align: center;\n        font-family: AppFontBold;\n        position: relative;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    \n        &::after {\n            content: \"\";\n            position: absolute;\n            right: -4px;\n            bottom: -4px;\n            height: 45px;\n            width: 1px;\n            background: #e5e5e5;\n        }\n\n        .flag-container {\n            height: 17px;\n            width: 22px;\n            border-radius: 3px;\n            overflow: hidden;\n            margin-right: 10px;\n            .flag {\n                width: 100%;\n                height: 100%;\n                object-fit: cover;\n            }\n        }\n\n        .arrow {\n            height: 4px;\n            width: 7px;\n            margin-left: 13px;\n        }\n    }\n    ion-input {\n        padding: 0px 15px !important;\n        --placeholder-color: rgb(181, 176, 176);\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
