// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-icon {
  position: absolute;
  right: 8px;
  top: -8px;
  height: 24px;
  width: 24px;
}

.bubble-container {
  background: #2C2E37;
  border-radius: 16px;
  padding: 16px;
  margin: 0 16px;
  text-transform: none;
}
.bubble-container p {
  margin: 0;
}

.bubble-pin {
  width: 20px;
  margin: 0 auto;
  fill: #2C2E37;
}

.add-to-home-container {
  position: relative;
  margin: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/add-to-home/add-to-home.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,SAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,cAAA;EACA,oBAAA;AACF;AACE;EACE,SAAA;AACJ;;AAGA;EACE,WAAA;EACA,cAAA;EACA,aAAA;AAAF;;AAGA;EACE,kBAAA;EACA,YAAA;AAAF","sourcesContent":[".close-icon {\n  position: absolute;\n  right: 8px;\n  top: -8px;\n  height: 24px;\n  width: 24px;\n}\n\n.bubble-container {\n  background: #2C2E37;\n  border-radius: 16px;\n  padding: 16px;\n  margin: 0 16px;\n  text-transform: none;\n\n  p {\n    margin: 0;\n  }\n}\n\n.bubble-pin {\n  width: 20px;\n  margin: 0 auto;\n  fill: #2C2E37;\n}\n\n.add-to-home-container {\n  position: relative;\n  margin: 16px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
