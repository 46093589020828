import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-select-language-popover',
	templateUrl: './select-language-popover.component.html',
	styleUrls: ['./select-language-popover.component.scss'],
})
export class SelectLanguagePopoverModalComponent {
	public selectedLanguage: string;
	text = {
		de: 'Bestätigen',
		en: 'Confirm',
	};
	buttonText = this.text.de;
	public listCountries = [
		{
			name: 'Deutsch',
			icon: '../../../assets/flag/flags-country/de.svg',
			value: 'de',
		},
		{
			name: 'English',
			icon: '../../../assets/flag/flags-country/gb.svg',
			value: 'en',
		},
		// {
		// 	name: 'Polski',
		// 	icon: '../../../assets/flag/flags-country/pl.svg',
		// 	value: 'pl',
		// },
		// {
		// 	name: 'Español',
		// 	icon: '../../../assets/flag/flags-country/es.svg',
		// 	value: 'es',
		// },
		// {
		// 	name: 'Türkçe',
		// 	icon: '../../../assets/flag/flags-country/tr.svg',
		// 	value: 'tr',
		// },
		// {
		// 	name: 'Français',
		// 	icon: '../../../assets/flag/flags-country/fr.svg',
		// 	value: 'fr',
		// },
		// {
		// 	name: 'Italiano',
		// 	icon: '../../../assets/flag/flags-country/it.svg',
		// 	value: 'it',
		// },
	];
	constructor(
		private translate: TranslateService,
		private popover: PopoverController
	) {
		this.selectedLanguage = this.translate.currentLang;
		this.buttonText = this.text[this.selectedLanguage];
	}

	selectLng(lang: string) {
		this.selectedLanguage = lang;
		this.buttonText = this.text[this.selectedLanguage];
	}

	dismiss() {
		this.popover.dismiss();
	}
	dismissWithData() {
		this.translate.setDefaultLang(this.selectedLanguage);
		this.translate.use(this.selectedLanguage);
		this.dismiss();
	}
}
