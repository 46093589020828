// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog_info:not(li) > p:first-of-type {
  margin-bottom: 40px;
}

ion-content {
  padding: 10px 10px 0;
}

.acordion-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.acordion-wrapper .acordion {
  max-width: 500px;
}

.title strong {
  font-size: 18px;
}

ion-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.05);
}
ion-footer .link {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  margin: 0;
  border-right-width: 2px;
  border-right-style: solid;
  border-image: linear-gradient(180deg, rgb(255, 255, 255) 20%, rgb(82, 19, 64) 20%, rgb(82, 19, 64) 85%, rgb(255, 255, 255) 85%);
  border-image-slice: 1;
  color: #521340;
  font-family: AppFontBold;
  font-size: 16px;
  line-height: 121.4%; /* 19.424px */
  letter-spacing: 0.32px;
  text-decoration-line: underline;
  text-underline-offset: 0.2em;
  text-transform: capitalize;
}
ion-footer .link:last-child {
  border-right: none;
}

.title:first-child {
  padding: 0 30px 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/faq/faq.page.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACC,aAAA;EACA,uBAAA;EACA,mBAAA;AACD;AACC;EACC,gBAAA;AACF;;AAIC;EACC,eAAA;AADF;;AAKA;EACC,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,gDAAA;AAFD;AAGC;EACC,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;EACA,SAAA;EACA,uBAAA;EACA,yBAAA;EACA,+HAAA;EAOA,qBAAA;EAEE,cAAA;EAEA,wBAAA;EACA,eAAA;EACA,mBAAA,EAAA,aAAA;EACA,sBAAA;EACA,+BAAA;EACA,4BAAA;EACF,0BAAA;AATF;AAYE;EACC,kBAAA;AAVH;;AAgBC;EACC,sBAAA;AAbF","sourcesContent":[".dialog_info:not(li) > p:first-of-type {\n  margin-bottom: 40px;\n}\n\nion-content {\n  padding: 10px 10px 0;\n}\n\n.acordion-wrapper {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\n\t.acordion {\n\t\tmax-width: 500px;\n\t}\n}\n\n.title {\n\tstrong {\n\t\tfont-size: 18px;\n\t}\n}\n\nion-footer {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\theight: 64px;\n\tbox-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.05);\n\t.link {\n\t\tflex-grow: 1;\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tcursor: pointer;\n\t\tpadding: 8px;\n\t\tmargin: 0;\n\t\tborder-right-width: 2px;\n\t\tborder-right-style: solid;\n\t\tborder-image: linear-gradient(\n\t\t\t180deg,\n\t\t\trgba(255, 255, 255, 1) 20%,\n\t\t\trgba(82, 19, 64, 1) 20%,\n\t\t\trgba(82, 19, 64, 1) 85%,\n\t\t\trgba(255, 255, 255, 1) 85%\n\t\t);\n\t\tborder-image-slice: 1;\n\n    color: #521340;\n\n    font-family: AppFontBold;\n    font-size: 16px;\n    line-height: 121.4%; /* 19.424px */\n    letter-spacing: 0.32px;\n    text-decoration-line: underline;\n    text-underline-offset: 0.2em; \n\t\ttext-transform: capitalize;\n\n\n\t\t&:last-child {\n\t\t\tborder-right: none;\n\t\t}\n\t}\n}\n\n.title {\n\t&:first-child {\n\t\tpadding: 0 30px 10px 0;\n\t}\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
