import { Injectable } from '@angular/core';
import Article from 'src/smoothr-web-app-core/models/Article';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import ArticleOption from 'src/smoothr-web-app-core/models/ArticleOption';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import { defaultsToArticleOptionForOrder, numberD } from 'src/smoothr-web-app-core/utils/utils';

@Injectable({
	providedIn: 'root',
})
export class PfandService {
	constructor() {}
	//This function User for changing + - in basket
	async changeArticlePfand(orderedArticles: ArticleGroup[], articleGroup: ArticleGroup, action?: string) {
		articleGroup = JSON.parse(JSON.stringify(articleGroup));

		const foundDeposit = numberD(articleGroup.article.deposit) > 0;
		if (foundDeposit) {
			const foundPfandArticleIndex = orderedArticles.findIndex(it =>
				it.article.tags.find(tag => tag.identifier == 'deposit' + numberD(articleGroup.article.deposit).toString().substring(2))
			);
			if (foundPfandArticleIndex >= 0) {
				if (action == 'add') {
					orderedArticles[foundPfandArticleIndex].quantity++;
				}
				if (action == 'minus') {
					if (orderedArticles[foundPfandArticleIndex].quantity === 1) {
						orderedArticles.splice(foundPfandArticleIndex, 1);
					} else {
						orderedArticles[foundPfandArticleIndex].quantity--;
					}
				}
			}
			return orderedArticles;
		}
		articleGroup.groups = articleGroup.groups.filter((it: ArticleOption) => {
			return numberD(it.article.deposit) > 0;
		});
		if (articleGroup.groups.length > 0) {
			const articleGroupData = articleGroup.groups.map(it => {
				return {
					quantity: it.quantity,
					deposit: 'deposit' + numberD(it.article.deposit).toString().substring(2),
				};
			});

			const groupData = groupByToMap(
				articleGroupData,
				(articleGroupData: { quantity: number; deposit: string }) => articleGroupData.deposit
			);
			const quantityPare = {
				deposit8: groupData.get('deposit8')?.reduce((a, b) => a + b.quantity, 0) ?? 0,
				deposit15: groupData.get('deposit15')?.reduce((a, b) => a + b.quantity, 0) ?? 0,
				deposit25: groupData.get('deposit25')?.reduce((a, b) => a + b.quantity, 0) ?? 0,
			};
			const foundAllPfandArticles = orderedArticles
				.map((it, index) => {
					it.article.tags = it.article.tags.filter(
						tag =>
							tag.identifier.includes('deposit8') ||
							tag.identifier.includes('deposit15') ||
							tag.identifier.includes('deposit25')
					);
					if (it.article.tags.length > 0) {
						return {
							articleGroup: it,
							index: index,
							tag: it.article.tags[0]?.identifier,
							newQuantaty: quantityPare[it.article.tags[0]?.identifier],
						};
					}
					return null;
				})
				.filter(v => !!v);

			foundAllPfandArticles.forEach(depositArticleGroup => {
				const foundPfandArticleIndex = depositArticleGroup.index;
				if (foundPfandArticleIndex >= 0) {
					if (action == 'add') {
						orderedArticles[depositArticleGroup.index].quantity =
							orderedArticles[depositArticleGroup.index].quantity + depositArticleGroup.newQuantaty;
					}
					if (action == 'minus') {
						orderedArticles[foundPfandArticleIndex].quantity =
							orderedArticles[foundPfandArticleIndex].quantity - depositArticleGroup.newQuantaty;
					}
				}
			});
			return orderedArticles.filter(v => v.quantity > 0);
		}
		return orderedArticles;
	}
	// function with Delete Articles with quantity of group
	async deletePfandArticle(orderedArticles: ArticleGroup[], articleGroup: ArticleGroup, index: number) {
		articleGroup = JSON.parse(JSON.stringify(articleGroup));
		const foundDeposit = numberD(articleGroup.article.deposit) > 0;
		if (foundDeposit) {
			const foundPfandArticle = orderedArticles.find(it =>
				it.article.tags.find(tag => tag.identifier == 'deposit' + numberD(articleGroup.article.deposit).toString().substring(2))
			);
			if (foundPfandArticle) {
				const orderArticlesFilter = (JSON.parse(JSON.stringify(orderedArticles)) as ArticleGroup[])
					.map(it => {
						if (it.article._id === foundPfandArticle.article._id) {
							const quantity = it.quantity - articleGroup.quantity;

							it.quantity = quantity;
						}
						return it;
					})
					.filter(it => it.quantity > 0);
				orderedArticles = orderArticlesFilter;
			}
			return orderedArticles;
		}
		articleGroup.groups = articleGroup.groups.filter((it: ArticleOption) => {
			return numberD(it.article.deposit) > 0;
		});
		if (articleGroup.groups.length > 0) {
			const articleGroupData = articleGroup.groups.map(it => {
				return {
					quantity: it.quantity,
					deposit: 'deposit' + numberD(it.article.deposit).toString().substring(2),
				};
			});

			const groupData = groupByToMap(
				articleGroupData,
				(articleGroupData: { quantity: number; deposit: string }) => articleGroupData.deposit
			);
			const quantityPare = {
				deposit8: groupData.get('deposit8')?.reduce((a, b) => a + b.quantity, 0) ?? 0,
				deposit15: groupData.get('deposit15')?.reduce((a, b) => a + b.quantity, 0) ?? 0,
				deposit25: groupData.get('deposit25')?.reduce((a, b) => a + b.quantity, 0) ?? 0,
			};
			const foundAllPfandArticles = new Map(
				orderedArticles
					.map((it, index) => {
						it.article.tags = it.article.tags.filter(
							tag =>
								tag.identifier.includes('deposit8') ||
								tag.identifier.includes('deposit15') ||
								tag.identifier.includes('deposit25')
						);
						if (it.article.tags.length > 0) {
							return {
								articleGroup: it,
								index: index,
								tag: it.article.tags[0]?.identifier,
								newQuantaty: quantityPare[it.article.tags[0]?.identifier],
							};
						}
						return null;
					})
					.filter(v => !!v)
					.map(it => [it.articleGroup.article._id, it])
			);
			const orderArticlesFilter = orderedArticles
				.map(it => {
					if (foundAllPfandArticles.has(it.article._id)) {
						const articleGroupQunatity = foundAllPfandArticles.get(it.article._id)?.newQuantaty * articleGroup.quantity;
						it.quantity = it.quantity - articleGroupQunatity;
					}
					return it;
				})
				.filter(it => {
					return it.quantity > 0;
				});
			orderedArticles = orderArticlesFilter;
			return orderedArticles;
		}
		return orderedArticles;
	}

	async onEditArticlePfand(venue: Venue, orderedArticles: ArticleGroup[], articleGroupOld: ArticleGroup, articleGroupNew: ArticleGroup) {
		articleGroupNew = JSON.parse(JSON.stringify(articleGroupNew));
		articleGroupOld = JSON.parse(JSON.stringify(articleGroupOld));
		const groupByToMap = <T, Q>(array: T[], predicate: (value: T, index: number, array: T[]) => Q) =>
			array.reduce((map, value, index, array) => {
				const key = predicate(value, index, array);
				map.get(key)?.push(value) ?? map.set(key, [value]);
				return map;
			}, new Map<Q, T[]>());
		articleGroupOld.groups = articleGroupOld.groups.filter((it: ArticleOption) => {
			return numberD(it.article.deposit) > 0;
		});
		articleGroupNew.groups = articleGroupNew.groups.filter((it: ArticleOption) => {
			return numberD(it.article.deposit) > 0;
		});
		if (articleGroupNew.groups.length > 0) {
			const oldGroup = articleGroupOld.groups.map(it => {
				return {
					quantity: it.quantity,
					deposit: 'deposit' + numberD(it.article.deposit).toString().substring(2),
				};
			});

			const old = groupByToMap(oldGroup, (oldGroup: { quantity: number; deposit: string }) => oldGroup.deposit);
			const quantityOld = {
				deposit8: old.get('deposit8')?.reduce((a, b) => a + b.quantity, 0) ?? 0,
				deposit15: old.get('deposit15')?.reduce((a, b) => a + b.quantity, 0) ?? 0,
				deposit25: old.get('deposit25')?.reduce((a, b) => a + b.quantity, 0) ?? 0,
			};

			const newGroup = articleGroupNew.groups.map(it => {
				return {
					quantity: it.quantity,
					deposit: 'deposit' + numberD(it.article.deposit).toString().substring(2),
				};
			});
			const newValue = groupByToMap(newGroup, (newGroup: { quantity: number; deposit: string }) => newGroup.deposit);
			const quantityNew = {
				deposit8: newValue.get('deposit8')?.reduce((a, b) => a + b.quantity, 0) ?? 0,
				deposit15: newValue.get('deposit15')?.reduce((a, b) => a + b.quantity, 0) ?? 0,
				deposit25: newValue.get('deposit25')?.reduce((a, b) => a + b.quantity, 0) ?? 0,
			};
			const deleteArticles: ArticleGroup[] = [];
			const newArticles: ArticleGroup[] = [];
			const mappedOrderedArticles = orderedArticles
				.map((it, index) => {
					it.article.tags = it.article.tags.filter(
						tag =>
							tag.identifier.includes('deposit8') ||
							tag.identifier.includes('deposit15') ||
							tag.identifier.includes('deposit25')
					);
					if (it.article.tags.length > 0) {
						return {
							articleGroup: it,
							index: index,
							tag: it.article.tags[0]?.identifier,
						};
					}
					return null;
				})
				.filter(v => !!v);
			const foundDeposit8 = mappedOrderedArticles.find(it =>
				it.articleGroup.article.tags.find(it => it.identifier.includes('deposit8'))
			);
			const foundDeposit15 = mappedOrderedArticles.find(it =>
				it.articleGroup.article.tags.find(it => it.identifier.includes('deposit15'))
			);
			const foundDeposit25 = mappedOrderedArticles.find(it =>
				it.articleGroup.article.tags.find(it => it.identifier.includes('deposit25'))
			);
			if (foundDeposit8) {
				if (quantityNew.deposit8 > 0) {
					foundDeposit8.articleGroup.quantity = foundDeposit8.articleGroup.quantity - quantityOld.deposit8 + quantityNew.deposit8;
					console.log(foundDeposit8.articleGroup.quantity, quantityOld.deposit15, quantityNew.deposit15);
					if (foundDeposit8.articleGroup.quantity < 1) {
						deleteArticles.push(foundDeposit8.articleGroup);
					}
				} else {
					if (quantityOld.deposit8 > 0 || quantityNew.deposit8 == 0) {
						if (foundDeposit8.articleGroup.article.tags.find(it => it.identifier.includes('deposit8'))) {
							foundDeposit8.articleGroup.quantity = foundDeposit8.articleGroup.quantity - quantityOld.deposit8;
							if (foundDeposit8.articleGroup.quantity < 1) {
								deleteArticles.push(foundDeposit8.articleGroup);
							}
						}
					}
				}
			} else {
				if (quantityNew.deposit8 > 0) {
					const foundArticle = ([].concat(...venue.articleCategories.map(it => it.articles)) as Article[]).find(it => {
						return it.tags.length > 0 && it.tags.find(it => it.identifier.includes('deposit15'));
					});
					if (foundArticle) {
						const articleGroupPfand = new ArticleGroup();
						articleGroupPfand.article = foundArticle;
						articleGroupPfand.quantity = quantityNew.deposit8 * articleGroupNew.quantity;
						articleGroupPfand.groups = [];
						newArticles.push(articleGroupPfand);
						quantityNew.deposit8 = 0;
					}
				}
			}
			if (foundDeposit15) {
				if (quantityNew.deposit15 > 0) {
					foundDeposit15.articleGroup.quantity =
						foundDeposit15.articleGroup.quantity - quantityOld.deposit15 + quantityNew.deposit15;
					if (foundDeposit15.articleGroup.quantity < 1) {
						deleteArticles.push(foundDeposit15.articleGroup);
					}
				} else {
					if (quantityOld.deposit15 > 0 || quantityNew.deposit15 == 0) {
						if (foundDeposit15.articleGroup.article.tags.find(it => it.identifier.includes('deposit15'))) {
							foundDeposit15.articleGroup.quantity = foundDeposit15.articleGroup.quantity - quantityOld.deposit15;
							if (foundDeposit15.articleGroup.quantity < 1) {
								deleteArticles.push(foundDeposit15.articleGroup);
							}
						}
					}
				}
			} else {
				if (quantityNew.deposit15 > 0) {
					const foundArticle = ([].concat(...venue.articleCategories.map(it => it.articles)) as Article[]).find(it => {
						return it.tags.length > 0 && it.tags.find(it => it.identifier.includes('deposit15'));
					});
					if (foundArticle) {
						const articleGroupPfand = new ArticleGroup();
						articleGroupPfand.article = foundArticle;
						articleGroupPfand.quantity = quantityNew.deposit15 * articleGroupNew.quantity;
						articleGroupPfand.groups = [];
						newArticles.push(articleGroupPfand);
						quantityNew.deposit15 = 0;
					}
				}
			}
			if (foundDeposit25) {
				if (quantityNew.deposit25 > 0) {
					foundDeposit25.articleGroup.quantity =
						foundDeposit25.articleGroup.quantity - quantityOld.deposit25 + quantityNew.deposit25;
					if (foundDeposit25.articleGroup.quantity < 1) {
						deleteArticles.push(foundDeposit25.articleGroup);
					}
				} else {
					if (quantityOld.deposit25 > 0 || quantityNew.deposit25 == 0) {
						if (foundDeposit25.articleGroup.article.tags.find(it => it.identifier.includes('deposit25'))) {
							foundDeposit25.articleGroup.quantity = foundDeposit25.articleGroup.quantity - quantityOld.deposit25;
							if (foundDeposit25.articleGroup.quantity < 1) {
								deleteArticles.push(foundDeposit25.articleGroup);
							}
						}
					}
				}
			} else {
				if (quantityNew.deposit25 > 0) {
					const foundArticle = ([].concat(...venue.articleCategories.map(it => it.articles)) as Article[]).find(it => {
						return it.tags.length > 0 && it.tags.find(it => it.identifier.includes('deposit25'));
					});
					if (foundArticle) {
						const articleGroupPfand = new ArticleGroup();
						articleGroupPfand.article = foundArticle;
						articleGroupPfand.quantity = quantityNew.deposit25 * articleGroupNew.quantity;
						articleGroupPfand.groups = [];
						newArticles.push(articleGroupPfand);
						quantityNew.deposit25 = 0;
					}
				}
			}

			orderedArticles = orderedArticles.map(it => {
				if (foundDeposit8 && it.article._id === foundDeposit8.articleGroup.article._id) {
					it = foundDeposit8.articleGroup;
				}
				if (foundDeposit15 && it.article._id === foundDeposit15.articleGroup.article._id) {
					it = foundDeposit15.articleGroup;
				}
				if (foundDeposit25 && it.article._id === foundDeposit25.articleGroup.article._id) {
					it = foundDeposit25.articleGroup;
				}
				return it;
			});
			if (deleteArticles.length > 0) {
				const mapDeletedArticles = new Map(deleteArticles.map(it => [it.article._id, it]));
				orderedArticles = orderedArticles.filter(it => !mapDeletedArticles.has(it.article._id));
			}
			if (newArticles.length > 0) {
				newArticles.forEach(it => orderedArticles.push(it));
			}
		}
		console.log(orderedArticles);
		return orderedArticles;
	}
}

// this function user for group data by params
const groupByToMap = <T, Q>(array: T[], predicate: (value: T, index: number, array: T[]) => Q) =>
	array.reduce((map, value, index, array) => {
		const key = predicate(value, index, array);
		map.get(key)?.push(value) ?? map.set(key, [value]);
		return map;
	}, new Map<Q, T[]>());
