import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import moment, { utc } from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { AlertController, ModalController, ViewDidEnter } from '@ionic/angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import RepositoryInterface from '../../../smoothr-web-app-core/directives/repository-directive';
import { OrderListAction } from '../../enums/OrderListAction';
import { AppComponent } from '../../app.component';
import { ModalInfoComponent } from '../modal-info/modal-info.component';
import { CheckoutModalComponent } from '../checkout-modal/checkout-modal.component';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import Article from '../../../smoothr-web-app-core/models/Article';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import { MenuPage } from '../../pages/menu/menu.page';
import Order from '../../../smoothr-web-app-core/models/Order';
import { PromoCodeType } from '../../../smoothr-web-app-core/models/PromoCodeType';
import {
	defaultsToArticleOption,
	defaultsToArticleOptionForOrder,
	getBasePrice,
	numberD,
	numberToCurrency,
	sleep,
} from '../../../smoothr-web-app-core/utils/utils';
import { AnalyticsService } from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import { NoteArticleModalComponent } from '../note-article-modal/note-article-modal.component';
import { NavigationService } from 'src/app/services/navigation.service';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import { PfandService } from 'src/app/services/pfand.service';
import { SanifairDismissActions, SanifairListComponent } from '../sanifair-list/sanifair-list.component';
import { InfoModalComponent } from '../info-modal/info-modal.component';
import { PaymentModalComponent, PaymentModalResult } from 'src/smoothr-web-app-core/components/payment-modal/payment-modal.component';
import { SanifairScanModelDismiss, ScanQrSanifairModal } from '../scan-qr-sanifair/scan-qr-sanifair.component';
import { SanifairInfoModalComponent } from '../sanifair-info-modal/sanifair-info-modal.component';
import { Api } from 'src/smoothr-web-app-core/api/api';
import { AskedRegisterModalComponent } from '../asked-register-modal/asked-register-modal.component';
import { InfoModalQuantityComponent } from '../info-modall-quantity/info-modal-quantity.component';
import { SwiperOptions } from 'swiper/types';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import { debounceTime } from 'rxjs';

@Component({
	selector: 'app-order-content',
	templateUrl: './order-content.component.html',
	styleUrls: ['order-content.component.scss'],
})
export class OrderContentComponent extends RepositoryInterface implements OnInit, ViewDidEnter {
	moment = moment;

	isValid = false;
	loading = false;

	ou = OrderUtils;
	pt = PreorderType;
	differenceToMvo: number;
	@Input()
	fromMenu = false;
	promoError: string;
	tip = 0;
	numberD = numberD;
	numberToCurrency = numberToCurrency;
	@ViewChild('swiperSlider') swiper: ElementRef | null;
	recommendationArticles: Article[] = [];
	options = {
		autoHide: false,
		scrollbarMinSize: 100,
	};
	public config: SwiperOptions = {
		slidesPerView: 2,
		// coverflowEffect: {
		// 	rotate: 0,
		// 	stretch: 15,
		// 	depth: 10,
		// 	modifier: 2,
		// },

		speed: 7000,

		// autoplay: {
		// 	delay: 2500,
		// 	disableOnInteraction: false,
		// },
		loop: true,
		allowTouchMove: true,
		slidesPerGroup: 2,

		freeMode: true,
		spaceBetween: 0,
		grabCursor: true,
		// autoplay: {
		// 	delay: 1,
		// 	disableOnInteraction: true,
		// },
	};
	constructor(
		private translate: TranslateService,
		protected repository: RepositoryService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private router: Router,
		private route: ActivatedRoute,
		private alertCtrl: AlertController,
		private analytics: AnalyticsService,
		private navigationService: NavigationService,
		private pfandService: PfandService
	) {
		super(repository);
		this.validate();
	}

	get hasArticles(): boolean {
		return !!this.order && this.order.orderedArticles.length > 0;
	}

	ngOnInit() {
		super.ngOnInit();
		this.analytics.openOrderContent();
		this.repository.recommendationList$.pipe(debounceTime(500)).subscribe(recommendationArt => {
			if (this.venue && recommendationArt.length > 0) {
				this.recommendationArticles = [];
				recommendationArt = recommendationArt.map(it => {
					// it.articles = it.articles.splice(0, 2);
					return it;
				});
				const categoryArticles = [].concat(...this.venue.articleCategories.map(it => it.articles)) as Article[];
				const categoryArticlesMap = new Map(categoryArticles.map(it => [it._id, it]));
				recommendationArt.forEach(it => {
					it.articles.forEach(art => {
						if (categoryArticlesMap.has(art)) {
							this.recommendationArticles.push(categoryArticlesMap.get(art));
						}
					});
				});
				console.log(this.recommendationArticles);
				this.startAutoPlaySlider();
			} else {
				this.recommendationArticles = [];
			}
		});
		console.log(this.router.url);
		if (this.router.url.includes('payment/fail')) {
			this.showCheckoutModal(this.verifiedOrder, true);
		}
	}
	ionViewDidEnter(): void {}

	onVenue() {
		super.onVenue();
		this.validate();
	}

	onOrder() {
		super.onOrder();
		if (this.order && this.order.orderedArticles && this.order.orderedArticles.length > 0) {
			this.analytics.orderHasProducts();
		}
		this.validate();
	}
	async onVerifiedOrder() {
		if (this.verifiedOrder) {
			await sleep(1000);
			if (this.order && this.order?.orderedArticles?.length > 0 && this.verifiedOrder?.orderedArticles?.length > 0) {
				const orderedArticlesMap = new Map(this.order.orderedArticles.map(it => [it.article._id, it]));
				this.verifiedOrder.orderedArticles = this.verifiedOrder.orderedArticles.map(it => {
					if (orderedArticlesMap.has(it?.article?._id)) {
						it.isCoupon = orderedArticlesMap.get(it?.article?._id)?.isCoupon;
					}
					return it;
				});
			}
		}
		console.log('test', this.verifiedOrder, this.order);
	}
	async startAutoPlaySlider() {
		try {
			await sleep(1500);
			this.swiper.nativeElement?.swiper?.autoplay?.start();
			// console.log(this.swiper.nativeElement.swiper.update())
		} catch (e) {
			console.log(e, 'Problem with playing swiper...');
		}
	}
	async addNewCustomArticle(index: number) {
		const articleGroup = this.order.orderedArticles[index];
		articleGroup.quantity = 1;
		const modal = await this.modalCtrl.create({
			cssClass: AppComponent.largeScreen ? 'item-modal large-modal' : 'item-modal',
			component: ModalInfoComponent,
			componentProps: {
				// copy article group
				articleGroup: JSON.parse(JSON.stringify(articleGroup)),
				recommend: false,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		if (response.data && response.data.articleGroup) {
			OrderUtils.addToOrder(this.order, response.data.articleGroup, this.analytics);

			this.repository.onOrderChange(this.order);
			sleep(300);
			const copyGroup = response.data.articleGroup;
			// this.addPfand(JSON.parse(JSON.stringify(copyGroup)));
		}
	}

	async onAction(index: number, event: OrderListAction) {
		console.log('event', event);
		if (event === OrderListAction.add) {
			const foundArticles = this.order.orderedArticles[index];
			if (foundArticles.groups.length > 0) {
				if (this.verifiedOrder && !this.verifiedOrder.isPayed) {
					this.repository.onOrderChange(this.order);
					return;
				}
				const askChangeAmount = await InfoModalQuantityComponent.show(this.modalCtrl, {
					title: this.translate.instant('info_change_amount_dialog.title'),
					mainButton: this.translate.instant('info_change_amount_dialog.yes'),
					closeButton: this.translate.instant('info_change_amount_dialog.no'),
				});
				if (askChangeAmount === false) {
					this.addNewCustomArticle(index);
					return;
				}
			}
		}
		switch (event) {
			case OrderListAction.add:
				this.order.orderedArticles[index].quantity += 1;
				// this.changeArticlePfand(this.order.orderedArticles[index], 'add');
				break;
			case OrderListAction.delete:
				if (OrderUtils.isBogoOrFreeArticlePromo(this.order) && this.order.promoCode.type === PromoCodeType.BOGO) {
					const bogoArticleIndex = this.order.orderedArticles.findIndex(ag => ag.isPromo);
					const bogoArticle = this.order.orderedArticles[bogoArticleIndex];
					const matchingBogoArticleIndices = this.order.orderedArticles
						.map((ag, agIndex) => {
							return ag.article._id === bogoArticle.article._id &&
								!ag.isPromo &&
								OrderUtils.bogoPrice(ag, this.order.type, this.order.preorder.type) ===
									OrderUtils.bogoPrice(bogoArticle, this.order.type, this.order.preorder.type)
								? agIndex
								: -1;
						})
						.filter(agIndex => agIndex !== -1);
					if (matchingBogoArticleIndices.find(bogoIndex => bogoIndex === index) !== undefined) {
						// article that should be removed is a possible parent of promoCode article
						if (matchingBogoArticleIndices.length === 1) {
							// only that article is possible parent of promoCode. If this article is removed the PromoCode and PromoArticle
							// should also be removed. Ask user.
							const bogoAlert = await this.alertCtrl.create({
								header: this.translate.instant('remove_bogo_alert.header'),
								message: this.translate.instant('remove_bogo_alert.message'),
								buttons: [
									{
										text: this.translate.instant('remove_bogo_alert.button_remove'),
										handler: () => {
											this.order.orderedArticles[bogoArticleIndex].isPromo = false;
											this.order.promoCode = null;
											this.order.orderedArticles.splice(index, 1);
											bogoAlert.dismiss();
										},
									},
									{
										text: this.translate.instant('remove_bogo_alert.button_keep'),
										handler: () => {
											bogoAlert.dismiss();
										},
									},
								],
							});
							await bogoAlert.present();
							await bogoAlert.onDidDismiss();
							break;
						}
					}
				}
				const articleData = this.order.orderedArticles[index];
				if (articleData.controlEan) {
					console.log(this.order.orderedArticles.length);
					this.order.orderedArticles.splice(index, 1);
					console.log(this.order.orderedArticles.length);
					console.log(articleData.controlEan);

					const foundIndex = this.order.orderedArticles.findIndex(
						it => it.isCoupon && Number(it.article.gtin) === Number(articleData.controlEan)
					);
					console.log(foundIndex, this.order.orderedArticles);
					if (foundIndex >= 0) {
						this.order.orderedArticles.splice(foundIndex, 1);
					}
					this.repository.onOrderChange(this.order);
					return;
				}
				this.order.orderedArticles.splice(index, 1);

				// await this.deletePfandArticle(articleData, index);
				break;
			case OrderListAction.note:
				this.noteArticle(index);
				break;
			case OrderListAction.edit:
				const modal = await this.modalCtrl.create({
					cssClass: AppComponent.largeScreen ? 'item-modal large-modal' : 'item-modal',
					component: ModalInfoComponent,
					componentProps: {
						// copy article group
						articleGroup: JSON.parse(JSON.stringify(this.order.orderedArticles[index])),
						recommend: false,
					},
					showBackdrop: true,
					backdropDismiss: true,
				});
				await modal.present();
				const response = await modal.onDidDismiss();
				if (response.data && response.data.articleGroup) {
					const promoAgIndex = this.order.orderedArticles.findIndex(oa => oa.isPromo);
					if (
						OrderUtils.isBogoOrFreeArticlePromo(this.order) &&
						this.order.promoCode.type === PromoCodeType.BOGO &&
						this.order.orderedArticles[index].article._id === this.order.orderedArticles[promoAgIndex].article._id &&
						OrderUtils.bogoPrice(response.data.articleGroup, this.order.type, this.order.preorder.type) <
							OrderUtils.bogoPrice(this.order.orderedArticles[promoAgIndex], this.order.type, this.order.preorder.type)
					) {
						this.order.orderedArticles[promoAgIndex].isPromo = false;
						const newPromoAg = JSON.parse(JSON.stringify(response.data.articleGroup));
						newPromoAg.isPromo = true;
						this.order.orderedArticles.push(newPromoAg);
					}

					// this.order.orderedArticles = await this.pfandService.onEditArticlePfand(
					// 	this.venue,
					// 	this.order.orderedArticles,
					// 	this.order.orderedArticles[index],
					// 	response.data.articleGroup
					// );
					this.order.orderedArticles[index] = response.data.articleGroup;
				}
				break;
			case OrderListAction.remove:
				if (this.order.orderedArticles[index].quantity > 1) {
					this.order.orderedArticles[index].quantity -= 1;
					// this.changeArticlePfand(this.order.orderedArticles[index], 'minus');
				} else {
					await this.onAction(index, OrderListAction.delete);
					return;
				}
				break;
		}
		this.repository.onOrderChange(this.order);
	}
	async invalidateorder(orderId: string) {
		try {
			const result = await Api.invalidateOrder(orderId);
			console.log(result);
		} catch (e) {
			console.log('Error while updating');
		}
	}
	mapArticleGroup(articleGroup: ArticleGroup) {
		if (this.venue) {
			const a = this.venue.articleCategories
				.map(cat => {
					return cat.articles.find(art => {
						return art._id === articleGroup.article._id;
					});
				})
				.filter(it => !!it);
			articleGroup.article = a[0];

			return articleGroup;
		}
		return articleGroup;
	}
	async scanSanifairCoupon() {
		try {
			if (this.loading) {
				return;
			}
			this.loading = true;
			if (!(this.verifiedOrder && this.verifiedOrder._id && this.verifiedOrder.sanifairVouchers.length > 0)) {
				try {
					const result = await this.repository.sendOrderWithoutNavToPayment(
						this.modalCtrl,
						this.translate,
						this.verifiedOrder && this.verifiedOrder?.sanifairVouchers ? this.verifiedOrder : this.order,
						this.tip,
						loading => (this.loading = loading),
						this.snackbarCtrl,
						true
					);
					console.log(result);
					if (result?.error) {
						this.snackbarCtrl.open(this.translate.instant('error_creating_order'), null, { duration: 4000 });
						return;
					}
					console.log(result);
				} catch (e) {
					if (e) {
						this.snackbarCtrl.open(this.translate.instant('error_creating_order'), null, { duration: 4000 });
					}
					return;
				}

				await sleep(200);
			}

			const sanifairResult = await ScanQrSanifairModal.show(this.modalCtrl, this.venue, this.verifiedOrder);
			console.log('Sanifair Result', sanifairResult);
			this.loading = false;

			if (sanifairResult) {
				if (sanifairResult?.verifiedOrder) {
					const result = await SanifairListComponent.show(
						this.modalCtrl,
						sanifairResult.verifiedOrder?.sanifairVouchers,
						sanifairResult?.verifiedOrder,
						this.venue
					);
					if (result.event === SanifairDismissActions.ORDER) {
						return;
					}
				}
				if (sanifairResult.event === SanifairScanModelDismiss.ORDER) {
					return;
				}
				this.loading = false;

				return;
			}
		} catch (e) {
			this.loading = false;

			this.snackbarCtrl.open('Sorry, smth goes wrong with creating order...', null, {
				duration: 4000,
			});
		}
	}
	async showCheckoutModal(order: Order, isSecondSlide: boolean = false) {
		const result = await CheckoutModalComponent.show(this.modalCtrl, this.analytics, order, this.tip, isSecondSlide, this.translate);
		if (result.data === undefined) {
			this.loading = false;
			return;
		}
		order.orderAt = result.data.orderAt;
		order.preorder = result.data.preorder;
		if (order?.preorder?.lat && order?.preorder?.lng) {
			const lat = order?.preorder?.lat.toString() as any;
			const lng = order?.preorder?.lng.toString() as any;
			order.preorder.lat = lat;
			order.preorder.lng = lng;
		}
		order.asap = true;
		order.sendToSalesforce = result.data.sendToSalesforce;
		console.log(order);
		if (!order._id) {
			this.repository.onOrderChange(this.order);
		}
		await this.createOrderAndPay(JSON.parse(JSON.stringify(order)));
		this.loading = false;
	}
	async openCheckoutModal() {
		this.validate();
		if (!this.isValid || this.loading) {
			return;
		}
		if (this.customer?.isAnonymous) {
			const result = await AskedRegisterModalComponent.show(this.modalCtrl);
			console.log(result);
			if (result == undefined) {
				return;
			}
			if (result) {
				this.navigationService.signIn();
				return;
			}
		}

		this.loading = true;

		const order = this.verifiedOrder ?? this.order;
		this.showCheckoutModal(order);
	}
	async paymentOrder() {
		const result = await PaymentModalComponent.show(
			this.modalCtrl,
			this.venue,
			[this.repository._verifiedOrder],
			this.customer,
			this.tip,
			false
		);
		if (result && result.result === PaymentModalResult.SUCCESS) {
			this.repository.payment.emit(result.payment);
			await this.navigationService.paymentSuccess(this.repository._verifiedOrder._id, result.payment._id);
			return;
		}
	}
	async createOrderAndPay(order: Order) {
		try {
			await this.repository.createOrderWithPayment(
				this.modalCtrl,
				this.translate,
				order,
				this.tip,
				loading => (this.loading = loading),
				this.snackbarCtrl,
				true
			);
			this.loading = false;
		} catch (e) {
			console.log(e);
			this.loading = false;

			console.log(JSON.parse(JSON.stringify(e)));
		}
	}

	validate() {
		if (!this.order || !this.venue || !this.order.preorder) {
			this.isValid = false;
			return;
		}
		const result = OrderUtils.validateOrder(this.venue, this.order);
		this.differenceToMvo = result.movDifference;
		this.isValid = result.valid;
	}

	totalPrice(order: Order): string {
		return numberToCurrency(OrderUtils.orderTotalPrice(order, true, true) + this.tip, order.currency);
	}

	replace(index: number, article: Article, options: ArticleOption[]) {
		this.order.orderedArticles[index].quantity = 1;
		this.order.orderedArticles[index].article = article;
		this.order.orderedArticles[index].groups = options;
	}

	onPromoApplied(order: Order) {
		this.promoError = null;
		this.repository.onOrderChange(order);
	}

	onDeletePromo(order: Order) {
		this.promoError = null;
		this.repository.onOrderChange(order);
	}

	onPromoCodeError(error: string) {
		if (!error) {
			// error just resetting error no error happened
			return;
		}
		// Promo error
		this.snackbarCtrl.open(error, null, { duration: 2000 });
		this.repository.onOrderChange(OrderUtils.removePromo(this.order));
		this.promoError = error;
	}

	async backToMenu() {
		await this.navigationService.menu();
	}

	async noteArticle(index: number) {
		const modal = await this.modalCtrl.create({
			cssClass: 'short-info-modal auto-height',
			component: NoteArticleModalComponent,
			componentProps: {
				// copy article group
				articleGroup: JSON.parse(JSON.stringify(this.order.orderedArticles[index])),
				recommend: false,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		if (response?.data) {
			this.order.orderedArticles[index].note = response?.data;
		}
	}
	checkIfOrdersTheSame(order: Order, verifiedOrder: Order) {
		const mappedVerifiedOrder = new Map((verifiedOrder?.orderedArticles ?? []).map(it => [it?.article?._id, it?.quantity]));
		return (
			order?._id &&
			order.orderedArticles.every(
				it => mappedVerifiedOrder.has(it.article._id) && mappedVerifiedOrder.get(it.article._id) === it.quantity
			)
		);
	}
	hasSanifair(order: Order) {
		const copyOrder = JSON.parse(JSON.stringify(order)) as Order;
		return !!copyOrder?.orderedArticles?.find(artGrop => !!artGrop?.article?.tags?.find(it => it?.identifier === 'sanifair_voucher'));
	}
	async extends() {
		if (this.verifiedOrder) {
			try {
				const response = await Api.extendSanifairVoucher(this.verifiedOrder._id);
			} catch (e) {}
		}
	}
	calcArticlePrice(article: Article) {
		let price = 0;
		const articleGroup = new ArticleGroup();
		articleGroup.article = article;
		articleGroup.groups = defaultsToArticleOption(article, [], article.defaults, this.order.type, this.order.preorder.type);
		articleGroup.quantity = 1;
		price = OrderUtils.articleGroupsTotalPrice([articleGroup], this.order.type, this.order.preorder.type);

		const priceText = numberToCurrency(price, this.order.currency);
		const formatText = priceText.toString().split(/[,.]/);
		return (
			'<div class="price_transform"><div class="full_price">' +
			formatText[0] +
			'.' +
			'</div><div class="rest_price">' +
			formatText[1] +
			'</div><div class="rest_price_hide">' +
			formatText[1] +
			'</div></div>'
		);
	}
	async addArticle(article: Article) {
		const articleGroup = new ArticleGroup();
		articleGroup.article = article;
		articleGroup.groups.push(...defaultsToArticleOption(article, [], article.defaults, this.order?.type, this.order.preorder.type));

		articleGroup.quantity = 1;
		const modal = await this.modalCtrl.create({
			cssClass: AppComponent.largeScreen ? 'item-modal large-modal' : 'item-modal',
			component: ModalInfoComponent,
			componentProps: {
				articleGroup,
				showOnlyRequiredGroups: true,
			},
			mode: 'ios',
			backdropDismiss: true,
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		if (response.data && response.data.articleGroup) {
			OrderUtils.addToOrder(this.order, response.data.articleGroup, this.analytics);

			this.repository.onOrderChange(this.order);
			sleep(300);
		}
	}
	checkMeasurmentPlusPfand(article: Article) {
		let result = '';
		if (!this.order) {
			return;
		}
		const pfandValue = this.checkPfandTag(article);
		if (pfandValue) {
			result =
				this.translate.instant('pfand_text.zzgl') +
				(pfandValue === 'deposit15' ? numberToCurrency(0.15, this.order.currency) : numberToCurrency(0.25, this.order.currency)) +
				this.translate.instant('pfand_text.pfand') +
				'<br/>';
		} else {
			if (result.length > 0) {
				result = result + ')';
			}
		}
		if (article?.measurement && article?.measurement?.unit && article?.measurement?.amount) {
			result =
				result +
				' ' +
				article.measurement?.refAmount +
				' ' +
				article.measurement?.refUnit +
				' = ' +
				this.numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.order.preorder.type), this.order.currency);
		}
		return result;
	}
	checkPfandTag(article: Article) {
		if (article.tags?.length === 0) {
			return '';
		}
		const deposit25 = article.tags.find(it => it.identifier === 'deposit25') ? 'deposit25' : '';
		const deposit15 = article.tags.find(it => it.identifier === 'deposit15') ? 'deposit15' : '';

		return deposit15 || deposit25;
	}
}
