import { AfterViewInit, Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import Article from 'src/smoothr-web-app-core/models/Article';
import Compound from 'src/smoothr-web-app-core/models/Compound';
import Localization from '../../../smoothr-web-app-core/models/Localization';

@Component({
	selector: 'app-short-info-html',
	templateUrl: './short-info-html.component.html',
	styleUrls: ['short-info-html.component.scss'],
})
export class ShortInfoHtmlComponent implements AfterViewInit {
	html: { de: string };
	environment = environment;

	constructor(private modalCtrl: ModalController) {}
	ngAfterViewInit(): void {}
	static async show(modalCtrl: ModalController, html: { de: string }) {
		const modal = await modalCtrl.create({
			cssClass: 'short-info-modal auto-height',
			component: ShortInfoHtmlComponent,
			componentProps: {
				html,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
