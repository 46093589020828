import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';

@Component({
	selector: 'app-order-page-toolbar',
	templateUrl: './order-page-toolbar.component.html',
	styleUrls: ['order-page-toolbar.component.scss'],
})
export class OrderPageToolbarComponent implements OnInit {
	@Output()
	backClick = new EventEmitter<void>();
	@Output()
	close = new EventEmitter<void>();
	@Input()
	preorderType: PreorderType;
	@Input()
	title: string;
	@Input()
	hideElements: boolean;
	environment = environment;

	constructor() {}

	ngOnInit() {}

	onBackClick() {
		this.backClick.emit();
	}
	onClose() {
		this.close.emit();
	}
}
