import { Directive, ElementRef, Input } from '@angular/core';
import Article from 'src/smoothr-web-app-core/models/Article';
import { SwiperOptions } from 'swiper/types';
import { ShortInfoModalComponent } from '../components/short-info-modal/short-info-modal.component';
import { ModalController } from '@ionic/angular';

@Directive({
	selector: '[addInfoIcon]',
})
export class AddInfoIconDirective {
	private readonly swiperElement: HTMLElement;
	@Input() config?: SwiperOptions;
	@Input() option: Article;
	@Input() modalCtrl: ModalController;
	constructor(public element: ElementRef<HTMLElement>) {
		// let firstArray = arrayOfWords(it => it)
	}

	ngAfterViewInit(): void {
		const value = this.element.nativeElement.innerText;

		const arrayOfWords = value.split(' ').filter(it => it !== '');
		let firstPart = [];
		let wordWithInfo = '';
		for (let index = 0; index < arrayOfWords.length; index++) {
			const element = arrayOfWords[index];

			if (arrayOfWords.length == 1) {
				wordWithInfo = element;
			} else {
				if (index == arrayOfWords.length - 1) {
					wordWithInfo = arrayOfWords[index];
				} else {
					firstPart.push(element);
				}
			}
		}

		this.element.nativeElement.innerText = '';
		this.element.nativeElement.innerHTML = firstPart.join(' ') + '<div>' + wordWithInfo + '</div>';
		// document.getElementById('optionId' + this.option._id).addEventListener('click', () => {
		//     this.openInfoClick(this.option)
		//     // $event.stopPropagation()
		// })
		setTimeout(() => {
			console.log(document.getElementById('optionId' + this.option._id));
		}, 500);
	}
	async openInfoClick(article: Article) {
		await ShortInfoModalComponent.show(this.modalCtrl, article);
	}
}
