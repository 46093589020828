// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 15px;
  border-radius: 15px;
  border: 0.5px solid #e5e5e5;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(33, 33, 33, 0.02);
}

ion-img {
  height: 100%;
  width: 100%;
}

.logo_vrapper {
  margin-right: 15px;
}

.text_wrapper {
  flex-grow: 1;
  margin-right: 15px;
}
.text_wrapper .title {
  font-size: 16px;
  margin-bottom: 5px;
}
.text_wrapper .price {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/sanifair-item/sanifair-item.component.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,uBAAA;EACA,mBAAA;EAEC,kBAAA;EAED,mBAAA;EACA,2BAAA;EACA,gBAAA;EACA,mDAAA;AADD;;AAIA;EACE,YAAA;EACA,WAAA;AADF;;AAIA;EACE,kBAAA;AADF;;AAIA;EACE,YAAA;EACA,kBAAA;AADF;AAGE;EACE,eAAA;EACA,kBAAA;AADJ;AAIE;EACE,eAAA;AAFJ","sourcesContent":[".wrapper {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\n  padding: 12px 15px;\n\n\tborder-radius: 15px;\n\tborder: 0.5px solid #e5e5e5;\n\tbackground: #fff;\n\tbox-shadow: 0px 4px 10px 0px rgba(33, 33, 33, 0.02);\n}\n\nion-img {\n  height: 100%;\n  width: 100%;\n}\n\n.logo_vrapper {\n  margin-right: 15px;\n}\n\n.text_wrapper {\n  flex-grow: 1;\n  margin-right: 15px;\n\n  .title {\n    font-size: 16px;\n    margin-bottom: 5px;\n  }\n\n  .price {\n    font-size: 12px;\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
