// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  padding: 0 10px;
}

ion-footer.invalid {
  background: darkgrey;
  color: grey;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/order/order.page.scss"],"names":[],"mappings":"AACA;EACE,eAAA;AAAF;;AAGA;EACE,oBAAA;EACA,WAAA;AAAF","sourcesContent":["\nion-content {\n  padding: 0 10px;\n}\n\nion-footer.invalid {\n  background: darkgrey;\n  color: grey;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
