import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import Article from 'src/smoothr-web-app-core/models/Article';
import Order from 'src/smoothr-web-app-core/models/Order';
import { getPrice, numberToCurrency } from 'src/smoothr-web-app-core/utils/utils';
import { ShortInfoModalComponent } from '../short-info-modal/short-info-modal.component';

export enum AddToBasketCoupon {
	ADD_AND_PAY = 'addpay',
	SELECT_ANOTHER = 'select_another',
}
@Component({
	selector: 'app-add-coupon-article-dialog',
	templateUrl: './add-coupon-article-dialog.component.html',
	styleUrls: ['./add-coupon-article-dialog.component.scss'],
})
export class AddCouponArticleDialogComponent implements OnInit {
	article: Article;
	order: Order;
	numberToCurrency = numberToCurrency;
	addToBasketEnum = AddToBasketCoupon;
	constructor(private modalCtrl: ModalController) {}
	static async show(modalCtrl: ModalController, article: Article, order: Order): Promise<AddToBasketCoupon> {
		const modal = await modalCtrl.create({
			component: AddCouponArticleDialogComponent,
			cssClass: 'coupon-dialog auto-height',
			componentProps: {
				article,
				order,
			},
			showBackdrop: true,
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		return result;
	}
	ngOnInit() {}
	price(article: Article) {
		if (!this.order) {
			return;
		}
		return getPrice(article, OrderType.PREORDER, this.order.preorder.type);
	}
	dismiss(value: AddToBasketCoupon) {
		this.modalCtrl.dismiss(value);
	}
	async openInfoClick(article: Article) {
		await ShortInfoModalComponent.show(this.modalCtrl, article);
	}
}
