import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';

interface ModalInfoItem {
	title?: string;
	info?: string;
	mainButton?: string;
	closeButton?: string;
}

@Component({
	selector: 'app-info-modal-quantity',
	templateUrl: './info-modal-quantity.component.html',
	styleUrls: ['./info-modal-quantity.component.scss'],
})
export class InfoModalQuantityComponent {
	constructor(private modalCtrl: ModalController) {
		// setTimeout(() => {
		// 	this.dismiss(false);
		// }, 6000);
	}

	title = '';
	info = '';
	mainButton = '';
	closeButton = '';

	static async show(modalCtrl: ModalController, { title, info, mainButton, closeButton }: ModalInfoItem) {
		const modal = await modalCtrl.create({
			cssClass: 'auto-height info-modal-custom dialog-primary-background',
			component: InfoModalQuantityComponent,
			componentProps: {
				title,
				info,
				mainButton,
				closeButton,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});

		await modal.present();
		const res = (await modal.onDidDismiss()).data;
		await sleep(100);
		return res;
	}
	dismiss(value: boolean) {
		this.modalCtrl.dismiss(value);
	}
}
