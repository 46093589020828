// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle-wrap {
  width: 35px;
  height: 35px;
  position: absolute;
  right: -5px;
  top: 3px;
  transform: translateY(-50%);
}

.left {
  left: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/feature-icon/feature-icon.component.scss"],"names":[],"mappings":"AAEA;EACC,WAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,QAAA;EACA,2BAAA;AADD;;AAKA;EACC,SAAA;AAFD","sourcesContent":["@import \"../../../theme/mixins\";\n\n.circle-wrap {\n\twidth: 35px;\n\theight: 35px;\n\tposition: absolute;\n\tright: -5px;\n\ttop: 3px;\n\ttransform: translateY(-50%);\n\n}\n\n.left {\n\tleft: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
