// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  margin-top: 10px;
}

.button-container .action-btn {
  border-radius: 0;
  width: 50%;
  margin-right: 10px;
  text-align: center;
  padding: 15px;
  background: var(--ion-color-second);
  color: var(--ion-color-main);
}
.button-container .action-btn.negative {
  margin-right: 0;
  margin-left: 10px;
  background: #e2e2e2;
}`, "",{"version":3,"sources":["webpack://./src/app/components/delivery-not-available-modal/delivery-not-available-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAGE;EACE,gBAAA;EACA,UAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,mCAAA;EACA,4BAAA;AAAJ;AAEI;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AAAN","sourcesContent":["h1 {\n  margin-top: 10px;\n}\n\n.button-container {\n  .action-btn {\n    border-radius: 0;\n    width: 50%;\n    margin-right: 10px;\n    text-align: center;\n    padding: 15px;\n    background: var(--ion-color-second);\n    color: var(--ion-color-main);\n\n    &.negative {\n      margin-right: 0;\n      margin-left: 10px;\n      background: #e2e2e2;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
