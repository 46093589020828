import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { SignUpPage } from './sign-up.page';
import { SharedModuleModule } from '../../shared-module/shared-module.module';
// import { IonIntlTelInputModule } from 'ion-intl-tel-input';

const routes: Routes = [
	{
		path: '',
		component: SignUpPage,
	},
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		ReactiveFormsModule,
		RouterModule.forChild(routes),
		SharedModuleModule,
		// IonIntlTelInputModule,
	],
	declarations: [SignUpPage],
})
export class SignUpPageModule {}
