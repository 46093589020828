// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  color: white;
  fill: white;
}

ion-icon {
  margin: 16px 0;
  height: 36px;
  width: 100%;
}

p {
  line-height: 22px;
}

h1 {
  margin: 0;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 4px;
}

ion-button {
  border-radius: 4px;
  border: 2px solid white;
  text-transform: none;
  font-weight: bold;
  font-family: AppFont-bold, sans-serif;
  --padding: 13px;
  --box-shadow: none;
}
ion-button.button-positive {
  color: var(--ion-color-primary);
  --background: white;
}
ion-button.button-negative {
  margin-top: 4px;
  --background: #00000000;
}

ion-row {
  padding: 8px;
}
ion-row ion-col {
  padding: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/allow-gps-modal/allow-gps-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;AACF;;AAEA;EACE,cAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,SAAA;EACA,uBAAA;EAAA,kBAAA;EACA,cAAA;AACF;;AAEA;EACE,kBAAA;EACA,uBAAA;EACA,oBAAA;EACA,iBAAA;EACA,qCAAA;EACA,eAAA;EACA,kBAAA;AACF;AACE;EACE,+BAAA;EACA,mBAAA;AACJ;AAEE;EACE,eAAA;EACA,uBAAA;AAAJ;;AAIA;EACE,YAAA;AADF;AAGE;EACE,aAAA;AADJ","sourcesContent":["* {\n  color: white;\n  fill: white;\n}\n\nion-icon {\n  margin: 16px 0;\n  height: 36px;\n  width: 100%;\n}\n\np {\n  line-height: 22px;\n}\n\nh1 {\n  margin: 0;\n  width: fit-content;\n  padding: 0 4px;\n}\n\nion-button {\n  border-radius: 4px;\n  border: 2px solid white;\n  text-transform: none;\n  font-weight: bold;\n  font-family: AppFont-bold, sans-serif;\n  --padding: 13px;\n  --box-shadow: none;\n\n  &.button-positive {\n    color: var(--ion-color-primary);\n    --background: white;\n  }\n\n  &.button-negative {\n    margin-top: 4px;\n    --background: #00000000;\n  }\n}\n\nion-row {\n  padding: 8px;\n\n  ion-col {\n    padding: 12px\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
