import { DisplayMode } from '../enums/DisplayMode';
import Localization from './Localization';
import Article from './Article';
import Assets from './Assets';
import { DisplayIdentifier } from '../enums/DisplayIdentifier';
import Tag from './Tag';

export default class OptionGroup {
	// tslint:disable-next-line:variable-name
	_id?: string;
	articles?: Article[];
	description?: Localization;
	displayMode?: DisplayMode;
	displayModeMobile?: DisplayMode;
	hasMultiple: boolean;
	limit: number;
	name: Localization;
	requiredAmount: number;
	displayIdentifiers: DisplayIdentifier[];
	sortOrder: number;
	backgroundImage: Assets;
	venue?: string;
	visible: boolean;
	tags: Tag[];
}
