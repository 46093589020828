import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';

interface ModalInfoItem {
	title?: string;
	info?: string;
	mainButton?: string;
	closeButton?: string;
}

@Component({
	selector: 'app-sanifair-info-modal',
	templateUrl: './sanifair-info-modal.component.html',
	styleUrls: ['./sanifair-info-modal.component.scss'],
})
export class SanifairInfoModalComponent {
	constructor(private modalCtrl: ModalController) {
		// setTimeout(() => {
		// 	this.dismiss(false);
		// }, 6000);
	}
	static async show(modalCtrl: ModalController) {
		const modal = await modalCtrl.create({
			cssClass: 'sanifair-info-modal auto-height',
			component: SanifairInfoModalComponent,
			componentProps: {},
			showBackdrop: true,
			backdropDismiss: true,
		});

		await modal.present();
		const res = (await modal.onDidDismiss()).data;
		await sleep(100);
		return res;
	}
	dismiss(value: boolean) {
		this.modalCtrl.dismiss(value);
	}
}
