import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalController } from '@ionic/angular';
import { throws } from 'assert';
import { Api } from 'src/smoothr-web-app-core/api/api';
import Order from 'src/smoothr-web-app-core/models/Order';
import SanifairVoucher from 'src/smoothr-web-app-core/models/SanifairVoucher';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import { OrderUtils } from 'src/smoothr-web-app-core/utils/order-utils';
import { numberD, numberToCurrency, sleep } from 'src/smoothr-web-app-core/utils/utils';
import { ScanQrSanifairModal } from '../scan-qr-sanifair/scan-qr-sanifair.component';

export enum SanifairDismissActions {
	CLOSE = 'close',
	ORDER = 'order',
}
@Component({
	selector: 'app-sanifair-list',
	templateUrl: './sanifair-list.component.html',
	styleUrls: ['./sanifair-list.component.scss'],
})
export class SanifairListComponent implements OnInit {
	sanifairVouchers: SanifairVoucher[] = [];
	verifiedOrder: Order = null;
	venue: Venue = null;
	numberToCurrency = numberToCurrency;
	constructor(
		private modalCtrl: ModalController,
		private snackBar: MatSnackBar
	) {}

	static async show(
		modalCtrl: ModalController,
		sanifairVouchers: any,
		verifiedOrder: Order,
		venue: Venue
	): Promise<{ event: SanifairDismissActions }> {
		const modal = await modalCtrl.create({
			component: SanifairListComponent,
			cssClass: 'delivery-modal',
			componentProps: {
				sanifairVouchers: sanifairVouchers,
				verifiedOrder: verifiedOrder,
				venue: venue,
			},
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		await sleep(100);
		return result.data;
	}
	ngOnInit() {}

	filterArticleGroupsOnlySanifair(order: Order) {
		const copy = JSON.parse(JSON.stringify(order?.orderedArticles)) as Order;
		return (order?.orderedArticles ?? []).filter(art => !!art?.article?.tags?.find(it => it?.identifier === 'sanifair_voucher'));
	}
	totalPriceBeforeDiscount(order: Order) {
		let copyOrder = JSON.parse(JSON.stringify(order)) as Order;
		copyOrder.orderedArticles = (order?.orderedArticles ?? []).filter(
			art => !art?.article?.tags?.find(it => it?.identifier === 'sanifair_voucher')
		);

		return numberToCurrency(OrderUtils.orderTotalPrice(copyOrder, true, true), copyOrder.currency);
	}
	async dismiss() {
		this.modalCtrl.dismiss();
	}

	totalDiscount(order: Order) {
		let copyOrder = JSON.parse(JSON.stringify(order)) as Order;
		copyOrder.orderedArticles = (order?.orderedArticles ?? []).filter(
			art => !!art?.article?.tags?.find(it => it?.identifier === 'sanifair_voucher')
		);
		return numberToCurrency(OrderUtils.orderTotalPrice(copyOrder, true, true), copyOrder.currency);
	}
	async scanNewSanifairCoupon() {
		const quantityOfArticles = this.verifiedOrder.orderedArticles.reduce((acc, artGroup) => acc + artGroup.quantity, 0);
		// if (quantityOfArticles > this.verifiedOrder.sanifairVouchers?.length) {
		const sanifairResult = await ScanQrSanifairModal.show(this.modalCtrl, this.venue, this.verifiedOrder, true);

		if (sanifairResult) {
			if (sanifairResult?.verifiedOrder) {
				this.sanifairVouchers = sanifairResult?.verifiedOrder?.sanifairVouchers;
				this.verifiedOrder = sanifairResult?.verifiedOrder;
			}
		}
		// }
	}
	checkIfUserCanAddNewSanifairCoupon() {
		const quantityOfArticles = this.verifiedOrder.orderedArticles
			.filter(artGroup => numberD(artGroup.article.price) > 0)
			.reduce((acc, artGroup) => acc + artGroup.quantity, 0);
		return quantityOfArticles > this.verifiedOrder.sanifairVouchers?.length;
	}
	navigateToPayment() {
		this.modalCtrl.dismiss({
			event: SanifairDismissActions.ORDER,
		});
	}
	deleteSanifair(itemId: string) {
		// this.verifiedOrder.i
	}
}
