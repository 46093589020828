import { Component, OnDestroy, OnInit } from '@angular/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Api } from '../../../smoothr-web-app-core/api/api';
import { Gender } from 'src/smoothr-web-app-core/enums/Gender';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, debounceTime } from 'rxjs';
import { ValidationUtils } from '../../../smoothr-web-app-core/utils/validation-utils';
import { AuthStrategy } from '../../../smoothr-web-app-core/models/AuthStrategy';
import { axiosErrorToMessage } from '../../../smoothr-web-app-core/utils/utils';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { NavigationService } from 'src/app/services/navigation.service';
import { MapsUtils } from 'src/smoothr-web-app-core/utils/maps-utils';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { PhoneCountry } from 'src/app/components/checkout-modal/checkout-modal.component';
import { HtmlVisualizerModalComponent, PageEnum } from 'src/app/components/html-visualizer-modal/html-visualizer-modal.component';
import { ModalController } from '@ionic/angular';
import { Location } from '@angular/common';

@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.page.html',
	styleUrls: ['sign-up.page.scss'],
})
export class SignUpPage implements OnDestroy, OnInit {
	static url = 'sign-up';
	genderValue = Gender;
	showRePassword = false;
	showPassword = false;
	isValidEmail = true;
	isValidName = true;
	isValidPassword = true;
	isValidRePassword = true;
	venue: Venue;
	gender = 'gender';
	email = 'email';
	password = 'password';
	rePassword = 'rePassword';
	name = 'name';
	surname = 'surname';
	agbChecked = 'agbChecked';
	phone = 'phone';

	salesforce = 'salesforce';
	loading = false;
	preferredCountries = ['de'];
	defaultCountry = 'de';
	signUpForm: FormGroup;

	phoneCountries: PhoneCountry[] = [
		{
			code: 'de',
			tel: '+49',
			icon: '../../../assets/tabilo/de_flag.svg',
		},
		{
			code: 'au',
			tel: '+43',
			icon: '../../../assets/tabilo/au_flag.svg',
		},
	];

	pageEnum = PageEnum;
	openedSalesForceBlock = false;

	private readonly subscription: Subscription;

	constructor(
		private translate: TranslateService,
		private repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private fb: FormBuilder,
		private navigationService: NavigationService,
		private modalCtrl: ModalController,
		private location: Location
	) {
		if (repository.customer && !repository.customer.isAnonymous) {
			this.navigationService.account();
		}
	}
	ngOnInit() {
		this.createFormSignUp();
		this.signUpForm.valueChanges.pipe(debounceTime(5000)).subscribe(v => {
			console.log(this.signUpForm.valid, v);
		});
	}

	createFormSignUp() {
		const formControlNames: any = {};
		formControlNames[this.gender] = [''];
		formControlNames[this.name] = ['', [Validators.required]];
		formControlNames[this.surname] = ['', [Validators.required]];
		formControlNames[this.phone] = ['', [this._customPhoneValidatorForLibrary]];
		formControlNames[this.email] = [
			'',
			[
				Validators.required,
				Validators.pattern(
					"^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
				),
			],
		];
		formControlNames[this.salesforce] = [false];
		formControlNames[this.agbChecked] = [false, [Validators.required, Validators.requiredTrue]];
		formControlNames[this.password] = [
			'',
			[Validators.required, Validators.minLength(8), Validators.pattern(/\d+/), Validators.pattern(/[A-Z]+/)],
		];

		formControlNames[this.rePassword] = ['', [Validators.required, this._isPasswordMatch()]];
		this.signUpForm = this.fb.group(formControlNames);
	}

	private _customPhoneValidatorForLibrary(control: AbstractControl) {
		if (!control?.value?.phone) {
			return null;
		}
		const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
		try {
			const phoneNumber = phoneUtil.parse(control?.value?.country + control?.value?.phone);
			if (phoneUtil.isValidNumber(phoneNumber) && phoneUtil.isPossibleNumber(phoneNumber)) {
				return null;
			} else {
				return { invalidPhone: true };
			}
		} catch (e) {
			return { invalidCountryCode: true };
		}
	}

	private _isPasswordMatch(): ValidatorFn {
		return () => {
			const pass = this.signUpForm?.get('password')?.value;
			const confirmPass = this.signUpForm?.get('rePassword')?.value;
			return pass === confirmPass ? null : { notSame: true };
		};
	}

	isValid(controlName?: string): boolean {
		if (controlName) {
			return this.signUpForm.get(controlName)?.hasError('required') && this.signUpForm.get(controlName)?.touched;
		}

		return this.signUpForm.invalid;
	}

	isValidByPattern(controlName?: string): boolean {
		if (controlName) {
			return this.signUpForm.get(controlName)?.errors && !this.signUpForm.get(controlName)?.hasError('required');
		}
		return this.signUpForm.invalid;
	}

	getControlValue(controlName?: string) {
		if (controlName) {
			return this.signUpForm?.get(controlName)?.value;
		}
		return '';
	}

	ngOnDestroy() {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}
	}

	async signUp() {
		// if (!(await this.submitFormCustomValidator())) {
		// 	return;
		// }
		if (!this.loading) {
			this.loading = true;
			try {
				await Api.signUpWithCredentials(
					{
						name: this.getControlValue('name') + ' ' + this.getControlValue('surname'),
						phone: this.getControlValue('phone')?.country + this.getControlValue('phone')?.phone ?? '',
						gender: this.getControlValue('gender') || this.genderValue.male,
						email: this.getControlValue('email'),
						salesforceEnabled: this.getControlValue(this.salesforce),
						preferredVenues: [],
					},
					this.getControlValue('password')
				);
				await this.navigationService.emailConfirmation();
			} catch (e) {
				this.loading = false;

				if (e.response.data.name === 'UserAlreadyExistsError') {
					this.isValidEmail = false;
				}
				this.snackbarCtrl.open(axiosErrorToMessage(this.translate, e));
			}
			this.loading = false;
		}
	}

	async signIn() {
		await this.navigationService.signIn();
	}

	setEmailValid() {
		this.isValidEmail = true;
	}

	setNameValid() {
		this.isValidName = true;
	}

	setPasswordValid() {
		this.isValidPassword = true;
		this.isValidRePassword = true;
	}

	async signUpWithGoogle() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Google');
				const result = await Api.signIn(AuthStrategy.GOOGLE);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async signUpWithFacebook() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Facebook');
				const result = await Api.signIn(AuthStrategy.FACEBOOK);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async signUpWithApple() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Apple');
				const result = await Api.signIn(AuthStrategy.APPLE);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	openTerms() {
		window.open('/tos', '_blank');
	}

	openPrivacy() {
		window.open('/privacy', '_blank');
	}

	goBack() {
		this.location.back();
	}
	async submitFormCustomValidator() {
		let controlStreetValue = this.getControlValue('street').trim();
		let controlHouseNumberValue = this.getControlValue('number').trim();
		let controlPostalCodeValue = this.getControlValue('postalCode').trim();
		let controlCityValue = this.getControlValue('city').trim();
		if (!controlCityValue && !controlHouseNumberValue && !controlPostalCodeValue && !controlCityValue) {
			return true;
		}
		try {
			const pred = controlStreetValue + ' ' + controlHouseNumberValue + ', ' + controlCityValue + ' ' + controlPostalCodeValue;
			const geocode = await MapsUtils.getPlace(pred);
			const address = MapsUtils.placeToAddress(geocode);
			const addressValidationResult = ValidationUtils.validateAddress(address, true);
			console.log(address, addressValidationResult);

			if (addressValidationResult) {
				// this.streetError = this.translate.instant(addressValidationResult);
				this.signUpForm.controls['street'].setErrors({ incorrect: true });
				return false;
			}

			this.signUpForm.patchValue({
				street: address.street,
				postalCode: address.postalCode,
				city: address.city,
				number: address.number,
			});
			return true;
		} catch (e) {
			this.signUpForm.controls['street'].setErrors({ street_not_found: true });
			this.snackbarCtrl.open(this.translate.instant('errors.street_not_found'), null, {
				duration: 2000,
			});
			return false;
		}
	}

	getFlag() {
		return this.phoneCountries.find(country => country.tel === this.signUpForm.get('phoneCode')?.value).icon;
	}

	getErrorsForm(controlName) {
		if (controlName) {
			return this.signUpForm.get(controlName).errors;
		}

		return null;
	}

	async openHtmlModal(event: Event, page: PageEnum) {
		event.stopPropagation();
		await HtmlVisualizerModalComponent.show(this.modalCtrl, this.repository._venue, page);
	}
	async openHtmlModalAGB(event: Event, page: PageEnum) {
		event.stopPropagation();
		await HtmlVisualizerModalComponent.show(this.modalCtrl, null, page);
	}
	openFullSalesForceBlock() {
		this.openedSalesForceBlock = !this.openedSalesForceBlock;
	}
	isValiInput(controlName?: string): boolean {
		console.log(this.signUpForm.get(controlName)?.errors);

		if (controlName) {
			const errors = this.signUpForm.get(controlName)?.errors
				? Object.keys(this.signUpForm.get(controlName)?.errors).length <= 0
				: true;
			return errors || !this.signUpForm.get(controlName).touched;
		}
		return this.signUpForm?.invalid;
	}
}
