import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-impressum-content',
	templateUrl: './impressum-content.component.html',
	styleUrls: ['./impressum-content.component.css'],
})
export class ImpressumContentComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
