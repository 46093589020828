import { Component, OnInit } from '@angular/core';
import { EmailConfirmationPage } from '../email-confirmation/email-confirmation.page';
import { SignInPage } from '../sign-in/sign-in.page';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HomePage } from '../home/home.page';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService, NAVIGATIONURLS } from 'src/app/services/navigation.service';

@Component({
	selector: 'app-email-action',
	templateUrl: './email-action.page.html',
	styleUrls: ['email-action.page.scss'],
})
export class EmailActionPage implements OnInit {
	static url = 'email-action';

	constructor(
		private translate: TranslateService,
		private route: ActivatedRoute,
		private router: Router,
		private auth: AngularFireAuth,
		private snackbarCtrl: MatSnackBar,
		private navigationService: NavigationService
	) {}

	ngOnInit() {
		this.route.queryParams.subscribe(async params => {
			if (!params.token || !params.mode) {
				this.snackbarCtrl.open(this.translate.instant('errors.invalid_link'), null, {
					duration: 2000,
				});
				await this.navigationService.home();
				return;
			}
			switch (params.mode) {
				case 'resetPassword':
					await this.navigationService.navigateToUrlWithParams(NAVIGATIONURLS.signIn(), params);
					break;
				case 'recoveryEmail':
					break;
				case 'verifyEmail':
					await this.navigationService.navigateToUrlWithParams(NAVIGATIONURLS.confirmEmail(), params);
					break;
			}
		});
	}
}
