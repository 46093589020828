import Article from './Article';
import ArticleOption from './ArticleOption';

export default class ArticleGroup {
	id: string;
	quantity: number;
	note: string;
	article: Article;
	groups: ArticleOption[] = [];
	isPromo = false;
	freeArticle = false;
	references: string;
	isRecommendedRecipe = false;
	totalPrice: string = '';
	isCoupon: boolean = false;
	controlEan: string = '';
}
