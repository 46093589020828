// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-row {
  justify-content: center;
  margin: 30px 10px 40px;
  padding-bottom: 40px;
}
ion-row.toggle-container > * {
  background: #f5f5f5;
  color: var(--ion-color-main);
  padding: 12px;
}
ion-row.toggle-container *.selected {
  background: var(--ion-color-second);
}
ion-row.toggle-container :first-child {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}
ion-row.toggle-container :last-child {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

span {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/components/toggle-button-group/toggle-button-group.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,sBAAA;EACA,oBAAA;AACF;AAEI;EACE,mBAAA;EACA,4BAAA;EACA,aAAA;AAAN;AAGI;EACE,mCAAA;AADN;AAII;EACE,8BAAA;EACA,2BAAA;AAFN;AAKI;EACE,+BAAA;EACA,4BAAA;AAHN;;AAQA;EACE,cAAA;AALF","sourcesContent":["ion-row {\n  justify-content: center;\n  margin: 30px 10px 40px;\n  padding-bottom: 40px;\n\n  &.toggle-container {\n    > * {\n      background: #f5f5f5;\n      color: var(--ion-color-main);\n      padding: 12px;\n    }\n\n    *.selected {\n      background: var(--ion-color-second);\n    }\n\n    :first-child {\n      border-bottom-left-radius: 8px;\n      border-top-left-radius: 8px;\n    }\n\n    :last-child {\n      border-bottom-right-radius: 8px;\n      border-top-right-radius: 8px;\n    }\n  }\n}\n\nspan {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
