// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-button {
  --background: transparent;
  width: 30px;
  --width: 30px;
  margin-left: 8px;
  --padding-start: 0px;
  --padding-end: 0px;
  height: 30px;
}
ion-button .button-native {
  padding: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/burger-btn/burger-btn.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;EACA,YAAA;AACF;AAAE;EACE,uBAAA;AAEJ","sourcesContent":["ion-button {\n  --background: transparent;\n  width: 30px;\n  --width: 30px;\n  margin-left: 8px;\n  --padding-start: 0px;\n  --padding-end: 0px;\n  height: 30px;\n  .button-native {\n    padding: 0px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
