// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  background: white;
  border-radius: 20px;
}

.logo {
  height: 80px;
  width: 80px;
}

.title {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #006E87;
  text-align: center;
  font-family: AppFontBold;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
}

.description {
  font-size: 16px;
  letter-spacing: 0.16px;
  margin-bottom: 30px;
  text-align: center;
}

.button_block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.button_block ion-button {
  width: 100%;
}
.button_block .top_button {
  --background: transparent;
  border-radius: 50px;
  border: 3px solid var(--ion-color-tertiary);
  color: var(--ion-color-tertiary);
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/sanifair-info-modal/sanifair-info-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,iBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EAEA,cAAA;EACA,kBAAA;EACA,wBAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;AAAF;;AAGA;EACE,eAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;AAAF;AAGE;EACE,WAAA;AADJ;AAIE;EACE,yBAAA;EACA,mBAAA;EACA,2CAAA;EACA,gCAAA;EACA,mBAAA;AAFJ","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 25px;\n  background: white;\n  border-radius: 20px;\n}\n\n.logo {\n  height: 80px;\n  width: 80px;\n}\n\n.title {\n  height: 80px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  color: #006E87;\n  text-align: center;\n  font-family: AppFontBold;\n  font-size: 28px;\n  font-weight: 700;\n  text-transform: uppercase;\n}\n\n.description {\n  font-size: 16px;\n  letter-spacing: 0.16px;\n  margin-bottom: 30px;\n  text-align: center;\n}\n\n.button_block {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n\n\n  ion-button {\n    width: 100%;\n  }\n\n  .top_button {\n    --background: transparent;\n    border-radius: 50px;\n    border: 3px solid var(--ion-color-tertiary);\n    color: var( --ion-color-tertiary);\n    margin-bottom: 10px;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
