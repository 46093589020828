// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-radio-group {
  width: 100%;
  border-radius: 0px;
  padding: 5px;
  display: block;
}

.price {
  font-weight: bold;
  font-size: 14px;
  color: black;
  margin-inline-start: 4px;
  font-family: AppFont, sans-serif !important;
  font-weight: bold;
}

ion-label {
  font-family: AppFont, sans-serif !important;
  font-size: 14px;
  text-transform: capitalize;
  height: 45px;
  display: flex !important;
  align-items: center;
}

ion-icon {
  color: var(--ion-color-primary);
  height: 20px;
  width: 20px;
}

app-tag {
  display: inline-block;
}

ion-item {
  display: flex;
  align-items: center;
  border: 0.5px solid #e5e5e5;
  background: #ffffff;
  border-radius: 15px !important;
}

.circle-wrap {
  width: 25px;
  height: 25px;
  position: absolute;
  left: -10px;
  top: 10px;
  transform: translateY(-50%);
  z-index: 99;
}

.wrapper {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}

.measurement {
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/article-option-group-single/article-option-group-single.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EAEA,YAAA;EACA,cAAA;AAAF;;AAGA;EACE,iBAAA;EACA,eAAA;EACA,YAAA;EACA,wBAAA;EACA,2CAAA;EACA,iBAAA;AAAF;;AAGA;EACE,2CAAA;EACA,eAAA;EACA,0BAAA;EAEA,YAAA;EACA,wBAAA;EACA,mBAAA;AADF;;AAIA;EACE,+BAAA;EACA,YAAA;EACA,WAAA;AADF;;AAIA;EACE,qBAAA;AADF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,mBAAA;EACA,8BAAA;AAAF;;AAGA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,2BAAA;EACA,WAAA;AAAF;;AAGA;EACE,WAAA;EACA,kBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF","sourcesContent":["ion-radio-group {\n  width: 100%;\n  border-radius: 0px;\n\n  padding: 5px;\n  display: block;\n}\n\n.price {\n  font-weight: bold;\n  font-size: 14px;\n  color: black;\n  margin-inline-start: 4px;\n  font-family: AppFont, sans-serif !important;\n  font-weight: bold;\n}\n\nion-label {\n  font-family: AppFont, sans-serif !important;\n  font-size: 14px;\n  text-transform: capitalize;\n\n  height: 45px;\n  display: flex !important;\n  align-items: center;\n}\n\nion-icon {\n  color: var(--ion-color-primary);\n  height: 20px;\n  width: 20px;\n}\n\napp-tag {\n  display: inline-block;\n}\nion-item {\n  display: flex;\n  align-items: center;\n  border: 0.5px solid #e5e5e5;\n  background: #ffffff;\n  border-radius: 15px !important;\n}\n\n.circle-wrap {\n  width: 25px;\n  height: 25px;\n  position: absolute;\n  left: -10px;\n  top: 10px;\n  transform: translateY(-50%);\n  z-index: 99;\n}\n\n.wrapper {\n  width: 100%;\n  position: relative;\n  margin-bottom: 10px;\n}\n\n.measurement {\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
