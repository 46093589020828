// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  background: var(--ion-color-primary);
  color: white;
  padding: 12px;
  font-weight: bold;
}

.separator {
  width: 100%;
  height: 1px;
  margin: 16px 0;
  background: white;
}

ion-icon {
  width: 32px;
  margin-right: 13px;
  padding: 4px;
  border-radius: 0;
}

.version {
  opacity: 0.3;
  font-size: x-small;
}

ion-row {
  align-items: center;
  padding: 2px;
  cursor: pointer;
}

.name {
  margin-top: 12px;
  font-size: large;
}

a {
  text-decoration: none;
  color: white;
}

ion-button {
  color: white;
  --background: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}

.lang-row {
  margin-left: 10px;
}
.lang-row ion-col {
  padding: 0px;
}

.icon-lang {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
}
.icon-lang ion-icon {
  margin: 0px !important;
  padding: 0px !important;
  zoom: 1.6;
}

.selected {
  opacity: 1 !important;
}

.lang-title {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/menu-popover/menu-popover.component.scss"],"names":[],"mappings":"AAEA;EACE,oCAAA;EACA,YAAA;EACA,aAAA;EACA,iBAAA;AADF;;AAIA;EACE,WAAA;EACA,WAAA;EACA,cAAA;EACA,iBAAA;AADF;;AAIA;EACE,WAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;AADF;;AAIA;EACE,YAAA;EACA,kBAAA;AADF;;AAIA;EACE,mBAAA;EACA,YAAA;EACA,eAAA;AADF;;AAIA;EACE,gBAAA;EACA,gBAAA;AADF;;AAIA;EACE,qBAAA;EACA,YAAA;AADF;;AAKA;EACE,YAAA;EACA,sCAAA;EACA,kCAAA;UAAA,0BAAA;AAFF;;AAIA;EACE,iBAAA;AADF;AAEE;EACE,YAAA;AAAJ;;AAGA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AAAF;AACE;EACE,sBAAA;EACA,uBAAA;EACA,SAAA;AACJ;;AAEA;EACE,qBAAA;AACF;;AACA;EACE,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,WAAA;AAEF","sourcesContent":["@import \"../../../theme/mixins\";\n\n.wrapper {\n  background: var(--ion-color-primary);\n  color: white;\n  padding: 12px;\n  font-weight: bold;\n}\n\n.separator {\n  width: 100%;\n  height: 1px;\n  margin: 16px 0;\n  background: white;\n}\n\nion-icon {\n  width: 32px;\n  margin-right: 13px;\n  padding: 4px;\n  border-radius: 0;\n}\n\n.version {\n  opacity: 0.3;\n  font-size: x-small;\n}\n\nion-row {\n  align-items: center;\n  padding: 2px;\n  cursor: pointer;\n}\n\n.name {\n  margin-top: 12px;\n  font-size: large;\n}\n\na {\n  text-decoration: none;\n  color: white;\n}\n\n\nion-button {\n  color: white;\n  --background: rgba(255, 255, 255, 0.3);\n  backdrop-filter: blur(4px);;\n}\n.lang-row{\n  margin-left: 10px;\n  ion-col {\n    padding: 0px;\n  }\n}\n.icon-lang{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  opacity: 0.6;\n  ion-icon {\n    margin: 0px !important;\n    padding: 0px !important;\n    zoom: 1.6;\n  }\n}\n.selected{\n  opacity: 1 !important;\n}\n.lang-title{\n  max-width: 90%;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  margin: 0px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
