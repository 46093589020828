import { AfterViewInit, Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import Article from 'src/smoothr-web-app-core/models/Article';
import Compound from 'src/smoothr-web-app-core/models/Compound';
import Localization from '../../../smoothr-web-app-core/models/Localization';
import { numberD } from 'src/smoothr-web-app-core/utils/utils';
import { NutritionValue } from 'src/smoothr-web-app-core/models/Nutrition';

@Component({
	selector: 'app-short-info-modal',
	templateUrl: './short-info-modal.component.html',
	styleUrls: ['short-info-modal.component.scss'],
})
export class ShortInfoModalComponent implements AfterViewInit {
	article: Article;
	compound: any;
	environment = environment;
	numberD = numberD;

	constructor(private modalCtrl: ModalController) {}
	ngAfterViewInit(): void {
		console.log(Object.values(this.compound.allergens).find(it => it === true));
		console.log(Object.values(this.compound.additives).find(it => it === true));
	}
	static async show(modalCtrl: ModalController, article: Article) {
		const modal = await modalCtrl.create({
			cssClass: 'allergens',
			component: ShortInfoModalComponent,
			componentProps: {
				article,
				compound: article?.compound,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}

	isAllergens(article: Article) {
		if (article?.compound?.allergens) return Object.values(article?.compound?.allergens)?.find(it => it === true);
		else return null;
	}

	isAddit(article: Article) {
		if (article?.compound?.additives) return Object.values(article?.compound?.additives)?.find(it => it === true);
		else return null;
	}
	isHaveAnyValueMoreZero(article: Article) {
		return Object.values(article.nutritions)
			.map(it => (numberD(it) ? numberD(it) : 0))
			.find(it => it > 0);
	}
	convertNumber(value: NutritionValue) {
		if (!value) {
			return '';
		}
		return numberD(value).toString().replace('.', ',');
	}
}
