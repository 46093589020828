import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import Order from 'src/smoothr-web-app-core/models/Order';
import SanifairVoucher from 'src/smoothr-web-app-core/models/SanifairVoucher';
import { numberD, numberToCurrency } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-sanifair-item',
	templateUrl: './sanifair-item.component.html',
	styleUrls: ['./sanifair-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SanifairItemComponent implements OnInit {
	@Input() item: ArticleGroup;
	@Input() order: Order;
	@Output() deleteSanifairVaucher = new EventEmitter<string>();
	numberToCurrency = numberToCurrency;

	constructor() {}

	ngOnInit(): void {}
	calculatePriceOfItem() {
		if (!this.item) {
			return '';
		}
		if (this.item?.totalPrice && numberD(this.item?.totalPrice) < 0) {
			return numberToCurrency(numberD(this.item?.totalPrice) / this.item?.quantity, this.order.currency);
		}
	}
	deleteSanifair() {}
}
