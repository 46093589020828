import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { environment } from '../../../environments/environment';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { sleep } from '../../../smoothr-web-app-core/utils/utils';

import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import { NavigationService, NAVIGATIONURLS } from 'src/app/services/navigation.service';

import { ModalController } from '@ionic/angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectTableModalComponent } from 'src/app/components/select-table-modal/select-table-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Api } from 'src/smoothr-web-app-core/api/api';
import { BehaviorSubject, Subscription } from 'rxjs';

import { AppComponent } from 'src/app/app.component';
import { MapsUtils } from 'src/maps/utils/maps';

import { InfoModalComponent } from 'src/app/components/info-modal/info-modal.component';
import { SelectLanguageModalComponent } from 'src/app/components/select-language-modal/select-language-modal.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';

enum Language {
	GreatBritain = 'en',
	German = 'de',
}

@Component({
	selector: 'app-home',
	templateUrl: './home.page.tabilo.html',
	styleUrls: ['./home.page.tabilo.scss'],
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';
	static scanQr = 'scan-qr/:venueId/:tableId';
	static failedUrl = 'payment/failed/:orderId';
	public listCountries = [
		{
			name: 'German',
			icon: '../../../assets/flag/flags-country/de-round.svg',
			value: 'de',
		},
		{
			name: 'English',
			icon: '../../../assets/flag/flags-country/gb-round.svg',
			value: 'en',
		},
		// {
		// 	name: 'Polish',
		// 	icon: '../../../assets/flag/flags-country/pl-round.svg',
		// 	value: 'pl',
		// },
		// {
		// 	name: 'Spanish',
		// 	icon: '../../../assets/flag/flags-country/es-round.svg',
		// 	value: 'es',
		// },
		// {
		// 	name: 'Turkish',
		// 	icon: '../../../assets/flag/flags-country/tr-round.svg',
		// 	value: 'tr',
		// },
		// {
		// 	name: 'France',
		// 	icon: '../../../assets/flag/flags-country/fr-round.svg',
		// 	value: 'fr',
		// },
		// {
		// 	name: 'Italy',
		// 	icon: '../../../assets/flag/flags-country/it-round.svg',
		// 	value: 'it',
		// },
	];
	test = '';
	environment = environment;
	languageEnum = Language;
	selectedLang: string;
	languageChangeSubscription: Subscription;
	preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY];
	active: any = {};
	loading$ = new BehaviorSubject(false);

	disableTest = false;
	countNumberOfUsers = 0;
	form: FormGroup;
	constructor(
		protected repository: RepositoryService,
		public translate: TranslateService,
		private navigationService: NavigationService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private fb: FormBuilder
	) {
		super(repository);
		this.selectedLang = this.translate.currentLang;
	}

	ngOnInit() {
		// super.ngOnInit();
		super.ngOnInit();
		this.route.paramMap.subscribe(params => {
			if (params && params.get('tableNumber') && params.get('venueId')) {
				this.loadVenueByQrCode(params.get('tableNumber'), params.get('venueId'));
			}
		});
		if (window.location.pathname.includes(NAVIGATIONURLS.paymentFail())) {
			this.snackbarCtrl.open(this.translate.instant('order.payment_fail'), null, {
				duration: 6000,
			});
		}
		this.languageChangeSubscription = this.translate.onLangChange.subscribe(v => {
			this.selectedLang = v.lang;
		});
	}

	ngOnDestroy(): void {
		if (this.languageChangeSubscription) {
			this.languageChangeSubscription.unsubscribe();
		}
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		if (repository) {
			repository.resetVenue();
			repository.address.emit(null);
			repository.onOrderChange(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
			repository.onOrderChange(null);
		}
		await router.navigateByUrl(HomePage.url);
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	ionViewDidEnter() {
		this.repository.onOrderChange(null);
		// this.repository.previousPreorder.emit(null);
		this.repository.verifiedOrder.emit(null);
		this.repository.address.emit(null);
		this.repository.payment.emit(null);
		this.repository.venue.emit(null);
		this.repository.onOrderChange(null);
		this.repository.resetVenue();
		this.getActiveCustomers();
	}
	async locateAndShowStores() {
		const allCookie = document.cookie.split(';').find(it => !!it);
		if (!allCookie) {
			await InfoModalComponent.show(this.modalCtrl, {
				info: this.translate.instant('not_accept_cookie'),

				closeButton: this.translate.instant('short_info.close'),
			});
			return;
		}
		this.loading$.next(true);

		try {
			const address = await MapsUtils.getUserGeocode();
			this.repository.address.emit(address);
		} catch (e) {
			console.error(e);
			this.repository.address.emit(null);
			this.loading$.next(false);
		}
		this.repository.address.emit(null);
		await this.navigationService.map();
		this.loading$.next(false);
	}

	async loadVenueByQrCode(tableNumber: string, venueId: string) {
		this.loading$.next(true);
		try {
			const venueData = await this.repository.getVenue(venueId);
			const table = (await Api.getTable(this.venue._id, tableNumber)).data;
			await sleep(100);
			if (venueData && table) {
				this.repository.venue.emit(venueData);
				this.repository.createOrder(this.venue, null, OrderType.PREORDER, PreorderType.INSIDE);
				this.order.tableNumber = table.number;
				this.order.table = table._id;
				this.repository.onOrderChange(this.order);
				sleep(200);
				await this.navigationService.menu();
				await SelectTableModalComponent.show(this.modalCtrl, this.order);
			}
			this.loading$.next(false);
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('home_page.scan_table_error'), null, {
				duration: 2000,
			});
			this.loading$.next(false);
		}
	}
	openPrivacy() {
		this.navigationService.privacy();
	}
	openImpressum() {
		this.navigationService.imprint();
	}
	openAgb() {
		this.navigationService.tos();
	}
	async selectLanguage(item: string) {
		this.translate.setDefaultLang(item);
		this.translate.use(item);
	}
	findFlag() {
		return this.listCountries.find(opt => opt.value === this.translate.currentLang).icon;
	}
	private async getActiveCustomers() {
		try {
			const response = await Api.getActiveUsers({
				customerGroups: ['tabilo'],
				updatedFrom: moment().subtract(6, 'month').format('YYYY-MM-DD'),
				updatedTo: moment().format('YYYY-MM-DD'),
			});
			if (response?.count) {
				this.countNumberOfUsers = Math.round(response.count / 6);
			}
		} catch (e) {
			this.countNumberOfUsers = 0;
			console.log(e);
		}
	}
}
