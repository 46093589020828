// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  padding: 0 12px;
  --background: var(--ion-color-secondary);
}
ion-toolbar ion-icon {
  height: 19px;
  width: 19px;
  fill: white;
}
ion-toolbar ion-title {
  font-family: AppFontBold, sans-serif !important;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/components/account-toolbar/account-toolbar.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACC,wCAAA;AACH;AAAE;EACE,YAAA;EACA,WAAA;EACA,WAAA;AAEJ;AAAE;EACE,+CAAA;EACA,WAAA;AAEJ","sourcesContent":["ion-toolbar {\n  padding: 0 12px;\n   --background: var(--ion-color-secondary);\n  ion-icon {\n    height: 19px;\n    width: 19px;\n    fill: white;\n  }\n  ion-title {\n    font-family: AppFontBold, sans-serif !important;\n    color: #fff;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
