// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-page-toolbar {
  background: url('menu_toolbar_background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  align-items: center;
  min-height: 75px;
  flex-wrap: nowrap;
  padding-right: 15px;
}

.title {
  font-size: var(--order-toolbar-title-font-size);
  font-family: var(--font-tusker);
  line-height: var(--order-toolbar-title-line-height);
  color: #fff;
  text-transform: uppercase;
}

ion-icon {
  height: 37px;
  width: 37px;
  padding: 10px 10px 10px 15px;
}

app-preorder-type-chip {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 991px) {
  app-burger-btn {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/order-page-toolbar/order-page-toolbar.component.scss","webpack://./src/theme/mixins.scss"],"names":[],"mappings":"AAEA;EACE,8CAAA;EACA,4BAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AADF;;AAIA;EACE,+CAAA;EACA,+BAAA;EACA,mDAAA;EACA,WAAA;EACA,yBAAA;AADF;;AAIA;EACE,YAAA;EACA,WAAA;EACA,4BAAA;AADF;;AAIA;EACE,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AADF;;AC7BE;EDiCF;IAEI,aAAA;EADF;AACF","sourcesContent":["@import \"../../../theme/mixins\";\n\n.order-page-toolbar {\n  background: url(../../../assets/tabilo/menu_toolbar_background.svg);\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: bottom;\n  align-items: center;\n  min-height: 75px;\n  flex-wrap: nowrap;\n  padding-right: 15px;\n}\n\n.title {\n  font-size: var(--order-toolbar-title-font-size);\n  font-family: var(--font-tusker);\n  line-height: var(--order-toolbar-title-line-height);\n  color: #fff;\n  text-transform: uppercase;\n}\n\nion-icon {\n  height: 37px;\n  width: 37px;\n  padding: 10px 10px 10px 15px;\n}\n\napp-preorder-type-chip {\n  margin-left: 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\napp-burger-btn {\n  @include desktop {\n    display: none;\n  }\n}\n","@mixin desktop {\n  @media (min-width: 991px) {\n    @content;\n  }\n}\n\n@mixin mobile {\n  @media (max-width: 992px) {\n    @content\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
