// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  font-family: AppFontBold, sans-serif;
  margin: 0;
  text-align: center;
  padding: 0 4px;
  color: var(--ion-color-secondary);
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

p {
  padding: 0 4px;
  text-align: center;
  font-family: AppFont, sans-serif;
  font-size: 16px;
  color: var(--ion-color-subtitle);
}

.block-img {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0px 10px 10px;
}
.block-img ion-img {
  width: 200px;
  margin-bottom: 20px;
}

.overflow-auto {
  background: white;
  color: var(--ion-color-subtitle);
  padding: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/select-table-modal/select-table-modal.component.scss"],"names":[],"mappings":"AAAA;EACC,oCAAA;EACA,SAAA;EACA,kBAAA;EACA,cAAA;EACA,iCAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,yBAAA;AACD;;AAEA;EACC,cAAA;EACA,kBAAA;EACA,gCAAA;EACA,eAAA;EACA,gCAAA;AACD;;AAEA;EACC,WAAA;EACA,aAAA;EACA,uBAAA;EACA,qBAAA;AACD;AACC;EACC,YAAA;EACA,mBAAA;AACF;;AAGA;EACC,iBAAA;EACA,gCAAA;EACA,aAAA;AAAD","sourcesContent":["h1 {\n\tfont-family: AppFontBold, sans-serif;\n\tmargin: 0;\n\ttext-align: center;\n\tpadding: 0 4px;\n\tcolor: var(--ion-color-secondary);\n\tfont-size: 28px;\n\tline-height: 33px;\n\tmargin-bottom: 15px;\n\ttext-transform: uppercase;\n}\n\np {\n\tpadding: 0 4px;\n\ttext-align: center;\n\tfont-family: AppFont, sans-serif;\n\tfont-size: 16px;\n\tcolor: var(--ion-color-subtitle);\n}\n\n.block-img {\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n\tmargin: 0px 10px 10px;\n\n\tion-img {\n\t\twidth: 200px;\n\t\tmargin-bottom: 20px;\n\t}\n}\n\n.overflow-auto {\n\tbackground: white;\n\tcolor:  var(--ion-color-subtitle);\n\tpadding: 25px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
