// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preorder-type-chip {
  background: var(--ion-color-tertiary);
  color: white;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  padding: 8px 10px;
  border-radius: 28px;
  font-family: var(--font-tusker), sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/app/components/preorder-type-chip/preorder-type-chip.component.scss"],"names":[],"mappings":"AAAA;EACE,qCAAA;EACA,YAAA;EAEA,eAAA;EACA,iBAAA;EAEA,yBAAA;EACA,iBAAA;EACA,mBAAA;EACA,2CAAA;AADF","sourcesContent":[".preorder-type-chip {\n  background: var(--ion-color-tertiary);\n  color: white;\n  // border: 1px solid var(--preorder-type-chip-border-color);\n  font-size: 12px;\n  line-height: 17px;\n\n  text-transform: uppercase;\n  padding: 8px 10px;\n  border-radius: 28px;\n  font-family: var(--font-tusker), sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
