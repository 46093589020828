import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-feature-icon',
	templateUrl: './feature-icon.component.html',
	styleUrls: ['./feature-icon.component.scss'],
})
export class FeatureIconComponent implements OnInit {
	@Input() featureType: string[];
	@Input() isArticleAlign = false;

	constructor(private translate: TranslateService) {}

	ngOnInit() {}
	changeUrl(item: string) {
		let end = '.png';
		if (item == 'vegan' || item === 'vegetarian') {
			end = '.svg';
		}
		return item + '_' + (this.translate.currentLang ?? 'de') + end;
	}
}
