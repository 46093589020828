import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ViewDidEnter } from '@ionic/angular';
import { NavigationService } from 'src/app/services/navigation.service';
import { RepositoryService } from 'src/smoothr-web-app-core/services/repository/repository.service';

@Component({
	selector: 'app-map',
	templateUrl: './map.page.html',
	styleUrls: ['map.page.scss'],
})
export class MapPage implements ViewDidEnter {
	constructor(
		private navService: NavigationService,
		private repository: RepositoryService
	) {
		this.repository.enterBySlug$.next('');
	}
	static url = 'map';

	static navigate(router: Router) {
		return router.navigateByUrl(MapPage.url);
	}
	ionViewDidEnter() {
		if (this.repository) {
			// this.repository.resetVenue();

			this.repository.onOrderChange(null);
			this.repository.verifiedOrder.emit(null);
			this.repository.payment.emit(null);
			this.repository.onOrderChange(null);
		}
	}
	goHome() {
		this.navService.home();
	}
}
