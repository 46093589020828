import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';
import { countries } from '../mock-countries';

@Component({
	selector: 'app-custom-modal-with-country-selection',
	templateUrl: './custom-modal-with-country-selection.component.html',
	styleUrls: ['./custom-modal-with-country-selection.component.scss'],
})
export class CustomModalWithCountrySelectionComponent implements OnInit {
	defaultValue: string;
	country: string;
	form: FormGroup;
	allCountries = countries;
	countriesArray = this.allCountries;
	constructor(
		private modalCtrl: ModalController,
		private fb: FormBuilder
	) {
		this.form = this.fb.group({
			value: '',
		});
	}
	static async show(modalCtrl: ModalController, defaultValue: string): Promise<any> {
		const modal = await modalCtrl.create({
			cssClass: 'short-info-modal auto-height dialog-primary-background',
			component: CustomModalWithCountrySelectionComponent,
			componentProps: {
				defaultValue,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await sleep(200);
		return result;
	}
	ngOnInit() {
		this.form.get('value').valueChanges.subscribe(value => {
			if (!value) {
				this.countriesArray = this.allCountries;
			}
			this.countriesArray = this.allCountries.filter(it => it.name.toLowerCase().includes(value.toLowerCase()));
		});
	}
	dismiss() {
		this.modalCtrl.dismiss();
	}
	dismissWithValue(country: any) {
		this.modalCtrl.dismiss(country);
	}
	compareCode(code: string) {
		return this.defaultValue.replace('+', '') === code;
	}
}
