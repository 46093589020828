import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-asked-register-modal',
	templateUrl: './asked-register-modal.component.html',
	styleUrls: ['./asked-register-modal.component.scss'],
})
export class AskedRegisterModalComponent implements OnInit {
	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController): Promise<boolean | undefined> {
		const modal = await modalCtrl.create({
			cssClass: 'short-info-modal auto-height dialog-primary-background-small',
			component: AskedRegisterModalComponent,

			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await sleep(200);
		return result;
	}
	ngOnInit() {}
	dismiss(value: boolean) {
		return this.modalCtrl.dismiss(value);
	}
}
