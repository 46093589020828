import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalController } from '@ionic/angular';
import { AppComponent } from '../../app.component';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import { sleep } from '../../../smoothr-web-app-core/utils/utils';
import Table from '../../../smoothr-web-app-core/models/Table';
import { Api } from '../../../smoothr-web-app-core/api/api';
import { TranslateService } from '@ngx-translate/core';
import { RepositoryService } from 'src/smoothr-web-app-core/services/repository/repository.service';
import Order from 'src/smoothr-web-app-core/models/Order';
import ValidateVoucherRequest from 'src/smoothr-web-app-core/models/ValidateVoucherRequest';
import { of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

enum Language {
	GreatBritain = 'en',
	German = 'de',
}
export enum SanifairScanModelDismiss {
	CLOSE = 'close',
	ORDER = 'order',
}
@Component({
	selector: 'app-scan-qr-sanifair',
	templateUrl: './scan-qr-sanifair.component.html',
	styleUrls: ['./scan-qr-sanifair.component.scss'],
})
export class ScanQrSanifairModal implements OnInit {
	scanning = true;
	loading = false;
	languageEnum = Language;
	firstOpen = false;
	venue: Venue;
	verifiedOrder: Order;
	eanCode = '';
	itfCode = '';
	constructor(
		private snackBarCtrl: MatSnackBar,
		private router: Router,
		private modalCtrl: ModalController,
		public translate: TranslateService,
		private repository: RepositoryService
	) {}

	/**
	 * returns table number
	 */
	static async show(
		modalCtrl: ModalController,
		venue: Venue,
		verifiedOrder: Order,
		firstOpen = false
	): Promise<{ verifiedOrder: Order; event: SanifairScanModelDismiss }> {
		const resultScan = await modalCtrl.create({
			component: ScanQrSanifairModal,
			cssClass: AppComponent.largeScreen ? 'item-modal large-modal' : 'item-modal',
			componentProps: {
				venue,
				verifiedOrder,
				firstOpen,
			},
		});
		await resultScan.present();
		const res = (await resultScan.onDidDismiss()).data;
		await sleep(100);
		return res;
	}
	ngOnInit() {
		// console.log(environment.webAppUrl + '/scan-qr/table/1')
		// this.scanSuccessHandler(environment.webAppUrl + '/scan-qr/table/1');
	}
	close() {
		this.modalCtrl.dismiss();
	}

	startScanning() {
		this.scanning = true;
	}

	stopScanning() {
		this.scanning = false;
	}

	async scanSuccessHandler($event: string) {
		console.log($event);

		try {
			await sleep(500);
			let sanifairCode = this.validateSanifairCode($event ?? '');
			if (sanifairCode) {
				this.getandLoadSanifairCode($event);
			}
			this.loading = false;
		} catch (e) {
			console.log('ERRROR', e);
			this.loading = false;
			this.snackBarCtrl.dismiss();
			this.snackBarCtrl.open('Bitte QR-Code überprüfen', null, {
				duration: 2000,
			});
		}
	}
	validateSanifairCode(value: string) {
		let sanitizedCurrentQrCodeData = '';
		if (value.includes('qr.sanifair.de')) {
			sanitizedCurrentQrCodeData = value.replace('Ö', ':').replace('-', '/').replace('ö', ';');
		} else {
			sanitizedCurrentQrCodeData = value;
		}
		return (
			sanitizedCurrentQrCodeData.includes('qr.sanifair.de') ||
			((sanitizedCurrentQrCodeData.length == 13 || sanitizedCurrentQrCodeData.length == 10) && !!Number(sanitizedCurrentQrCodeData))
		);
	}
	combineSanitizeCode(eanCode: string, iftCode: string, code: string) {
		console.log('COMBINE SANITEZE CODE', { eanCode, iftCode, code });
		if (eanCode && iftCode) {
			return eanCode + ':' + iftCode;
		} else if (code.includes('qr.sanifair.de')) {
			return code;
		} else {
			return '';
		}
	}
	async getandLoadSanifairCode(code: string) {
		if (code.length === 13) {
			this.eanCode = code;
		}
		if (code.length === 10) {
			this.itfCode = code;
		}
		if (code.length === 23) {
			this.eanCode = code.substring(0, 13);
			this.itfCode = code.substring(13, 23);
		}
		let now = new Date().getTime();

		if (this.repository.lastSuccessfullScan.getValue()) {
			const addFiveSeconds = 3 * 1000;
			if (this.repository.lastSuccessfullScan.getValue() + addFiveSeconds > now) {
				// this.snackBarCtrl.open(' Wait 3 seconds before next usages', null, {duration: 2000});
				return;
			}
		}
		const sanitiredCode = this.combineSanitizeCode(this.eanCode, this.itfCode, code);

		if (!sanitiredCode) {
			console.log(`Sanifair Coupon not complete. Current: ${code}, Ean: ${this.eanCode}, IFT: ${this.itfCode}`);
			return;
		}
		this.loading = true;

		this.repository.lastSuccessfullScan.next(new Date().getTime());
		this.eanCode = '';
		this.itfCode = '';

		try {
			await sleep(500);
			const response = (await this.reserveSanifair(this.venue, this.verifiedOrder, sanitiredCode)) as any;
			if (response) {
				this.repository.verifiedOrder.emit(response);
				this.modalCtrl.dismiss({
					verifiedOrder: response,
				});
			}
			console.log('FINAL RESULT ------', sanitiredCode);
			this.stopScanning();
		} catch (e: any) {
			this.loading = false;
			setTimeout(() => {
				this.startScanning();
			}, 3000);
		}
	}
	test() {
		console.log('TEST');
		return new Promise<any>(async (resolve, reject) => {
			return reject();
		});
	}
	async reserveSanifair(venue: Venue, order: Order, sanifairCode: string) {
		const splits = sanifairCode.split(':');
		let validVoucherRequest: ValidateVoucherRequest = {
			eanCode: sanifairCode,
			locationId: venue?.sanifairLocationId ?? '',
			deviceId: venue?.sanifairDeviceId ?? '',
		};
		if (splits.length === 2 && !sanifairCode.includes('qr.sanifair.de')) {
			validVoucherRequest = { ...validVoucherRequest, eanCode: splits[0], itfCode: splits[1] };
		}

		try {
			const newOrder = await Api.reserveSanifairVoucher(order._id, validVoucherRequest);
			// const newOrder = this.test();

			return newOrder;
		} catch (e) {
			setTimeout(() => {
				this.startScanning();
			}, 3000);
			console.log(e?.response?.data);
			if (e?.response?.data?.sanifairErrorCode) {
				this.snackBarCtrl.open(this.translate.instant('sanifair_errors.code_' + e?.response?.data?.sanifairErrorCode), null, {
					duration: 4000,
				});
			} else {
				this.snackBarCtrl.open(e?.response?.data?.message, null, {
					duration: 4000,
				});
			}

			return null;
		}
	}

	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
	back() {
		this.modalCtrl.dismiss({ event: SanifairScanModelDismiss.ORDER });
	}
	goToPayment() {
		this.modalCtrl.dismiss({ event: SanifairScanModelDismiss.ORDER });
	}
}
