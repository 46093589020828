import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import { MenuPage } from '../menu/menu.page';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { HomePage } from '../home/home.page';
import { MatSnackBar } from '@angular/material/snack-bar';
import Map = google.maps.Map;
import Marker = google.maps.Marker;
import LatLng = google.maps.LatLng;
import { MapsUtils } from '../../../smoothr-web-app-core/utils/maps-utils';
import { environment } from '../../../environments/environment';
import { getLatLng } from 'src/smoothr-web-app-core/utils/utils';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { NavigationService } from 'src/app/services/navigation.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
	selector: 'app-venue',
	templateUrl: './venue.page.html',
	styleUrls: ['venue.page.scss'],
})
export class VenuePage implements OnInit {
	static url = 'store';
	private static venues: Venue[] = [];
	private static preorderType: PreorderType = null;

	@ViewChild('mapContainer', { static: false })
	gmap: ElementRef;
	map: Map;

	loading = true;
	venues: Venue[];
	preorderType: PreorderType;
	showMap = false;
	clusterer: MarkerClusterer = null;
	mapOptions: google.maps.MapOptions = {
		zoom: 18,
		maxZoom: 18,
		disableDefaultUI: true,
		clickableIcons: false,
		styles: [
			{
				featureType: 'poi.business',
				stylers: [{ visibility: 'off' }],
			},
		],
	};
	selectedVenue: Venue = null;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private analytics: AngularFireAnalytics,
		private navigatorService: NavigationService
	) {}

	// static navigate(router: Router, venues: Venue[], preorderType: PreorderType) {
	// 	VenuePage.venues = venues;
	// 	VenuePage.preorderType = preorderType;
	// 	router.navigateByUrl(VenuePage.url);
	// }

	ionViewDidEnter() {
		this.loading = false;
		this.venues = VenuePage.venues;
		this.preorderType = VenuePage.preorderType;
		this.map = new Map(this.gmap.nativeElement, this.mapOptions);
		this.route.paramMap.subscribe(params => {
			if (params.get('venueId')) {
				this.loadVenue(params.get('venueId'));
			} else {
				this.setupMap(null, []);
			}
		});

		this.loading = false;
	}

	ngOnInit() {}
	async loadVenue(id: string) {
		try {
			const venueData = await this.repository.getVenue(id);
			if (venueData) {
				this.venues = [venueData];
				this.setupMap(venueData, [venueData]);
			}
		} catch (e) {
			this.setupMap(null, []);
		}
	}
	setupMap(selectedVenue: Venue, venues: Venue[]) {
		this.selectedVenue = selectedVenue;
		this.clusterer = MapsUtils.addVenuesToMap(this.clusterer, selectedVenue, venues, this.map, venue => this.setupMap(venue, venues));
	}

	async selectVenue(venue: Venue, preorderType: PreorderType, attempt: number = 0, prevError: any = null) {
		if (attempt > 5) {
			this.loading = false;
			console.error(prevError);
			this.snackbarCtrl.open(prevError, null, {
				duration: 2000,
			});
			return;
		}
		this.loading = true;
		try {
			await this.repository.getVenue(venue._id);
			// this.repository.createOrder(venue, null, preorderType);
			this.analytics.logEvent('venue_selected', {
				name: venue.name,
				readableId: venue.readableId,
			});
			await this.navigatorService.menu();
			this.loading = false;
		} catch (e) {
			await this.selectVenue(venue, preorderType, attempt + 1, e);
		}
	}
	goHome() {
		HomePage.navigate(this.router);
	}
	setShowMap(value: boolean) {
		this.showMap = value;
	}
}
