// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  width: 90%;
  margin: 15px auto;
}

h3 {
  font-family: AppFontBold, sans-serif;
  margin-top: 15px;
  font-size: 20px;
}

ion-item {
  border: 1px solid #E5E5E5;
  border-radius: 7px;
  font-size: 16px !important;
  color: black;
}
ion-item ion-label {
  margin-left: 10px;
}

ion-input {
  font-family: AppFont, sans-serif;
}

.button-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}
.button-block ion-button {
  --background: white;
  background: white;
  color: var(--ion-color-primary);
  border: 2px solid var(--ion-color-secondary);
  --border-radius: 28px;
  border-radius: 28px;
  width: 48%;
  font-size: 15px;
  text-transform: uppercase;
  font-family: AppFontBold, sans-serif;
  border: 2px solid var(--ion-color-primary);
}
.button-block .button:last-child {
  --background: var(--ion-color-primary);
  background: var(--ion-color-primary);
  border: none;
  --border-radius: 28px;
  color: white;
}

ion-icon {
  z-index: 10;
}

.euro {
  color: var(--ion-color-tertiary);
}

.red_error {
  font-size: 12px;
  color: red;
  transition: ease-in-out 0.5s;
}`, "",{"version":3,"sources":["webpack://./src/app/components/add-custom-tip/add-custom-tip.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,iBAAA;AACJ;;AACA;EACI,oCAAA;EACA,gBAAA;EACA,eAAA;AAEJ;;AAAA;EACI,yBAAA;EACA,kBAAA;EACA,0BAAA;EACA,YAAA;AAGJ;AAFI;EACI,iBAAA;AAIR;;AADA;EACI,gCAAA;AAIJ;;AAFA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;AAKJ;AAJI;EACI,mBAAA;EACA,iBAAA;EACA,+BAAA;EACA,4CAAA;EACA,qBAAA;EACA,mBAAA;EACA,UAAA;EAEA,eAAA;EACA,yBAAA;EACA,oCAAA;EACA,0CAAA;AAKR;AAHI;EACI,sCAAA;EACA,oCAAA;EACA,YAAA;EACA,qBAAA;EACA,YAAA;AAKR;;AAFA;EACI,WAAA;AAKJ;;AAHA;EACI,gCAAA;AAMJ;;AAJA;EACI,eAAA;EACA,UAAA;EACA,4BAAA;AAOJ","sourcesContent":[".wrapper{\n    width: 90%;\n    margin: 15px auto;\n}\nh3 {\n    font-family: AppFontBold, sans-serif;\n    margin-top: 15px;\n    font-size: 20px;\n}\nion-item {\n    border: 1px solid #E5E5E5;\n    border-radius: 7px;\n    font-size: 16px !important;\n    color: black;\n    ion-label {\n        margin-left: 10px;\n    }\n}\nion-input {\n    font-family: AppFont, sans-serif;\n}\n.button-block{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 5px;\n    ion-button {\n        --background: white;\n        background: white;\n        color: var(--ion-color-primary);\n        border: 2px solid var(--ion-color-secondary);\n        --border-radius: 28px;\n        border-radius: 28px;\n        width: 48%;\n\n        font-size: 15px;\n        text-transform: uppercase;\n        font-family: AppFontBold, sans-serif;\n        border: 2px solid var(--ion-color-primary);\n    }\n    .button:last-child{\n        --background: var(--ion-color-primary);\n        background: var(--ion-color-primary);\n        border: none;\n        --border-radius: 28px;\n        color: white;\n    }\n}\nion-icon{\n    z-index: 10;\n}\n.euro {\n    color: var(--ion-color-tertiary);\n}\n.red_error {\n    font-size: 12px;\n    color: red;\n    transition: ease-in-out 0.5s;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
