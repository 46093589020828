import { Component, OnInit, ViewChild } from '@angular/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import ArticleCategory from '../../../smoothr-web-app-core/models/ArticleCategory';
import { ToOrderButtonComponent } from '../../components/to-order-button/to-order-button.component';
import { AlertController, IonSearchbar, ModalController } from '@ionic/angular';
import { ModalInfoComponent } from '../../components/modal-info/modal-info.component';
import Article from '../../../smoothr-web-app-core/models/Article';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import { AppComponent } from '../../app.component';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { environment } from '../../../environments/environment';
import {
	axiosErrorToMessage,
	defaultsToArticleOption,
	defaultsToArticleOptionForOrder,
	getAvailability,
	sleep,
} from '../../../smoothr-web-app-core/utils/utils';
import { AnalyticsService } from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import { NavigationService, NAVIGATIONURLS } from 'src/app/services/navigation.service';
import { SelectTableModalComponent } from 'src/app/components/select-table-modal/select-table-modal.component';
import ArticleOption from 'src/smoothr-web-app-core/models/ArticleOption';
import { TimeUtils } from 'src/smoothr-web-app-core/utils/time-utils';

@Component({
	selector: 'app-testing',
	templateUrl: './testing.page.html',
	styleUrls: ['testing.page.scss'],
})
export class TestingPage extends RepositoryDirective implements OnInit {
	static url = 'testing/smoothr';

	@ViewChild(ToOrderButtonComponent, { static: false }) toOrderButton;
	@ViewChild('articleContent', { static: false }) articleList;
	@ViewChild(IonSearchbar, { static: false }) searchBar;
	@ViewChild('categoryList', { static: false }) categoryList;

	environment = environment;
	scrollEvents = true;
	loading = false;
	searchTerm = '';
	categories: ArticleCategory[] = [];
	moment = moment;
	PreorderType = PreorderType;
	searchBarOpen = false;
	isLargeScreen = AppComponent.largeScreen;
	get preorderType(): PreorderType {
		return this.order?.preorder?.type;
	}

	constructor(
		private translate: TranslateService,
		protected repository: RepositoryService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private analytics: AnalyticsService,
		private navigationService: NavigationService,
		private alertController: AlertController
	) {
		super(repository);
	}

	private _selectedCategoryIndex = 0;

	get selectedCategoryIndex(): number {
		return this._selectedCategoryIndex;
	}

	set selectedCategoryIndex(value) {
		if (this._selectedCategoryIndex === value) {
			return;
		}
		this._selectedCategoryIndex = value;
		this.scrollTo(value);
	}

	get selectedCategory(): ArticleCategory {
		return this.categories[this.selectedCategoryIndex];
	}

	get active(): boolean {
		return (
			this.order &&
			OrderUtils.articleGroupsTotalPrice(
				this.order.orderedArticles,
				this.order.type,
				this.order.preorder.type,
				this.order.terminalorder ? this.order.terminalorder.type : null
			) > 0
		);
	}

	get index(): number {
		return this.categories.findIndex(category => category._id === this.selectedCategory._id);
	}

	async ngOnInit() {
		await super.ngOnInit();
		switch (window.location.pathname) {
			case '/' + NAVIGATIONURLS.order():
				break;
			case '/' + NAVIGATIONURLS.paymentCancel():
				console.log(this.translate.instant('order.payment_cancel'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_cancel'), null, {
					duration: 2000,
				});
				break;
			case '/' + NAVIGATIONURLS.paymentFail():
				console.log(this.translate.instant('order.payment_fail'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_fail'), null, {
					duration: 5000,
				});
				break;
		}

		this.loading = true;

		console.log('here');
		const venueData = await this.repository.getVenue('6391ba0bf349b300119fc670');
		this.repository.venue.emit(venueData);
		this.repository.createOrder(venueData, null, OrderType.PREORDER, PreorderType.INSIDE);
		await sleep(500);
		this.reloadCategories();
		this.openSelectTableModal();
		this.checkVenue();
		this.loading = false;
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	async scrollTo(index: number) {
		if (this.categories.length - 1 < index || index < 0) {
			return;
		}
		if (this.searchTerm !== '') {
			await sleep(100);
			await this.scrollTo(index);
			return;
		}
		this.scrollEvents = false;
		if (this.selectedCategoryIndex !== index) {
			this.selectedCategoryIndex = index;
			await this.scrollArticleListTo(index);
			await sleep(100);

			await this.scrollCategoryListTo(index);
		}
		this.scrollEvents = true;
	}

	async onScroll(event) {
		const categoryElements = [...this.articleList.el.children];
		const categoryIndex = categoryElements
			.map(el => (this.isLargeScreen ? el.offsetTop - 300 : el.offsetTop - 4))
			.map((value, index, array) => {
				return (
					value <= event.detail.currentY &&
					((index < array.length - 1 && event.detail.currentY < array[index + 1]) || index === array.length - 1)
				);
			})
			.findIndex(value => value);
		let prevIndex = -1;

		if (this.selectedCategory) {
			prevIndex = this.categories.findIndex(category => category._id === this.selectedCategory._id);
		}
		if (prevIndex === categoryIndex) {
			return;
		}
		if (this.scrollEvents) {
			this.selectedCategoryIndex = categoryIndex;
			await this.scrollCategoryListTo(categoryIndex);
		}
	}

	private async scrollCategoryListTo(index: number) {
		if (
			index < 0 ||
			!this.categoryList ||
			!this.categoryList.nativeElement ||
			!this.categoryList.nativeElement.children[index] ||
			(!this.categoryList.nativeElement.scrollTo && !this.categoryList.nativeElement.scrollLeft)
		) {
			return;
		}
		this.categoryList.nativeElement.scrollTo(this.categoryList.nativeElement.children[index].offsetLeft - 25, 0);
		await sleep(100);
	}
	async openModal(item: Article) {
		if (this.loading) {
			return;
		}
		const articleGroup = new ArticleGroup();
		articleGroup.article = item;
		articleGroup.groups.push(...defaultsToArticleOption(item, [], item.defaults, this.order?.type, this.order.preorder.type));
		articleGroup.quantity = 1;
		const modal = await this.modalCtrl.create({
			cssClass: AppComponent.largeScreen ? 'item-modal large-modal' : 'item-modal',
			component: ModalInfoComponent,
			componentProps: {
				articleGroup,
			},
			mode: 'ios',
			backdropDismiss: true,
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		if (response.data && response.data.articleGroup) {
			OrderUtils.addToOrder(this.order, response.data.articleGroup, this.analytics);
			this.repository.onOrderChange(this.order);
			sleep(300);
			const copyGroup = response.data.articleGroup;
			this.addPfand(JSON.parse(JSON.stringify(copyGroup)));
		}
	}
	async createArticlePfand() {
		const articlePfand = this.venue.articleCategories
			.find(it => it.name?.de === '(CTL) Pfand ')
			.articles.find(it => it.name.de === 'Pfand');
		if (articlePfand) {
			const articleGroupPfand = new ArticleGroup();
			articleGroupPfand.article = articlePfand;
			articleGroupPfand.quantity = 1;
			articleGroupPfand.groups = defaultsToArticleOptionForOrder(articlePfand, [], articlePfand.defaults, this.order);
			const index = this.order.orderedArticles.findIndex(orderedArticle => {
				return (
					orderedArticle.article._id === articleGroupPfand.article._id &&
					articleGroupPfand.groups.length === orderedArticle.groups.length &&
					articleGroupPfand.groups
						.map(
							option =>
								orderedArticle.groups.findIndex(orderedOption => {
									return (
										option.article._id === orderedOption.article._id &&
										option.quantity === orderedOption.quantity &&
										option.dependency === orderedOption.dependency &&
										option.dependsOn === orderedOption.dependsOn &&
										option.dependencyNumber === orderedOption.dependencyNumber
									);
								}) >= 0
						)
						.reduce((previousValue, currentValue) => previousValue && currentValue, true)
				);
			});
			if (index >= 0) {
				this.order.orderedArticles[index].quantity++;
			} else {
				this.order.orderedArticles.push(articleGroupPfand);
			}
			this.repository.onOrderChange(this.order);
		}
	}
	onSearchTermChanged(event) {
		this.searchTerm = event.detail.value;
		this.reloadCategories();
	}

	reloadCategories() {
		console.log('reloadCategories');
		if (!this.venue) {
			this.categories = [];
			return;
		}
		console.log('reloadCategories 2');

		const lowerSearchTerm = this.searchTerm && this.searchTerm !== '' ? this.searchTerm.toLocaleLowerCase() : null;
		this.categories = this.venue.articleCategories
			.filter(category => !category.hidden)
			.map(category => {
				const cat: ArticleCategory = JSON.parse(JSON.stringify(category));
				cat.articles = cat.articles.filter(article => {
					const available = getAvailability(article, OrderType.PREORDER, this.preorderType);
					if (lowerSearchTerm) {
						const keys = [
							article.name.de.toLocaleLowerCase(),
							article.name.en.toLocaleLowerCase(),
							cat.name.de.toLocaleLowerCase(),
							cat.name.en.toLocaleLowerCase(),
						];
						return available && keys.map(key => key.indexOf(lowerSearchTerm)).find(result => result >= 0) !== undefined;
					}
					return available;
				});
				return cat;
			})
			.filter(category => category.articles.length > 0);
		return;
	}

	onVenue() {
		super.onVenue();
		if (!this.venue) {
			this.repository
				.getVenue(this.order && this.order.venue)
				.then(venue => {
					this.repository.venue.emit(venue);
				})
				.catch(_ => {
					this.reloadCategories();
					this.selectedCategoryIndex = 0;
				});
			return;
		}
		this.reloadCategories();
		this.selectedCategoryIndex = 0;
	}

	onVenueError(error) {
		super.onVenueError(error);
		this.snackbarCtrl.open(axiosErrorToMessage(this.translate, error));
		this.loading = false;
		this.snackbarCtrl.open(
			this.translate.instant('menu_page.venue_timed_out'),
			this.translate.instant('menu_page.venue_timed_out_action')
		);
	}

	private async scrollArticleListTo(index: number) {
		if (!this.articleList) {
			return;
		}
		await this.articleList.scrollToPoint(0, this.articleList.el.children[index].offsetTop + 70, 300);
	}
	async goHome() {
		await this.repository.cleanRepository();
		await this.navigationService.home();
	}

	changeSearchbar() {
		this.searchBarOpen = !this.searchBarOpen;
	}

	clearInput() {
		this.searchTerm = '';
		this.searchBarOpen = !this.searchBarOpen;
		this.reloadCategories();
	}

	async openSelectTableModal(openByToolbar?: boolean) {
		if (this.venue && this.order.preorder.type === PreorderType.INSIDE) {
			if (this.order.tableNumber === 'preorder_table' || openByToolbar) {
				// const selectTable = await SelectTableModalComponent.show(this.modalCtrl, this.venue, this.order);
				// if (selectTable) {
				// 	this.order.tableNumber = selectTable.table;
				// 	this.order.table = selectTable.tableId;
				// 	this.repository.order.emit(this.order);
				// }
			}
		}
	}
	async addPfand(articleGroup: ArticleGroup) {
		if (articleGroup.article?.tags?.find(it => it?.identifier == 'deposit')) {
			await this.createArticlePfand();
			return;
		}
		if (articleGroup.groups?.length > 0) {
			articleGroup.groups = articleGroup.groups.filter((it: ArticleOption) => {
				return it.article.tags.length > 0 && it.article.tags.filter(tag => tag.identifier == 'deposit').length > 0;
			});
			const quantity = articleGroup.groups.map(it => it.quantity).reduce((a, b) => a + b);
			[...Array(quantity)].forEach((_, i) => this.createArticlePfand());
		}
	}
	async checkVenue() {
		if (this.venue) {
			const isOpenResturant = TimeUtils.doesHoursMatchNow(this.venue.openingHours);
			if (!isOpenResturant) {
				this.snackbarCtrl.open(this.translate.instant('home_page.venue_closed'), null, {
					duration: 5000,
				});
				this.navigationService.home();
			}
		}
	}
}
