// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-row {
  cursor: pointer;
}
ion-row > ion-col {
  border-radius: 5px;
  background: white;
  box-shadow: 0px 2px 45px rgba(255, 255, 255, 0.15);
  color: var(--ion-color-black);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px 5px;
}
ion-row > ion-col > ion-icon {
  height: 60px;
  width: 100%;
  color: var(--ion-color-black);
}
ion-row > ion-col > p {
  text-align: center;
  font-weight: bold;
  font-size: 0.9rem;
  text-transform: capitalize;
  font-family: AppFont, sans-serif;
  margin-bottom: 0px;
}
ion-row > ion-col.inactive > ion-icon {
  opacity: 0.3;
}
ion-row > ion-col.inactive > p {
  opacity: 0.3;
}`, "",{"version":3,"sources":["webpack://./src/app/components/home-preorder-type-button/home-preorder-type-button.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EACE,kBAAA;EACA,iBAAA;EACA,kDAAA;EACA,6BAAA;EACA,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;EACA,iBAAA;AACJ;AACI;EACE,YAAA;EACA,WAAA;EACA,6BAAA;AACN;AACI;EACE,kBAAA;EAEA,iBAAA;EACA,iBAAA;EACA,0BAAA;EACA,gCAAA;EACA,kBAAA;AAAN;AAKM;EACE,YAAA;AAHR;AAMM;EACE,YAAA;AAJR","sourcesContent":["ion-row {\n  cursor: pointer;\n\n  > ion-col {\n    border-radius: 5px;\n    background: white;\n    box-shadow: 0px 2px 45px rgba(255, 255, 255, 0.15);\n    color: var(--ion-color-black);\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px 5px;\n\n    > ion-icon {\n      height: 60px;\n      width: 100%;\n      color: var(--ion-color-black);\n    }\n    > p {\n      text-align: center;\n      // margin: 5px 0 0 0;\n      font-weight: bold;\n      font-size: 0.9rem;\n      text-transform: capitalize;\n      font-family: AppFont, sans-serif;\n      margin-bottom: 0px;\n      // padding: 0px 5px 10px 5px;\n    }\n\n    &.inactive {\n      > ion-icon {\n        opacity: 0.3;\n      }\n\n      > p {\n        opacity: 0.3;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
