export enum OrderType {
	STANDARD = 'standard',
	PREORDER = 'preorder',
	CUSTOM = 'custom',
	DBORDER = 'dborder',
	TERMINAL = 'terminal',
	LUNCHROOM = 'lunchroom',
	VIRTUAL = 'virtual',
	CATERING = 'catering',
}
