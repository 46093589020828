import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';

export const tabiloCustomerGroup = ['tabilo_sim', 'tabilo'];

export const customerGroup = tabiloCustomerGroup;

const supportEmail = {
	tabilo: 'tabilo@smoothr.de',
};
const firebaseConfig = {
	tabilo: {
		apiKey: 'AIzaSyCAxZEOD_R7Y6oEPOXiGux86jIWL75rN-k',
		authDomain: 'gastronomie-online-order-6e7e4.firebaseapp.com',
		projectId: 'gastronomie-online-order-6e7e4',
		storageBucket: 'gastronomie-online-order-6e7e4.appspot.com',
		messagingSenderId: '496947315731',
		appId: '1:496947315731:web:1ef711e7527de9a7d0ebe6',
		measurementId: 'G-475N7NT305',
	},
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyAOeiLvvjEYotAThoJIaqV3wdQxiPydMfE',
	SUPPORT_EMAIL: supportEmail[customerGroup[1]],
	firebaseConfig: firebaseConfig[customerGroup[1]],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment,
	countryList: ['de'],
	gaId: '',
	tableNumberSimphony: '110',
	tableIDSimohony: '64a2c8fe5794fe001263fa58',
	hasRecommendations: true,
};
// import 'zone.js/dist/zone-error';
