import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HomePage } from '../home/home.page';
import { MenuPage } from '../menu/menu.page';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { environment } from '../../../environments/environment';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
	selector: 'app-faq',
	templateUrl: './faq.page.html',
	styleUrls: ['faq.page.scss'],
})
export class FaqPage {
	static url = 'faq';
	environment = environment;

	listItems = [
		{
			title: 'Womit kann ich bezahlen?',
			description: 'Du kannst mit den üblichen Kreditkarten Mastercard und Visa Card bezahlen. Zudem akzeptieren wir PayPal.',
		},
		{
			title: 'Wie geht es nach meiner Bestellung weiter?',
			description:
				'Nach deiner Online-Bestellung bereiten wir die Gerichte frisch für dich zu. Du kannst bei Ankunft in der Raststätte bzw. beim Warten in der Raststätte auf dem Abholmonitor anhand deiner Bestellnummer verfolgen, wann deine Bestellung fertig ist. Sollte der Andrang etwas größer sein, kannst du es dir zwischendurch auf unseren Sitzplätzen gemütlich machen. Achte nur darauf, wann deine Bestellung fertig wird.',
		},
		{
			title: 'Kann ich auch mit Bargeld bezahlen?',
			description:
				'Derzeit kannst du in unserem Webshop noch nicht mit Bargeld bezahlen. Wenn du mit Bargeld zahlen möchtest, bestell doch einfach direkt im Restaurant.',
		},
		{
			title: 'Zu welchen Zeiten kann ich meine Online-Bestellungen aufgeben?',
			description:
				'Unsere Bestellzeiten können je nach Raststätten-Standort variieren. Die individuellen Öffnungszeiten findest du im Webshop in der Standortkarte unter „i“ wie Informationen.',
		},
		{
			title: 'Ich bin Vegetarier/-in. Habt ihr passende Produkte für mich?',
			description:
				'Ja, klar. Auch für dich haben wir superleckere Produkte. Schiebe den Veggie-Regler oberhalb der Produktübersicht nach rechts. Dann siehst du übersichtlich alle vegetarischen Gerichte.',
		},
		{
			title: 'Ich muss mich glutenfrei ernähren. Habt ihr Produkte, die ich essen kann?',
			description:
				'Wir nehmen das Thema glutenfrei und auch andere allergene Inhaltsstoffe sehr ernst. Leider können wir aufgrund der Herstellungsverfahren nicht sicher sein, dass unsere Produkte glutenfrei sind. Frag in diesem Fall lieber bei unserem Personal in der Raststätte nach. Hier kann dir sicherlich weitergeholfen werden.',
		},
		{
			title: 'Ich bin Allergiker. Kann ich bei euch bestellen?',
			description:
				'Wir haben Allergene und Zusatzstoffe ausgezeichnet. Klick einfach auf das „i“ direkt an den Produkten. Hier kannst du dich jederzeit informieren.',
		},
		{
			title: 'Wie lange dauert es, bis meine Bestellung fertig ist?',
			description:
				'Wir bereiten die Gerichte bei deiner Bestellung frisch zu. Aber keine Sorge. Wir sind gut organisiert und alles geht schnell. Leider kann es mal vorkommen, dass der Andrang etwas größer ist. Bitte hab Verständnis, wenn es dann mal etwas länger dauert.',
		},
		{
			title: 'Kann ich bei der Bestellung eine Uhrzeit zur Abholung festlegen?',
			description:
				'Leider geht das bei unseren Online-Bestellungen nicht. Solltest du Gast in unserem Hotel sein, kannst du gerne unser Personal ansprechen, wenn du zu einer bestimmten Uhrzeit dein Essen abholen möchtest.',
		},
		{
			title: 'Kann ich online einen Tisch bei euch reservieren?',
			description:
				'Deine Reservierung für einen Tisch nehmen wir gerne telefonisch entgegen. Die Rufnummer der Raststätte findest du im Webshop in der Standortkarte unter „i“ wie Informationen.',
		},
		{
			title: 'Kann ich meinen Hund mit in die Raststätte bringen?',
			description:
				'Wir lieben Hunde. Gerne kannst du deinen Hund angeleint in die Raststätte mitbringen. Wir stellen deinem Vierbeiner auch gerne einen frischen Napf mit Wasser zur Verfügung. Das ist bei uns eine Serviceselbstverständlichkeit.',
		},
		{
			title: 'Ich habe keine Bestellbestätigung bekommen. Was ist los?',
			description:
				'Trotz aller technischer Vorkehrungen kann so etwas in Ausnahmefällen vorkommen. Sprich gerne unser Personal im Restaurant an. Hier wird dir weitergeholfen.',
		},
		{
			title: 'Das Restaurant war geschlossen, als ich meine Bestellung abholen wollte. Was soll ich tun?',
			description:
				'Wenn das Restaurant geschlossen ist, kannst du nicht online vorab bestellen. Falls du aber schon bestellt und bezahlt hast und das Restaurant bei Ankunft schon geschlossen ist, wende dich bitte per E-Mail oder Telefon am nächsten Tag an die Raststätte, damit dir das Geld zurückerstattet werden kann.',
		},
		{
			title: 'Liefert ihr?',
			description:
				'Die Online-Bestellung in unserem Webshop enthält keinen Lieferservice. Solltest du eine Lieferung wünschen, dann kontaktiere vorab die Raststätte telefonisch. Vielleicht findet sich eine Möglichkeit, dass dir deine Bestellung geliefert wird. Bitte habe Verständnis, dass hierfür in der Regel ein Mindestbestellwert erreicht werden muss.',
		},
		{
			title: 'Umfasst das Online-Angebot im Webshop das gesamte Angebot der Raststätte?',
			description:
				'Das Online-Angebot umfasst einen Großteil unseres gastronomischen Angebotes. In der Raststätte haben wir aber natürlich eine Menge mehr zu bieten. Schau dich gerne bei uns um.',
		},
		{
			title: 'Warum kann ich keine alkoholischen Getränke im Webshop bestellen?',
			description:
				'Da wir bei Online-Bestellungen keine Altersprüfung durchführen, kannst du dein alkoholisches Getränk bei uns ausschließlich vor Ort kaufen.',
		},
		{
			title: 'Gibt es einen Mindestbestellwert?',
			description:
				'Für Online-Bestellungen über unseren Webshop gibt es keinen Mindestbestellwert. Sollten Sonderregelungen, wie z. B. ein Lieferservice, vereinbart sein, kann ein Mindestbestellwert möglich sein oder eine Liefergebühr erhoben werden.',
		},
		{
			title: 'Ist die Online-Bestellung teurer als eine direkte Bestellung in der Raststätte?',
			description:
				'Nein, egal ob du dein Essen online bestellst oder in der Raststätte kaufst: Du findest die gleichen Preise. Hier gibt es keinen Unterschied.',
		},
		{
			title: 'Kann ich den SANIFAIR Wert-Bon bei einer Online-Bestellung im Webshop einlösen?',
			description:
				'Ja, entsprechend der aktuell geltenden SANIFAIR Einlöse-Bedingungen (AGB) kannst du SANIFAIR Wert-Bons auch bei der Online-Bestellung einlösen.',
		},
		{
			title: 'Was mache ich mit dem SANIFAIR Wert-Bon, nachdem ich ihn auf eine Online-Bestellung im Webshop eingelöst habe?',
			description:
				'Durch die Einlösung für eine Online-Bestellung ist dein SANIFAIR Wert-Bon wertlos geworden und kann von dir entsorgt werden.',
		},
		{
			title: 'Warum kann ich keinen ADAC-Rabatt für eine Online-Bestellung erhalten?',
			description:
				'Deine ADAC-Mitglieder-Karte enthält technisch leider keine Möglichkeit, um sie für eine Online-Bestellung zu nutzen. Möchtest du den ADAC-Rabatt geltend machen, bestell gerne direkt in unserer Raststätte.',
		},
		{
			title: 'Wohin kann ich Feedback geben?',
			description:
				'Wir geben uns jeden Tag Mühe, dass du zufrieden mit unseren Produkten und unseren Services bist. Sollte dennoch mal was schieflaufen, lass uns das gerne wissen. Nur so haben wir die Chance, es beim nächsten Mal besser zu machen. Du kannst dein Feedback jederzeit am Standort abgeben. Sprich uns hierzu gerne an oder nutze den QR-Code, den du für dein Feedback an allen Standorten findest. Solltest du nicht mehr vor Ort am Standort sein, kannst du uns dein Feedback auch per Mail über <a class="link" href="mailto:kundenservice@tank.rast.de" >kundenservice@tank.rast.de</a> geben. Übrigens, über positives Feedback freuen wir uns auch jederzeit.',
		},
		{
			title: 'Warum sind die Kontaktinformationen Pflichtfelder?',
			description:
				'Einige Informationen wie dein Vorname und deine Mailadresse sind Pflichtfelder. Diese brauchen wir, um dir eine Bestellbestätigung, deine Bestellnummer und deine Rechnung zu senden. Für Rückfragen zu deiner Bestellung ist deine Rufnummer hilfreich, aber kein Muss.',
		},
		{
			title: 'Werden meine Daten gespeichert?',
			description:
				'Entsprechend unserer Datenschutzerklärung speichern wir manche deiner Daten. Welche Daten das sind und für welchen Zweck wir sie speichern, kannst du in unserer Datenschutzerklärung nachlesen. Diese findest du hier in unserem Webshop.',
		},
		{
			title: 'Gibt es eine Übersichtskarte aller Standorte, bei denen ich bestellen kann?',
			description:
				'In der Karte auf der Startseite findest du die Raststätten-Standorte in deiner Nähe. Solltest du darüber deinen Standort auswählen, schau bitte genau hin, welchen Standort du auswählst. Unsere Rastanlagen liegen häufig gegenüber und haben unterschiedliche Fahrtrichtungen auf der Autobahn.',
		},
		{
			title: 'Kann ich bei euch arbeiten?',
			description:
				'Wir sind kontinuierlich auf der Suche nach neuen Talenten für unsere Servicebetriebe. Informiere dich über freie Stellen in unserem Jobportal <a class="link" href="mailto:jobs-tank-rast.de" >jobs-tank-rast.de</a> oder sprich uns bei deinem nächsten Besuch auf der Raststätte einfach an. Wir freuen uns auf dich!',
		},
	];

	constructor(
		private repository: RepositoryService,
		private navigationService: NavigationService
	) {}

	async goBack() {
		if (this.repository._order === null) {
			await this.navigationService.home();
		} else {
			await this.navigationService.menu();
		}
	}

	async toPrivacy() {
		await this.navigationService.privacy();
	}
	async toImprint() {
		await this.navigationService.imprint();
	}
}
