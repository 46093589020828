// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  color: var(--ion-color-subtitle);
  text-align: left !important;
}

p {
  line-height: 22px;
}

h1 {
  margin: 0;
  padding: 0 4px;
}

li {
  text-align: left;
}

ion-icon {
  width: 48px;
  height: 48px;
}

ion-row {
  padding: 8px;
  background: white;
  text-align: center;
}
ion-row ion-col {
  padding: 12px;
}

ion-button {
  margin-top: 15px;
  color: white;
  background: var(--ion-color-tertiary);
  font-family: AppFont, san-serif;
  border-radius: 28px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/short-info-html/short-info-html.component.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,2BAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,SAAA;EACA,cAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AACA;EACE,WAAA;EACA,YAAA;AAEF;;AACA;EACE,YAAA;EACA,iBAAA;EACA,kBAAA;AAEF;AAAE;EACE,aAAA;AAEJ;;AAEA;EACE,gBAAA;EACA,YAAA;EACA,qCAAA;EACA,+BAAA;EACA,mBAAA;EACA,WAAA;AACF","sourcesContent":["* {\n  color: var(--ion-color-subtitle);\n  text-align: left !important;\n}\n\np {\n  line-height: 22px;\n}\n\nh1 {\n  margin: 0;\n  padding: 0 4px;\n}\n\nli {\n  text-align: left;\n}\nion-icon {\n  width: 48px;\n  height: 48px;\n}\n\nion-row {\n  padding: 8px;\n  background: white;\n  text-align: center;\n\n  ion-col {\n    padding: 12px\n  }\n}\n\nion-button {\n  margin-top: 15px;\n  color: white;\n  background: var(--ion-color-tertiary);\n  font-family: AppFont, san-serif;\n  border-radius: 28px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
