import { Component, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { ActionSheetController, ModalController, PickerController, PopoverController } from '@ionic/angular';
import { HomePage } from 'src/app/pages/home/home.page';
import { RepositoryService } from 'src/smoothr-web-app-core/services/repository/repository.service';
import { NavigationService } from '../services/navigation.service';

@Injectable()
export class CheckOrderGuard implements CanActivate {
	constructor(
		public repository: RepositoryService,
		private navService: NavigationService
	) {}
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (!this.repository._order) {
			this.navService.home();
		} else {
			return true;
		}
	}
}
