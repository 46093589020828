// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_container {
  padding: 20px 15px 10px;
  overflow: auto;
}

ion-icon {
  width: 19px;
  z-index: 10;
}

.cross_row {
  margin-bottom: 20px;
  padding-right: 10px;
}

h3,
h1,
h2,
h4 {
  font-family: var(--font-tusker), sans-serif !important;
  color: var(--ion-color-primary) !important;
}

p,
li,
ol {
  font-family: AppFont, san-serif !important;
  color: var(--ion-color-subtitle) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/html-visualizer-modal/html-visualizer-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,cAAA;AACF;;AACA;EACE,WAAA;EACA,WAAA;AAEF;;AAAA;EACE,mBAAA;EACA,mBAAA;AAGF;;AAAA;;;;EAIE,sDAAA;EACA,0CAAA;AAGF;;AADA;;;EAGE,0CAAA;EACA,2CAAA;AAIF","sourcesContent":[".modal_container {\n  padding: 20px 15px 10px;\n  overflow: auto;\n}\nion-icon {\n  width: 19px;\n  z-index: 10;\n}\n.cross_row {\n  margin-bottom: 20px;\n  padding-right: 10px;\n}\n\nh3,\nh1,\nh2,\nh4 {\n  font-family: var(--font-tusker), sans-serif !important;\n  color: var(--ion-color-primary) !important;\n}\np,\nli,\nol {\n  font-family: AppFont, san-serif !important;\n  color: var(--ion-color-subtitle) !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
