// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  width: 100%;
  padding: 18px;
  font-size: 18px;
  border-radius: 4px;
  border: 2px solid var(--ion-color-subway-yellow);
}
.button.filled {
  background: var(--ion-color-subway-yellow);
  color: var(--ion-color-subway-green-dark);
  transition: 0.3s linear;
}
.button.filled:hover {
  transition: 0.3s linear;
  background: var(--ion-color-subway-yellow-dark);
}
.button.flat {
  background: #ffffff;
  color: var(--ion-color-subway-yellow);
  transition: 0.3s linear;
}
.button.flat:hover {
  transition: 0.3s linear;
  background: darkgrey;
}

ion-icon {
  position: absolute;
  top: 16px;
  right: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/recommendation-modal/recommendation-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,eAAA;EACA,kBAAA;EACA,gDAAA;AACF;AACE;EACE,0CAAA;EACA,yCAAA;EACA,uBAAA;AACJ;AACI;EACE,uBAAA;EACA,+CAAA;AACN;AAGE;EACE,mBAAA;EACA,qCAAA;EACA,uBAAA;AADJ;AAGI;EACE,uBAAA;EACA,oBAAA;AADN;;AAMA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;AAHF","sourcesContent":[".button {\n  width: 100%;\n  padding: 18px;\n  font-size: 18px;\n  border-radius: 4px;\n  border: 2px solid var(--ion-color-subway-yellow);\n\n  &.filled {\n    background: var(--ion-color-subway-yellow);\n    color: var(--ion-color-subway-green-dark);\n    transition: 0.3s linear;\n\n    &:hover {\n      transition: 0.3s linear;\n      background: var(--ion-color-subway-yellow-dark)\n    }\n  }\n\n  &.flat {\n    background: #ffffff;\n    color: var(--ion-color-subway-yellow);\n    transition: 0.3s linear;\n\n    &:hover {\n      transition: 0.3s linear;\n      background: darkgrey;\n    }\n  }\n}\n\nion-icon {\n  position: absolute;\n  top: 16px;\n  right: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
