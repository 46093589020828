import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { Api } from 'src/smoothr-web-app-core/api/api';
import validator from 'validator';

@Component({
	selector: 'app-app-restore-password-modal',
	templateUrl: './app-restore-password-modal.component.html',
	styleUrls: ['./app-restore-password-modal.component.scss'],
})
export class AppRestorePasswordModalComponent {
	email: string = '';
	isValidEmail = true;
	resetToken = '';

	passwordForm: FormGroup;
	password = '';
	rePassword = '';

	constructor(
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private translate: TranslateService,
		private fb: FormBuilder,
		private navigationService: NavigationService
	) {}

	ngOnInit() {
		if (this.resetToken) {
			this.createPasswordForm();
		}
	}

	static async show(modalCtrl: ModalController, resetToken = ''): Promise<any> {
		const modal = await modalCtrl.create({
			component: AppRestorePasswordModalComponent,
			cssClass: 'short-info-modal auto-height dialog-primary-background-small',
			componentProps: {
				recommend: false,
				resetToken,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		return response.data;
	}

	createPasswordForm() {
		const formControllNames: any = {};

		formControllNames.password = [
			'',
			[Validators.required, Validators.minLength(8), Validators.pattern(/\d+/), Validators.pattern(/[A-Z]+/)],
		];
		formControllNames.rePassword = ['', [Validators.required, this._isPasswordMatch()]];

		this.passwordForm = this.fb.group(formControllNames);
	}

	private _isPasswordMatch(): ValidatorFn {
		return () => {
			const pass = this.passwordForm?.get('password')?.value;
			const confirmPass = this.passwordForm?.get('rePassword')?.value;
			return pass === confirmPass ? null : { notSame: true };
		};
	}

	isValid(controlName?: string): boolean {
		if (controlName) {
			return this.passwordForm.get(controlName)?.hasError('required') && this.passwordForm.get(controlName)?.touched;
		}

		return this.passwordForm.invalid;
	}

	isValidByPattern(controlName?: string): boolean {
		if (controlName) {
			return this.passwordForm.get(controlName)?.errors && !this.passwordForm.get(controlName)?.hasError('required');
		}
		return this.passwordForm.invalid;
	}

	sendEmail() {
		if (!validator.isEmail(this.email)) {
			this.snackbarCtrl.open(this.translate.instant('sign_up.validation.email'), null, {
				duration: 5000,
			});
			this.isValidEmail = false;

			return;
		} else {
			this.isValidEmail = true;
			this.modalCtrl.dismiss(this.email);
		}
	}

	async sendNewPassword() {
		try {
			await Api.resetPassword(this.resetToken, this.passwordForm.get('password').value);
			await this.navigationService.signIn();
			this.dismiss();
			this.snackbarCtrl.open(this.translate.instant('new_password.success'), null, {
				duration: 2000,
			});
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('new_password.error') + e, null, {
				duration: 2000,
			});
		}
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
	resetValidation() {
		this.isValidEmail = true;
	}
}
