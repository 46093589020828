import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Order from 'src/smoothr-web-app-core/models/Order';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import { SelectLanguageModalComponent } from '../select-language-modal/select-language-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { SelectLanguagePopoverModalComponent } from '../select-language-popover/select-language-popover.component';
import { ChangeVenuePopoverComponent, LeaveVenue } from '../change-venue-popover/change-venue-popover.component';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
	selector: 'app-toolbar-venue-info',
	templateUrl: './toolbar-venue-info.component.html',
	styleUrls: ['./toolbar-venue-info.component.scss'],
})
export class ToolbarVenueInfoComponent implements OnInit {
	@Input() order: Order;
	@Input() venue: Venue;
	@Input() close: boolean = false;
	@Output() back = new EventEmitter<void>();
	@Input() showSelectLanguage: boolean = false;
	isOpen = false;
	public listCountries = [
		{
			name: 'German',
			icon: '../../../assets/flag/flags-country/de-round.svg',
			value: 'de',
		},
		{
			name: 'English',
			icon: '../../../assets/flag/flags-country/gb-round.svg',
			value: 'en',
		},
		{
			name: 'Polish',
			icon: '../../../assets/flag/flags-country/pl-round.svg',
			value: 'pl',
		},
		{
			name: 'Spanish',
			icon: '../../../assets/flag/flags-country/es-round.svg',
			value: 'es',
		},
		{
			name: 'Turkish',
			icon: '../../../assets/flag/flags-country/tr-round.svg',
			value: 'tr',
		},
		{
			name: 'France',
			icon: '../../../assets/flag/flags-country/fr-round.svg',
			value: 'fr',
		},
		{
			name: 'Italy',
			icon: '../../../assets/flag/flags-country/it-round.svg',
			value: 'it',
		},
	];
	constructor(
		public translate: TranslateService,
		private modalCtrl: ModalController,
		private popover: PopoverController,
		private navigationService: NavigationService
	) {}

	ngOnInit() {}
	goBack() {}
	async selectLanguage() {
		const dialog = await SelectLanguageModalComponent.show(this.modalCtrl);
		console.log(dialog);
	}
	findFlag() {
		return this.listCountries.find(opt => opt.value === this.translate.currentLang).icon;
	}
	async openPopoverSelectionLanguage(ev: any) {
		if (this.isOpen) {
			return;
		}
		this.isOpen = true;
		const popover = await this.popover.create({
			component: SelectLanguagePopoverModalComponent,
			event: ev,
			translucent: true,
			cssClass: 'popover-select-language',
			showBackdrop: false,
			componentProps: {
				lang: this.translate.currentLang,
			},
		});
		popover.onWillDismiss().then(v => {
			// this.visible = false;
			this.isOpen = false;
		});
		await popover.present();
	}
	async showPopover(ev: any) {
		if (this.close) {
			if (this.isOpen) {
				return;
			}
			this.isOpen = true;
			const popover = await this.popover.create({
				component: ChangeVenuePopoverComponent,
				event: ev,
				translucent: true,
				cssClass: 'popover-select-language',
				showBackdrop: false,
				componentProps: {
					lang: this.translate.currentLang,
				},
			});
			popover.onWillDismiss().then(v => {
				// this.visible = false;

				if (v?.data == LeaveVenue.NAVIGATE_BACK) {
					this.back.emit();
				}
				this.isOpen = false;
			});
			await popover.present();
		}
	}
}
