// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  margin-bottom: 8px;
  text-transform: uppercase;
  text-decoration: underline;
}
h1 strong {
  font-size: 22px;
}

p {
  margin-bottom: 8px;
}

h2 {
  margin-bottom: 8px;
  font-size: 14px !important;
  line-height: 18px !important;
}
h2 strong {
  font-size: 14px !important;
  line-height: 18px !important;
}

b {
  font-family: AppFontBold;
}

ul {
  margin-block: 8px;
}
ul li p {
  margin-bottom: 0;
}

tr:first-child td p {
  text-align: center;
}

.mt {
  margin-top: 12px;
}

.underlined {
  text-decoration: underline;
}

.text-align-end {
  text-align: end;
}

.font-18 {
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/privacy-content/privacy-content.component.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,yBAAA;EACA,0BAAA;AAAF;AACE;EACE,eAAA;AACJ;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,0BAAA;EACA,4BAAA;AAAF;AAEE;EACE,0BAAA;EACA,4BAAA;AAAJ;;AAIA;EACE,wBAAA;AADF;;AAKA;EACE,iBAAA;AAFF;AAII;EACE,gBAAA;AAFN;;AASI;EACE,kBAAA;AANN;;AAWA;EACE,gBAAA;AARF;;AAUA;EACE,0BAAA;AAPF;;AAUA;EACE,eAAA;AAPF;;AAUA;EACE,eAAA;AAPF","sourcesContent":["\nh1 {\n  margin-bottom: 8px;\n  text-transform: uppercase;\n  text-decoration: underline;\n  strong {\n    font-size: 22px;\n  }\n}\n\np {\n  margin-bottom: 8px;\n}\n\nh2 {\n  margin-bottom: 8px;\n  font-size: 14px !important;\n  line-height: 18px !important;\n\n  strong {\n    font-size: 14px !important;\n    line-height: 18px !important;\n  }\n}\n\nb {\n  font-family: AppFontBold;\n}\n\n\nul {\n  margin-block: 8px;\n  li {\n    p {\n      margin-bottom: 0;\n    }\n  }\n}\n\ntr:first-child {\n  td {\n    p {\n      text-align: center;\n    }\n  }\n}\n\n.mt {\n  margin-top: 12px;\n}\n.underlined {\n  text-decoration: underline;\n}\n\n.text-align-end {\n  text-align: end;\n}\n\n.font-18 {\n  font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
