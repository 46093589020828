// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar-row {
  background: var(--ion-color-primary);
  color: white;
}
.toolbar-row ion-button {
  --background: transparent;
}

.title {
  font-family: Tusker, sans-serif;
  padding: 10px 0px 0px 10px;
  position: relative;
}
.title::after {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 0;
  width: 100%;
  height: 5px;
  background-image: url('separator_menu_modal.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.no_orders {
  height: 80vh;
  width: 100%;
  position: relative;
}
.no_orders ion-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 290px;
  max-width: 290px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/my-orders/my-orders.page.scss"],"names":[],"mappings":"AAAA;EAIE,oCAAA;EACA,YAAA;AAFF;AAFE;EACE,yBAAA;AAIJ;;AAEA;EACE,+BAAA;EACA,0BAAA;EACA,kBAAA;AACF;AACC;EACC,WAAA;EACA,kBAAA;EACA,YAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACA,iDAAA;EACA,4BAAA;EACA,kCAAA;EACA,sBAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,kBAAA;AACF;AAAE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,YAAA;EACA,gBAAA;AAEJ","sourcesContent":[".toolbar-row {\n  ion-button {\n    --background: transparent;\n  }\n  background: var(--ion-color-primary);\n  color: white;\n}\n\n.title {\n  font-family: Tusker, sans-serif;\n  padding: 10px 0px 0px 10px;\n  position: relative;\n  \n\t&::after {\n\t\tcontent: '';\n\t\tposition: absolute;\n\t\tbottom: -9px;\n\t\tleft: 0;\n\t\twidth: 100%;\n\t\theight: 5px;\n\t\tbackground-image: url('../../../assets/tabilo/separator_menu_modal.svg');\n\t\tbackground-repeat: no-repeat;\n\t\tbackground-position: center center;\n\t\tbackground-size: cover;\n\t}\n}\n.no_orders {\n  height: 80vh;\n  width: 100%;\n  position: relative;\n  ion-img {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 290px;\n    max-width: 290px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
