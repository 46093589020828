// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-account {
  border: 1px solid #EAEAEA;
  border-radius: 0;
  padding: 5px 10px;
  width: -moz-fit-content;
  width: fit-content;
  margin: 15px 35px;
  display: inline-flex;
  align-items: center;
}
.delete-account ion-icon {
  margin-right: 8px;
}

ion-radio-group > ion-row {
  gap: 5px;
}
ion-radio-group > ion-row ion-item {
  margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/account/account.page.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,iBAAA;EACA,oBAAA;EACA,mBAAA;AADF;AAGE;EACE,iBAAA;AADJ;;AAKA;EACE,QAAA;AAFF;AAGE;EACE,kBAAA;AADJ","sourcesContent":["@import \"../../../theme/mixins\";\n\n.delete-account {\n  border: 1px solid #EAEAEA;\n  border-radius: 0;\n  padding: 5px 10px;\n  width: fit-content;\n  margin: 15px 35px;\n  display: inline-flex;\n  align-items: center;\n\n  ion-icon {\n    margin-right: 8px;\n  }\n}\n\nion-radio-group > ion-row {\n  gap: 5px;\n  ion-item {\n    margin-bottom: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
