// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chip {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("/assets/tabilo/table-background.svg") center top/100% no-repeat;
  max-height: 70px;
  min-height: 50px;
  min-width: 50px;
  padding: 0 7px 0 10px;
  margin-right: 10px;
}
.chip ion-icon {
  fill: #fff;
  zoom: 1.2;
}
.chip p {
  font-family: AppFontBold, sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 13px;
  color: white;
}
.chip .table-wraper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/app/components/chip-table/chip-table.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gFAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,qBAAA;EACA,kBAAA;AACJ;AACI;EACI,UAAA;EACA,SAAA;AACR;AAEI;EACI,oCAAA;EACA,yBAAA;EACA,eAAA;EACA,iBAAA;EACA,YAAA;AAAR;AAGI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AADR","sourcesContent":[".chip{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: url('/assets/tabilo/table-background.svg') center top/100% no-repeat;\n    max-height: 70px;\n    min-height: 50px;\n    min-width: 50px;\n    padding: 0 7px 0 10px;\n    margin-right: 10px;\n\n    ion-icon {\n        fill: #fff;\n        zoom: 1.2;\n    }\n\n    p {\n        font-family: AppFontBold, sans-serif;\n        text-transform: uppercase;\n        font-size: 18px;\n        line-height: 13px;\n        color: white;\n    }\n\n    .table-wraper {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        flex-direction: column;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
