// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  width: 90%;
  margin: 15px auto;
}

h3 {
  font-family: var(--font-tusker), sans-serif;
  margin-top: 15px;
}

ion-textarea {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  font-size: 14px;
  width: 100% !important;
  padding: 10px;
}

.button-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.button-block .button {
  width: 150px;
}
.button-block_dismiss {
  --background: url('button_background_red.png') no-repeat center center / 100% 100%;
}

ion-icon {
  width: 19px;
  z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/app/components/note-article-modal/note-article-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,iBAAA;AACJ;;AACA;EACI,2CAAA;EACA,gBAAA;AAEJ;;AAAA;EACI,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,sBAAA;EACA,aAAA;AAGJ;;AADA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;AAIJ;AAFI;EACI,YAAA;AAIR;AADI;EACI,kFAAA;AAGR;;AAAA;EACI,WAAA;EACA,WAAA;AAGJ","sourcesContent":[".wrapper {\n    width: 90%;\n    margin: 15px auto;\n}\nh3 {\n    font-family: var(--font-tusker), sans-serif;\n    margin-top: 15px;\n}\nion-textarea {\n    border: 1px solid #e6e6e6;\n    border-radius: 10px;\n    font-size: 14px;\n    width: 100% !important;\n    padding: 10px;\n}\n.button-block {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 10px;\n\n    .button {\n        width: 150px;\n    }\n\n    &_dismiss {\n        --background: url('../../../assets/tabilo/button_background_red.png')  no-repeat center center / 100% 100%;\n    }\n}\nion-icon {\n    width: 19px;\n    z-index: 10;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
