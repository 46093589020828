import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-select-language-modal',
	templateUrl: './select-language-modal.component.html',
	styleUrls: ['./select-language-modal.component.scss'],
})
export class SelectLanguageModalComponent implements OnInit {
	public selectedLanguage: string;
	public listCountries = [
		{
			name: 'German',
			icon: '../../../assets/flag/flags-country/de.svg',
			value: 'de',
		},
		{
			name: 'English',
			icon: '../../../assets/flag/flags-country/gb.svg',
			value: 'en',
		},
		{
			name: 'Polish',
			icon: '../../../assets/flag/flags-country/pl.svg',
			value: 'pl',
		},
		{
			name: 'Spanish',
			icon: '../../../assets/flag/flags-country/es.svg',
			value: 'es',
		},
		{
			name: 'Turkish',
			icon: '../../../assets/flag/flags-country/tr.svg',
			value: 'tr',
		},
		{
			name: 'France',
			icon: '../../../assets/flag/flags-country/fr.svg',
			value: 'fr',
		},
		{
			name: 'Italy',
			icon: '../../../assets/flag/flags-country/it.svg',
			value: 'it',
		},
	];
	constructor(
		private translate: TranslateService,
		private modalCtrl: ModalController
	) {
		this.selectedLanguage = this.translate.currentLang;
	}
	static async show(modalCtrl: ModalController) {
		const modal = await modalCtrl.create({
			component: SelectLanguageModalComponent,
			cssClass: 'select-language',
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
	}
	ngOnInit() {}
	selectLng(lang: string) {
		this.selectedLanguage = lang;
		// this.translate.setDefaultLang(this.selectedLanguage);
		// this.translate.use(this.selectedLanguage);
		// this.dismiss();
	}
	dismissWithData() {
		this.translate.setDefaultLang(this.selectedLanguage);
		this.translate.use(this.selectedLanguage);
		this.dismiss();
	}
	dismiss() {
		this.modalCtrl.dismiss();
	}
}
