// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 991px) {
  .subway-btn {
    position: unset;
    width: 100%;
    margin-top: 32px;
  }
}

.wrap {
  padding: 15px;
  word-wrap: break-word;
  margin-top: 20px;
}

h1 {
  font-size: 20px;
  line-height: 24px;
  font-family: AppFontBold, sans-serif;
}

.text-large {
  margin-top: 10px;
  font-family: AppFont, sans-serif;
  font-size: 14px;
  line-height: 18px;
}`, "",{"version":3,"sources":["webpack://./src/theme/mixins.scss","webpack://./src/app/pages/email-confirmation/email-confirmation.page.scss"],"names":[],"mappings":"AACE;ECCF;IAEI,eAAA;IACA,WAAA;IACA,gBAAA;EADF;AACF;;AAGA;EACE,aAAA;EACA,qBAAA;EACA,gBAAA;AAAF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,oCAAA;AACF;;AACA;EACE,gBAAA;EACA,gCAAA;EACA,eAAA;EACA,iBAAA;AAEF","sourcesContent":["@mixin desktop {\n  @media (min-width: 991px) {\n    @content;\n  }\n}\n\n@mixin mobile {\n  @media (max-width: 992px) {\n    @content\n  }\n}\n","@import \"../../../theme/mixins\";\n\n.subway-btn {\n  @include desktop {\n    position: unset;\n    width: 100%;\n    margin-top: 32px;\n  }\n}\n.wrap {\n  padding: 15px;\n  word-wrap: break-word;\n  margin-top: 20px;\n}\nh1 {\n  font-size: 20px;\n  line-height: 24px;\n  font-family: AppFontBold, sans-serif;\n}\n.text-large {\n  margin-top: 10px;\n  font-family: AppFont, sans-serif;\n  font-size: 14px;\n  line-height: 18px;\n}\nion-footer {\n  ion-button {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
