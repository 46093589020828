// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  --background: var(--ion-color-secondary);
}

.info_section {
  display: flex;
  align-items: center;
  padding: 10px 15px;
}
.info_section ion-icon {
  width: 44px;
  height: 42px;
  margin-right: 10px;
}
.info_section .text_block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.info_section .text_block .info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.info_section .text_block .info .chip {
  padding: 3px 10px;
  background: var(--ion-color-tertiary);
  border-radius: 10px;
}
.info_section h3, .info_section p {
  color: white;
}
.info_section p {
  font-size: 12px;
}
.info_section h3 {
  margin-top: 5px;
  font-size: 14px;
  font-family: Tusker, sans-serif;
}

.flag-item-block {
  width: 35px;
}

.flag-item {
  position: relative;
}
.flag-item ion-icon {
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/components/toolbar-venue-info/toolbar-venue-info.component.scss"],"names":[],"mappings":"AAAA;EACI,wCAAA;AACJ;;AACA;EACI,aAAA;EACA,mBAAA;EACA,kBAAA;AAEJ;AADI;EACI,WAAA;EACA,YAAA;EACA,kBAAA;AAGR;AADI;EACI,aAAA;EACA,sBAAA;EACA,2BAAA;AAGR;AAFQ;EACI,aAAA;EACA,2BAAA;EACA,mBAAA;AAIZ;AAHY;EAEI,iBAAA;EACA,qCAAA;EACA,mBAAA;AAIhB;AACI;EACI,YAAA;AACR;AACI;EACI,eAAA;AACR;AACI;EACI,eAAA;EACA,eAAA;EACA,+BAAA;AACR;;AAEA;EACI,WAAA;AACJ;;AAIE;EACE,kBAAA;AADJ;AAEI;EACI,kBAAA;EACA,aAAA;EACA,SAAA;EACA,2BAAA;EACA,YAAA;AAAR","sourcesContent":["ion-toolbar {\n    --background: var(--ion-color-secondary);\n}\n.info_section {\n    display: flex;\n    align-items: center;\n    padding: 10px 15px;\n    ion-icon  {\n        width: 44px;\n        height: 42px;\n        margin-right: 10px;\n    }\n    .text_block {\n        display: flex;\n        flex-direction: column;\n        justify-content: flex-start;\n        .info {\n            display: flex;\n            justify-content: flex-start;\n            align-items: center;\n            .chip {\n\n                padding: 3px 10px;\n                background: var(--ion-color-tertiary);\n                border-radius: 10px;\n\n            }\n        }\n    }\n    h3,p {\n        color: white;\n    }\n    p {\n        font-size: 12px;\n    }\n    h3 {\n        margin-top: 5px;\n        font-size: 14px;\n        font-family: Tusker, sans-serif;\n    }\n}\n.flag-item-block {\n    width: 35px;  \n\n   \n  }\n\n  .flag-item {\n    position: relative;\n    ion-icon {\n        position: absolute;\n        bottom: -15px;\n        left: 50%;\n        transform: translateX(-50%);\n        color: white;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
