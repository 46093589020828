// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label {
  font-size: 300px;
}

.header {
  padding: 10px 17px;
  background-size: cover;
}
.header p {
  margin: 0;
}

.toolbar-logo {
  background: var(--ion-brand-logo-light);
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  display: flex;
  justify-self: center;
}

.back-icon {
  margin-right: 10px;
  width: 30px;
}

.ion-footer {
  background: #FFFFFF;
  color: var(--ion-color-second);
  text-align: center;
  cursor: pointer;
  border: 2px solid var(--ion-color-second);
  position: absolute;
  width: calc(100% - 100px) !important;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 30px;
  z-index: 9999;
}`, "",{"version":3,"sources":["webpack://./src/app/components/custom-address-modal/custom-address-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,sBAAA;AACF;AACE;EACE,SAAA;AACJ;;AAGA;EACE,uCAAA;EACA,WAAA;EACA,YAAA;EACA,wBAAA;EACA,4BAAA;EACA,YAAA;EACA,aAAA;EACA,oBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,WAAA;AAAF;;AAGA;EACE,mBAAA;EACA,8BAAA;EACA,kBAAA;EACA,eAAA;EACA,yCAAA;EACA,kBAAA;EACA,oCAAA;EACA,YAAA;EACA,OAAA;EACA,QAAA;EACA,YAAA;EACA,aAAA;AAAF","sourcesContent":[".label {\n  font-size: 300px;\n}\n\n.header {\n  padding: 10px 17px;\n  background-size: cover;\n\n  p {\n    margin: 0;\n  }\n}\n\n.toolbar-logo {\n  background: var(--ion-brand-logo-light);\n  width: 50px;\n  height: 50px;\n  background-size: contain;\n  background-repeat: no-repeat;\n  margin: auto;\n  display: flex;\n  justify-self: center;\n}\n\n.back-icon {\n  margin-right: 10px;\n  width: 30px;\n}\n\n.ion-footer {\n  background: #FFFFFF;\n  color: var(--ion-color-second);\n  text-align: center;\n  cursor: pointer;\n  border: 2px solid var(--ion-color-second);\n  position: absolute;\n  width: calc(100% - 100px) !important;\n  margin: auto;\n  left: 0;\n  right: 0;\n  bottom: 30px;\n  z-index: 9999;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
