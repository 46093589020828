import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { Router } from '@angular/router';
import { HomePage } from '../home/home.page';
import { MenuPage } from '../menu/menu.page';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { environment } from '../../../environments/environment';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
	selector: 'app-impressum',
	templateUrl: './impressum.page.html',
	styleUrls: ['impressum.page.scss'],
})
export class ImpressumPage implements OnInit {
	static url = 'impressum';
	environment = environment;
	checkIfVenueExistImpressum = '';

	constructor(
		private repository: RepositoryService,
		private navigationService: NavigationService,
		private location: Location
	) {}
	ionViewDidEnter() {
		console.log(this.repository._venue?.legal?.imprint);
		if (this.repository._venue && this.repository._order) {
			if (this.repository._venue && this.repository._venue?.legal?.imprint) {
				this.checkIfVenueExistImpressum = this.repository._venue?.legal?.imprint;
			} else {
				this.checkIfVenueExistImpressum = '';
			}
		} else {
			this.checkIfVenueExistImpressum = '';
		}
	}
	ngOnInit(): void {
		this.repository.venue.subscribe(v => {
			if (v && v?.legal?.imprint) {
				this.checkIfVenueExistImpressum = v?.legal?.imprint;
			} else {
				this.checkIfVenueExistImpressum = '';
			}
		});
	}

	async goBack() {
		if (this.location) {
			this.location.back();
			return;
		}
		this.location.back();
	}

	async toPrivacy() {
		await this.navigationService.privacy();
	}
	async toAGB() {
		await this.navigationService.tos();
	}
}
