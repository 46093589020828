// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  background: white;
  border-top: 2px solid #f6f3f5;
  padding: 20px 10px;
  border-bottom: 2px solid #f6f3f5;
  margin: 10px 0 10px;
}
.card ion-row {
  align-items: center;
}

.icon-col {
  padding: 0;
}
.icon-col ion-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.icon-col ion-row ion-img {
  height: 20px;
  width: 40px;
  margin: 0 4px;
}
.icon-col ion-row :last-child,
.icon-col ion-row :first-child {
  margin: 0;
}

.title {
  font-size: 12px;
  margin: 0 4px;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/app/components/payment-methods-display/payment-methods-display.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EAGA,6BAAA;EACA,kBAAA;EACA,gCAAA;EACA,mBAAA;AADF;AAGE;EACE,mBAAA;AADJ;;AAKA;EACE,UAAA;AAFF;AAIE;EACE,aAAA;EACA,6BAAA;EACA,mBAAA;AAFJ;AAII;EACE,YAAA;EACA,WAAA;EACA,aAAA;AAFN;AAKI;;EAEE,SAAA;AAHN;;AAQA;EACE,eAAA;EACA,aAAA;EACA,gBAAA;AALF","sourcesContent":[".card {\n  background: white;\n  // border: 1px solid #E6E7E3;\n  // border-radius: 50px;\n  border-top: 2px solid #f6f3f5;\n  padding: 20px 10px;\n  border-bottom: 2px solid #f6f3f5;\n  margin: 10px 0 10px;\n\n  ion-row {\n    align-items: center;\n  }\n}\n\n.icon-col {\n  padding: 0;\n\n  ion-row {\n    display: flex;\n    justify-content: space-evenly;\n    align-items: center;\n\n    ion-img {\n      height: 20px;\n      width: 40px;\n      margin: 0 4px;\n    }\n\n    :last-child,\n    :first-child {\n      margin: 0;\n    }\n  }\n}\n\n.title {\n  font-size: 12px;\n  margin: 0 4px;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
