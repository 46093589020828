// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  width: 90%;
  margin: 0px auto;
  padding: 35px 18px;
}

.title {
  color: #fff;
  text-align: center;
  font-family: Tusker;
  font-size: 22px;
  margin-bottom: 10px;
}

.email-msg {
  color: #fff;
  font-family: AppFontBold;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
}

ion-item {
  --background: transparent;
  --color: #fff;
}

.buttons-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button-close {
  --background: url('button_background_white.png') no-repeat center center/ 100% 100%;
  color: var(--ion-color-primary);
}

.stacked {
  background-color: #521340;
  padding: 1px 4px;
}

.text-container {
  margin-bottom: 20px;
}

.input_info,
.input-error {
  font-size: 9px;
  font-family: AppFont;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/components/app-restore-password-modal/app-restore-password-modal.component.scss"],"names":[],"mappings":"AAAA;EACC,UAAA;EACA,gBAAA;EAEA,kBAAA;AAAD;;AAGA;EACC,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;AAAD;;AAGA;EACC,WAAA;EACA,wBAAA;EACA,eAAA;EACG,kBAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,yBAAA;EACA,aAAA;AAAJ;;AAGA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ;;AAGA;EACI,mFAAA;EACA,+BAAA;AAAJ;;AAGA;EACI,yBAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,mBAAA;AAAJ;;AAIA;;EAEI,cAAA;EACA,oBAAA;EACA,WAAA;AADJ","sourcesContent":[".wrapper {\n\twidth: 90%;\n\tmargin: 0px auto;\n\t// background: url(../../../assets/tabilo/restore_password_modal_background.png) no-repeat center center/ 100% 100%;\n\tpadding: 35px 18px;\n}\n\n.title {\n\tcolor: #fff;\n\ttext-align: center;\n\tfont-family: Tusker;\n\tfont-size: 22px;\n\tmargin-bottom: 10px;\n}\n\n.email-msg {\n\tcolor: #fff;\n\tfont-family: AppFontBold;\n\tfont-size: 14px;\n    text-align: center;\n    margin-bottom: 20px;\n}\n\nion-item {\n    --background: transparent;\n    --color: #fff;\n}\n\n.buttons-row {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n\n.button-close {\n    --background: url(../../../assets/tabilo/button_background_white.png) no-repeat center center/ 100% 100%;\n    color: var(--ion-color-primary);\n}\n\n.stacked {\n    background-color: #521340;\n    padding: 1px 4px;\n}\n\n.text-container {\n    margin-bottom: 20px;\n\n}\n\n.input_info,\n.input-error {\n    font-size: 9px;\n    font-family: AppFont;\n    color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
