import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

export enum LeaveVenue {
	NAVIGATE_BACK = 'back',
	STAY = 'stay',
}
@Component({
	selector: 'app-change-venue-popover',
	templateUrl: './change-venue-popover.component.html',
	styleUrls: ['./change-venue-popover.component.scss'],
})
export class ChangeVenuePopoverComponent implements OnInit {
	leaveEnum = LeaveVenue;
	constructor(private popover: PopoverController) {}

	ngOnInit() {}
	dismiss(value: LeaveVenue) {
		this.popover.dismiss(value);
	}
}
