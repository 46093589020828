import { sleep } from './../../../smoothr-web-app-core/utils/utils';
import { Component } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { MenuModalComponent } from '../menu-modal/menu-modal.component';

@Component({
	selector: 'app-burger-btn',
	templateUrl: './burger-btn.component.html',
	styleUrls: ['burger-btn.component.scss'],
})
export class BurgerBtnComponent {
	visible: boolean;

	constructor(
		private popoverCtrl: PopoverController,
		private modalCtrl: ModalController
	) {}

	async presentPopover(ev: any) {
		const popover = await this.modalCtrl.create({
			component: MenuModalComponent,
			backdropDismiss: true,
			showBackdrop: true,
			cssClass: 'menu-modal',
		});
		popover.onWillDismiss().then(() => {
			this.visible = false;
		});
		this.visible = true;
		await sleep(200);
		await popover.present();
		return;
	}
}
