import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import { PreorderType } from 'src/smoothr-web-app-core/enums/PreorderType';
import Article from 'src/smoothr-web-app-core/models/Article';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import Order from 'src/smoothr-web-app-core/models/Order';
import { OrderUtils } from 'src/smoothr-web-app-core/utils/order-utils';
import { defaultsToArticleOption, getBasePrice, numberToCurrency } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-recomendation-article-modal',
	templateUrl: './recomendation-article-modal.component.html',
	styleUrls: ['./recomendation-article-modal.component.scss'],
})
export class RecomendationArticleModalComponent implements OnInit {
	public articlesList: Article[] = [];
	public order: Order;
	numberToCurrency = numberToCurrency;
	constructor(
		private modalCtrl: ModalController,
		private translate: TranslateService
	) {}
	static async show(modalCtrl: ModalController, articlesList: Article[], order: Order): Promise<{ article: Article }> {
		const modal = await modalCtrl.create({
			component: RecomendationArticleModalComponent,
			cssClass: ['auto-height, recommendation-dialog'],
			componentProps: {
				articlesList,
				order,
			},
			showBackdrop: true,
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		return result;
	}
	ngOnInit() {}
	trackBy(index: number, article: Article) {
		return article._id;
	}
	reloadPrice(article: Article) {
		let price = 0;
		if (article && this.order) {
			const articleGroup = new ArticleGroup();
			articleGroup.article = article;
			articleGroup.groups = defaultsToArticleOption(article, [], article.defaults, this.order.type, this.order.preorder.type);
			articleGroup.quantity = 1;
			price = OrderUtils.articleGroupsTotalPrice([articleGroup], this.order.type, this.order.preorder.type);
		} else {
			price = 0;
		}

		const priceText = numberToCurrency(price, this.order.currency);
		const formatText = priceText.toString().split(/[,.]/);
		return (
			'<div class="price_transform"><div class="full_price">' +
			formatText[0] +
			'.' +
			'</div><div class="rest_price">' +
			formatText[1] +
			'</div><div class="rest_price_hide">' +
			formatText[1] +
			'</div></div>'
		);
	}
	dismiss() {
		this.modalCtrl.dismiss();
	}
	selectArticle(article: Article) {
		this.modalCtrl.dismiss({ article });
	}
	checkMeasurmentPlusPfand(article: Article) {
		let result = '';

		const pfandValue = this.checkPfandTag(article);
		if (pfandValue) {
			result =
				this.translate.instant('pfand_text.zzgl') +
				(pfandValue === 'deposit15' ? numberToCurrency(0.15, this.order.currency) : numberToCurrency(0.25, this.order.currency)) +
				this.translate.instant('pfand_text.pfand') +
				'<br/>';
		} else {
			if (result.length > 0) {
				result = result + ')';
			}
		}
		if (article?.measurement && article?.measurement?.unit && article?.measurement?.amount) {
			result =
				result +
				' ' +
				article.measurement?.refAmount +
				' ' +
				article.measurement?.refUnit +
				' = ' +
				this.numberToCurrency(getBasePrice(article, OrderType.PREORDER, this.order.preorder.type), this.order.currency);
		}
		return result;
	}
	checkPfandTag(article: Article) {
		if (article.tags?.length === 0) {
			return '';
		}
		const deposit25 = article.tags.find(it => it.identifier === 'deposit25') ? 'deposit25' : '';
		const deposit15 = article.tags.find(it => it.identifier === 'deposit15') ? 'deposit15' : '';

		return deposit15 || deposit25;
	}
}
