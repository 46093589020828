// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-slide > ion-row > ion-col {
  z-index: 20;
}

.slide-title {
  text-align: center;
  width: 100%;
  font-size: large;
  margin-top: 36px;
  font-weight: bold;
}

.slide-card {
  margin-top: -26px;
  z-index: -1;
  color: black;
}

.slide-icon {
  margin: 0 auto;
  height: 56px;
  width: 56px;
}

.slide-prev-button {
  cursor: pointer;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 50%;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.slide-next-button {
  cursor: pointer;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/components/home-page-slides/home-page-slides.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,iBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,eAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,QAAA;EACA,OAAA;EACA,SAAA;EACA,UAAA;AACF;;AAEA;EACE,eAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,QAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;AACF","sourcesContent":["ion-slide > ion-row > ion-col {\n  z-index: 20;\n}\n\n.slide-title {\n  text-align: center;\n  width: 100%;\n  font-size: large;\n  margin-top: 36px;\n  font-weight: bold;\n}\n\n.slide-card {\n  margin-top: -26px;\n  z-index: -1;\n  color: black;\n}\n\n.slide-icon {\n  margin: 0 auto;\n  height: 56px;\n  width: 56px;\n}\n\n.slide-prev-button {\n  cursor: pointer;\n  height: 30px;\n  width: 30px;\n  position: absolute;\n  top: 50%;\n  left: 0;\n  bottom: 0;\n  z-index: 1;\n}\n\n.slide-next-button {\n  cursor: pointer;\n  height: 30px;\n  width: 30px;\n  position: absolute;\n  top: 50%;\n  right: 0;\n  bottom: 0;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
