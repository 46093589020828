import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/smoothr-web-app-core/services/analytics/analytics.service';
import { RepositoryService } from 'src/smoothr-web-app-core/services/repository/repository.service';
import { CookieService } from 'ngx-cookie-service';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

declare var cookieConsentData: any;
declare var cookieConsentCallback: any;
@Component({
	selector: 'app-privacy-content',
	templateUrl: './privacy-content.component.html',
	styleUrls: ['./privacy-content.component.scss'],
})
export class PrivacyContentComponent implements OnInit {
	constructor(
		public repository: RepositoryService,

		private cookieService: CookieService,

		private analytics: AnalyticsService,
		private location: Location,
		private cdr: ChangeDetectorRef,
		private translate: TranslateService
	) {}

	ngOnInit() {}
	async editCookies() {
		try {
			await this.analytics.setAnalyticsCollectionEnabled(false);
		} catch (e) {
			console.log(e);
		}
		console.log('Test');

		this.cookieService.deleteAll();
		// document.cookie.split(';').forEach(function (c) {
		// 	document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
		// 	console.log(document.cookie)
		// });
		await this.analytics.setAnalyticsCollectionEnabled(false);

		console.log(navigator.cookieEnabled);

		const findDialogWithCookie = document.getElementsByClassName('cc_css_reboot');

		await sleep(500);
		await this.initCookieConsent();

		// window.location.reload();
	}
	private async initCookieConsent() {
		console.log(cookieConsent);
		this.cdr.detectChanges();
		const onContentLoaded = async () => {
			cookieConsent.run({
				notice_banner_type: 'simple',
				consent_type: 'express',
				palette: 'dark',
				language: 'de',
				website_name: this.translate.instant('cookie_consent.title.' + environment.customerGroup[1]),
				cookies_policy_url: this.translate.instant('cookie_consent.policy_url.' + environment.customerGroup[1]),
			});
			console.log(cookieConsentData);
		};
		const a = await onContentLoaded();
		console.log(a);
		cookieConsentCallback = async () => {
			await this.handleCookieConsent();
		};
		// await this.handleCookieConsent();
	}

	async handleCookieConsent() {
		console.log('handleCookieConsent()', cookieConsentData);
		await this.setAnalyticsEnabled(cookieConsentData.targeting);
	}
	async setAnalyticsEnabled(enabled: boolean) {
		await this.analytics.setAnalyticsCollectionEnabled(enabled);
	}
}
