// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-chip {
  border-radius: 0;
  background: #F5F5F5;
  font-weight: bold;
  justify-content: center;
  color: var(--ion-color-main);
  transition: 200ms linear;
  flex: 1;
  margin: 0;
  text-align: center;
}
ion-chip.selected {
  background: var(--ion-color-second);
  color: #fff;
  transition: 200ms linear;
  margin-left: 10px;
  flex: 1;
}

.address-popover {
  padding: 20px;
  background: #fff;
  font-family: AppFont-black, sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/app/components/home-popover/home-popover.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,uBAAA;EACA,4BAAA;EACA,wBAAA;EACA,OAAA;EACA,SAAA;EACA,kBAAA;AACF;AACE;EACE,mCAAA;EACA,WAAA;EACA,wBAAA;EACA,iBAAA;EACA,OAAA;AACJ;;AAGA;EACE,aAAA;EACA,gBAAA;EACA,sCAAA;AAAF","sourcesContent":["ion-chip {\n  border-radius: 0;\n  background: #F5F5F5;\n  font-weight: bold;\n  justify-content: center;\n  color: var(--ion-color-main);\n  transition: 200ms linear;\n  flex: 1;\n  margin: 0;\n  text-align: center;\n\n  &.selected {\n    background: var(--ion-color-second);\n    color: #fff;\n    transition: 200ms linear;\n    margin-left: 10px;\n    flex: 1;\n  }\n}\n\n.address-popover {\n  padding: 20px;\n  background: #fff;\n  font-family: AppFont-black, sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
