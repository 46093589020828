import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ArticleOptionGroupFlavorComponent } from '../components/article-option-group-flavor/article-option-group-flavor.component';
import { ItemSelectableArticleComponent } from '../components/item-selectable-article/item-selectable-article.component';
import { ToOrderButtonComponent } from '../components/to-order-button/to-order-button.component';
import { OrderListComponent } from '../components/order-list/order-list.component';
import { ToggleButtonGroupComponent } from '../components/toggle-button-group/toggle-button-group.component';
import { MenuPopoverComponent } from '../components/menu-popover/menu-popover.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MyOrderComponent } from '../components/my-order/my-order.component';
// import { QRCodeModule } from 'angular2-qrcode';
import { ItemViewComponent } from '../components/item-view/item-view.component';
import { ArticleOptionGroupSingleComponent } from '../components/article-option-group-single/article-option-group-single.component';
import { VenueSuggestionComponent } from '../components/venue-suggestion/venue-suggestion.component';
import { HomePopoverComponent } from '../components/home-popover/home-popover.component';
import { ArticleOptionGroupCheckboxComponent } from '../components/article-option-group-checkbox/article-option-group-checkbox.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { createTranslateLoader } from '../app.module';
import { BurgerBtnComponent } from '../components/burger-btn/burger-btn.component';
import { ArticleOptionGroupMultipleComponent } from '../components/article-option-group-multiple/article-option-group-multiple.component';
import { RouterModule } from '@angular/router';
import { OrderContentComponent } from '../components/order-content/order-content.component';
import { PromoCodeComponent } from '../components/promo-code/promo-code.component';
import { PaymentMethodsDisplayComponent } from '../components/payment-methods-display/payment-methods-display.component';
import { CustomAddressModalComponent } from '../components/custom-address-modal/custom-address-modal.component';
import { AllowGpsModalComponent } from '../components/allow-gps-modal/allow-gps-modal.component';
import { LangStringPipe } from '../../smoothr-web-app-core/pipes/lang-string.pipe';
import { TagComponent } from '../components/tag/tag.component';
import { QuantitySelectorComponent } from '../components/quantity-selector/quantity-selector.component';
import { PreorderTypeChipComponent } from '../components/preorder-type-chip/preorder-type-chip.component';
import { AccountToolbarComponent } from '../components/account-toolbar/account-toolbar.component';
import { SlotsSelectorComponent } from '../components/slots-selector/slots-selector.component';
import { MenuModalComponent } from '../components/menu-modal/menu-modal.component';
import { NoteArticleModalComponent } from '../components/note-article-modal/note-article-modal.component';
import { LangObservablePipe } from '../../smoothr-web-app-core/pipes/lang-obervable.pipe';
import { AppRestorePasswordModalComponent } from '../components/app-restore-password-modal/app-restore-password-modal.component';
import { SelectDateFormcontrolComponent } from '../components/select-date-formcontrol/select-date-formcontrol.component';
import { ScanQrModal } from '../components/scan-qr/scan-qr.component';
import { ZXingScannerModule } from 'src/scanner/public_api';
import { TipComponent } from '../components/tip/tip.component';
import { AddCustomTipComponent } from '../components/add-custom-tip/add-custom-tip.component';
import { ChipTableComponent } from 'src/app/components/chip-table/chip-table.component';
import { ShortInfoHtmlComponent } from '../components/short-info-html/short-info-html.component';
import { MapVenueComponent } from 'src/maps/components/map-venue/map-venue.component';
import { MapSearchVenueComponent } from 'src/maps/components/map-search-venue/map-search-venue.component';
import { CheckDeliveryRadiusModalComponent } from '../components/check-delivery-radius-modal/check-delivery-radius-modal.component';
import { InfoModalComponent } from '../components/info-modal/info-modal.component';
import { FeatureIconComponent } from '../components/feature-icon/feature-icon.component';
import { SanifairListComponent } from '../components/sanifair-list/sanifair-list.component';
import { SanifairItemComponent } from '../components/sanifair-item/sanifair-item.component';
import { OrderPageToolbarComponent } from '../components/order-page-toolbar/order-page-toolbar.component';
import { ScanQrSanifairModal } from '../components/scan-qr-sanifair/scan-qr-sanifair.component';
import { SanifairInfoModalComponent } from '../components/sanifair-info-modal/sanifair-info-modal.component';
import { AskedRegisterModalComponent } from '../components/asked-register-modal/asked-register-modal.component';
import { HtmlVisualizerModalComponent } from '../components/html-visualizer-modal/html-visualizer-modal.component';
import { InfoModalQuantityComponent } from '../components/info-modall-quantity/info-modal-quantity.component';
import { SelectLanguageModalComponent } from '../components/select-language-modal/select-language-modal.component';
import { ToolbarVenueInfoComponent } from '../components/toolbar-venue-info/toolbar-venue-info.component';
import { RecomendationArticleModalComponent } from '../components/recomendation-article-modal/recomendation-article-modal.component';
import { SwiperDirective } from '../directives/swiper.directive';
import { InputPhoneWithCountyCodeComponent } from '../components/input-phone-with-county-code/input-phone-with-county-code.component';
import { CustomModalWithCountrySelectionComponent } from '../components/input-phone-with-county-code/custom-modal-with-country-selection/custom-modal-with-country-selection.component';
import { CookieInfoModalComponent } from '../components/cookie-info-modal/cookie-info-modal.component';
import { SelectLanguagePopoverModalComponent } from '../components/select-language-popover/select-language-popover.component';
import { ChangeVenuePopoverComponent } from '../components/change-venue-popover/change-venue-popover.component';
import { AddInfoIconDirective } from '../directives/add-info-icon.directive';
import { ImpressumContentComponent } from '../components/impressum-content/impressum-content.component';
import { PrivacyContentComponent } from '../components/privacy-content/privacy-content.component';
import { AgbContentComponent } from '../components/agb-content/agb-content.component';
import { CouponsSectionComponent } from '../components/coupons-section/coupons-section.component';
import { AddCouponArticleDialogComponent } from '../components/add-coupon-article-dialog/add-coupon-article-dialog.component';

import { NgScrollbarModule } from 'ngx-scrollbar';
import { InfoErrorModalComponent } from '../components/info-error-modal /info-error-modal.component';

@NgModule({
	declarations: [
		ArticleOptionGroupFlavorComponent,
		ArticleOptionGroupSingleComponent,
		ItemSelectableArticleComponent,
		ToOrderButtonComponent,
		OrderListComponent,
		ToggleButtonGroupComponent,
		MenuPopoverComponent,
		VenueSuggestionComponent,
		MyOrderComponent,
		HomePopoverComponent,
		ItemViewComponent,
		ArticleOptionGroupCheckboxComponent,
		ArticleOptionGroupMultipleComponent,
		BurgerBtnComponent,
		OrderContentComponent,
		PromoCodeComponent,
		PaymentMethodsDisplayComponent,
		CustomAddressModalComponent,
		AllowGpsModalComponent,
		TagComponent,
		TipComponent,
		AddCustomTipComponent,
		LangStringPipe,
		LangObservablePipe,
		QuantitySelectorComponent,
		PreorderTypeChipComponent,
		AccountToolbarComponent,
		SlotsSelectorComponent,
		MenuModalComponent,
		NoteArticleModalComponent,
		AppRestorePasswordModalComponent,
		SelectDateFormcontrolComponent,
		ScanQrModal,
		ChipTableComponent,
		ShortInfoHtmlComponent,
		MapVenueComponent,
		MapSearchVenueComponent,
		CheckDeliveryRadiusModalComponent,
		InfoModalComponent,
		FeatureIconComponent,
		SanifairListComponent,
		ScanQrSanifairModal,
		SanifairItemComponent,
		OrderPageToolbarComponent,
		SanifairInfoModalComponent,
		AskedRegisterModalComponent,
		HtmlVisualizerModalComponent,
		InfoModalQuantityComponent,
		SelectLanguageModalComponent,
		ToolbarVenueInfoComponent,
		RecomendationArticleModalComponent,
		SwiperDirective,
		InputPhoneWithCountyCodeComponent,
		CustomModalWithCountrySelectionComponent,
		CookieInfoModalComponent,
		SelectLanguagePopoverModalComponent,
		ChangeVenuePopoverComponent,
		AddInfoIconDirective,
		ImpressumContentComponent,
		PrivacyContentComponent,
		AgbContentComponent,
		CouponsSectionComponent,
		AddCouponArticleDialogComponent,
		InfoErrorModalComponent,
	],
	exports: [
		ArticleOptionGroupCheckboxComponent,
		ArticleOptionGroupFlavorComponent,
		ArticleOptionGroupSingleComponent,
		ArticleOptionGroupMultipleComponent,
		ItemSelectableArticleComponent,
		ToOrderButtonComponent,
		OrderListComponent,
		ToggleButtonGroupComponent,
		HomePopoverComponent,
		VenueSuggestionComponent,
		MenuPopoverComponent,
		BurgerBtnComponent,
		MyOrderComponent,
		ItemViewComponent,
		TranslateModule,
		OrderContentComponent,
		PromoCodeComponent,
		CustomAddressModalComponent,
		AllowGpsModalComponent,
		LangStringPipe,
		LangObservablePipe,
		TagComponent,
		QuantitySelectorComponent,
		PreorderTypeChipComponent,
		AccountToolbarComponent,
		SlotsSelectorComponent,
		MenuModalComponent,
		NoteArticleModalComponent,
		AppRestorePasswordModalComponent,
		SelectDateFormcontrolComponent,
		ScanQrModal,
		TipComponent,
		AddCustomTipComponent,
		ChipTableComponent,
		ShortInfoHtmlComponent,
		MapVenueComponent,
		MapSearchVenueComponent,
		CheckDeliveryRadiusModalComponent,
		InfoModalComponent,
		FeatureIconComponent,
		SanifairListComponent,
		ScanQrSanifairModal,
		SanifairItemComponent,
		OrderPageToolbarComponent,
		SanifairInfoModalComponent,
		AskedRegisterModalComponent,
		HtmlVisualizerModalComponent,
		InfoModalQuantityComponent,
		SelectLanguageModalComponent,
		ToolbarVenueInfoComponent,
		RecomendationArticleModalComponent,
		SwiperDirective,
		InputPhoneWithCountyCodeComponent,
		CustomModalWithCountrySelectionComponent,
		CookieInfoModalComponent,
		SelectLanguagePopoverModalComponent,
		ChangeVenuePopoverComponent,
		AddInfoIconDirective,
		ImpressumContentComponent,
		PrivacyContentComponent,
		AgbContentComponent,
		CouponsSectionComponent,
		AddCouponArticleDialogComponent,
		InfoErrorModalComponent,
	],
	imports: [
		CommonModule,
		IonicModule,
		FormsModule,
		MatSnackBarModule,
		ReactiveFormsModule,
		// QRCodeModule,
		ZXingScannerModule,
		TranslateModule.forChild({
			defaultLanguage: 'de',
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
		RouterModule,
		NgScrollbarModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModuleModule {}
