// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-chip {
  border-radius: 5px;
  background: white;
  color: var(--ion-color-secondary);
  transition: color 200ms linear, background 200ms linear;
  font-family: Montserrat, sans-serif !important;
  min-width: 60px;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 auto;
  padding: 14px;
  margin: 0 3px;
  height: auto;
  border: 1px solid #E2DCD5;
  border-radius: 10px;
}
@media (min-width: 991px) {
  ion-chip {
    overflow: unset;
    display: flex;
    flex-grow: 1 !important;
  }
}
ion-chip:first-child {
  margin-left: 12px;
}
ion-chip:last-child {
  margin-right: 12px;
}
ion-chip.selected {
  background: var(--ion-color-secondary);
  border-color: var(--ion-color-secondary);
  color: white;
}
ion-chip ion-label {
  font-size: 16px;
  margin: 0;
}

div {
  display: flex;
  overflow: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}
@media (min-width: 991px) {
  div {
    flex-grow: 1;
  }
}
div::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/category-chip-list/category-chip-list.component.scss","webpack://./src/theme/mixins.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,iBAAA;EACA,iCAAA;EACA,uDAAA;EACA,8CAAA;EACA,eAAA;EACA,sBAAA;EACA,uBAAA;EACA,cAAA;EACA,aAAA;EACA,aAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;AADF;ACdE;EDCF;IAgBI,eAAA;IACA,aAAA;IACA,uBAAA;EACF;AACF;AACE;EACE,iBAAA;AACJ;AAEE;EACE,kBAAA;AAAJ;AAGE;EACE,sCAAA;EACA,wCAAA;EACA,YAAA;AADJ;AAIE;EACE,eAAA;EACA,SAAA;AAFJ;;AAMA;EACE,aAAA;EACA,cAAA;EACA,uBAAA;EACA,wBAAA;EACA,qBAAA,EAAA,YAAA;AAHF;AC5CE;ED0CF;IAOI,YAAA;EADF;AACF;AAGE;EACE,aAAA;AADJ","sourcesContent":["@import \"../../../theme/mixins\";\n\nion-chip {\n  border-radius: 5px;\n  background: white;\n  color: var(--ion-color-secondary);\n  transition: color 200ms linear, background 200ms linear;\n  font-family: Montserrat, sans-serif !important;\n  min-width: 60px;\n  flex-direction: column;\n  justify-content: center;\n  flex: 0 0 auto;\n  padding: 14px;\n  margin: 0 3px;\n  height: auto;\n  border: 1px solid #E2DCD5;\n  border-radius: 10px;\n  @include desktop {\n    overflow: unset;\n    display: flex;\n    flex-grow: 1 !important;\n  }\n\n  &:first-child {\n    margin-left: 12px;\n  }\n\n  &:last-child {\n    margin-right: 12px;\n  }\n\n  &.selected {\n    background: var(--ion-color-secondary);\n    border-color: var(--ion-color-secondary);\n    color: white;\n  }\n\n  ion-label {\n    font-size: 16px;\n    margin: 0;\n  }\n}\n\ndiv {\n  display: flex;\n  overflow: auto;\n  scroll-behavior: smooth;\n  -ms-overflow-style: none;\n  scrollbar-width: none; /* Firefox */\n  @include desktop {\n    flex-grow: 1;\n  }\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n}\n","@mixin desktop {\n  @media (min-width: 991px) {\n    @content;\n  }\n}\n\n@mixin mobile {\n  @media (max-width: 992px) {\n    @content\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
