import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-agb-content',
	templateUrl: './agb-content.component.html',
	styleUrls: ['./agb-content.component.scss'],
})
export class AgbContentComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
