import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { Moment } from 'moment';
import { TimeUtils } from '../../../smoothr-web-app-core/utils/time-utils';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { IonCol } from '@ionic/angular';

@Component({
	selector: 'app-slots-selector',
	templateUrl: './slots-selector.component.html',
	styleUrls: ['./slots-selector.component.scss'],
})
export class SlotsSelectorComponent implements OnInit {
	@ViewChild('timeCol', { static: true }) timeCol: IonCol;
	@Output() slotSelected = new EventEmitter<Moment>();
	@Input() set slots(slots: Moment[]) {
		this.dates = [];
		this._slots = slots;
		this._slots.forEach(slot => {
			if (!slot) {
				this.slotSelected.emit(null);
				return;
			}
			const insertedDate = this.dates.find(date => TimeUtils.isSameDate(date, slot));
			if (!insertedDate) {
				this.dates.push(slot);
			}
		});
	}

	get slots(): Moment[] {
		return this._slots;
	}
	private _slots: Moment[] = [];
	@Input()
	set slot(slot: Moment) {
		this._slot = slot;
		this.slotSelected.emit(this._slot);
	}
	get slot(): Moment {
		return this._slot;
	}
	private _slot: Moment;
	dates: Moment[] = [];
	now: moment.Moment;
	maxSlots = 5;
	maxDates = 3;
	TimeUtils = TimeUtils;
	constructor(private translate: TranslateService) {}

	ngOnInit() {}

	slotToText(slot: Moment): string {
		if (!slot) {
			return null;
		}
		if (!moment.isMoment(slot)) {
			slot = moment(slot);
		}
		const now = moment();
		const sameDay = TimeUtils.isSameDate(slot, now);
		if (sameDay) {
			return slot.format('HH:mm');
		} else {
			return slot.format('HH:mm / DD.MM.yyyy');
		}
	}
	slotToDateText(slot: Moment): string {
		if (TimeUtils.isSameDate(slot, this.now)) {
			return this.translate.instant('today');
		}
		return slot.lang('de').format('dd, DD.MM.');
	}
	slotToTimeText(slot: Moment): string {
		if (!slot) return this.translate.instant('checkout_modal.first_slot');
		return slot.format('HH:mm');
	}
	timesForDate(selectedDate: Moment): Moment[] {
		const date = selectedDate ?? this.dates[0];
		if (!date) {
			return [];
		}
		return this.slots.filter(slot => TimeUtils.isSameDate(slot, date) || (!slot && date.dayOfYear() === this.dates[0].dayOfYear()));
	}
	setDate(date: Moment) {
		this.slot = date;
		this.maxSlots = 5;
		this.scrollTimeToTop();
	}
	setTime(time: Moment) {
		this.slot = time;
	}
	async scrollTimeToTop() {
		// @ts-ignore
		const el: HTMLDivElement = this.timeCol.el;
		el.scrollTo({
			left: 0,
			top: 0,
		});
	}
}
