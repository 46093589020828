import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import Article from 'src/smoothr-web-app-core/models/Article';

@Component({
	selector: 'app-coupons-section',
	templateUrl: './coupons-section.component.html',
	styleUrls: ['./coupons-section.component.scss'],
})
export class CouponsSectionComponent implements OnInit {
	@Input() articleList: Article[];
	@Output() code = new EventEmitter<string>();
	couponValue = '';
	couponValueError = false;
	couponForm = new FormControl('', [Validators.required, Validators.minLength(13), Validators.maxLength(13)]);
	isActive = false;
	constructor(private cdr: ChangeDetectorRef) {
		this.couponForm.valueChanges.subscribe(v => {
			// console.log(v);
			// if (v) {
			// 	const lastChar = v.charAt(v.length - 1);
			// 	if (!/\d/.test(lastChar)) {
			// 		v = v.slice(0, -1);
			// 	}
			// 	let cleanedValue = v.replace(/[^0-9]/g, '');
			// 	if (cleanedValue?.length > 13) {
			// 		cleanedValue = v.toString().slice(0, 13);
			// 	}
			// 	console.log("cleanedValue", cleanedValue)
			// 	this.couponForm.setValue(cleanedValue, { emitEvent: false });
			// 	this.isActive = true;
			// 	this.cdr.detectChanges()
			// } else {
			// 	this.isActive = false;
			// }
			// this.couponValueError = false;
		});
	}

	ngOnInit() {}
	validate() {
		if (!this.couponValue) {
			return false;
		}
	}
	emitValue() {
		this.couponValueError = false;
		console.log(this.articleList);
		// const foundArticle = this.articleList.find(it => it.gtin == this.couponForm.value);
		// if (!foundArticle) {
		// 	this.couponValueError = true;
		// 	return;
		// }

		this.code.emit(this.couponForm.value);
		this.couponForm.patchValue('');
	}
	numberOnlyValidation(event: any) {
		const char = String.fromCharCode(event.which || event.keyCode);
		console.log('key pres', char);
		// Check if the character is not a number (0-9)
		if (!/\d/.test(char)) {
			event.preventDefault(); // Prevent non-numeric characters
		}
	}
	// Handling input event instead of keypress for more control
	onInput(event: Event): void {
		const inputElement = event.target as HTMLInputElement;
		let value = inputElement.value;
		const lastChar = value.charAt(value.length - 1);
		if (!/\d/.test(lastChar)) {
			value = value.slice(0, -1);
		}
		// Clean the value by removing anything that's not a digit (0-9)
		let cleanedValue = value.replace(/[^0-9]/g, '');

		// Limit the cleaned value to a maximum of 13 characters
		if (cleanedValue.length > 13) {
			cleanedValue = cleanedValue.slice(0, 13); // Ensure max 13 digits
		}
		console.log('on input', cleanedValue);
		// Set the cleaned value back to the input element
		inputElement.value = cleanedValue;

		// Update the form control with the cleaned value
		this.couponForm.setValue(cleanedValue, { emitEvent: false });

		// Set isActive flag based on the presence of valid input
		this.isActive = cleanedValue.length > 0;

		// Optionally handle error state
		this.couponValueError = false;
	}
}
